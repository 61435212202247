////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ConvertRunDate {
  public static Convert(value: Date | undefined, showTime: boolean = false): string {
    if (value == null) {
      return 'n/a';
    } else {
      const formatter = new Intl.DateTimeFormat('un-US', {
        dateStyle: 'medium',
        timeStyle: showTime ? 'medium' : undefined
      });
      return formatter.format(value);
    }
  }
}