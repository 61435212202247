// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {CreateCustomerRequest, EditCustomerRequest} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {CustomerUI} from "../dataModel/CustomerUI";
import {CustomerTranslator} from "../dataModel/Translators/CustomerTranslator";
import {ServiceBase} from "./ServiceBase";
import {IV2Client} from "../clients/V2Client";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IClient} from "../clients/Client";
import {CustomerInfoPaginatedResult} from "../clients/V2Classes";

export class CustomerService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  GetCustomers(paginationToken?: string | null | undefined, limit?: number | undefined): Promise<CustomerInfoPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listCustomers(t, limit), paginationToken)
        .then(r => new CustomerInfoPaginatedResult(r))
    );
  }

  GetRemainingCustomers(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<CustomerInfoPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(token =>
        this._v2Client.listCustomers(token, limit), paginationToken, cancelToken)
        .then(r => new CustomerInfoPaginatedResult(r))
    );
  }

  CreateCustomer(
    name: string,
    domain: string,
    allowAllUsers: boolean,
    validEmails: string[] | undefined
  ): Promise<CustomerUI> {
    const create = new CreateCustomerRequest();
    create.name = name;
    create.emailDomain = domain;
    create.allowAllUsers = allowAllUsers;
    if (validEmails != null) {
      create.validEmails = validEmails;
    }

    return this.TryPromiseWithCatch(() =>
      this._client.createCustomer(create)
        .then(c => CustomerTranslator.TranslateCustomer(c))
    );
  }

  DeleteCustomer(domain: string): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteCustomer(domain)
    );
  }

  UpdateCustomer(id: string, dto: EditCustomerRequest): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.updateCustomer(id, dto)
    );
  }
}