// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { UsageDataItem } from '../UsageDataItem';
import {
  CustomerUsageData,
  CustomerUsageDataBase,
  JobUsageData,
  JobUsageDataBase,
  OverallUsageData,
  UserUsageData,
  UserUsageDataBase
} from '../../clients/Classes';
import { UsageSummaryUI } from '../UsageSummaryUI';

export class UsageDataTranslator {
  static TranslateUsageSummary(summaryData: OverallUsageData): UsageSummaryUI {
    const summaryUI = new UsageSummaryUI(summaryData.jobRunCount!, summaryData.runTimeSeconds!);
    summaryData.customerUsageDatas!.forEach(c => {
      const customerData = this.TranslateItem(c);
      summaryUI.CustomerData.push(customerData);
    });

    return summaryUI;
  }

  static TranslateItem(item: any): UsageDataItem {
    if (item instanceof CustomerUsageDataBase || item instanceof CustomerUsageData) {
      return this.TranslateCustomer(item);
    } else if (item instanceof UserUsageDataBase || item instanceof UserUsageData) {
      return this.TranslateUser(item);
    } else if (item instanceof JobUsageDataBase || item instanceof JobUsageData) {
      return this.TranslateJob(item);
    } else {
      throw Error('Unsupported object type sent for translation');
    }
  }

  private static TranslateCustomer(customer: CustomerUsageDataBase): UsageDataItem {
    const customerItem = new UsageDataItem();
    customerItem.JobRuns = customer.jobRunCount;
    customerItem.ItemType = customer.level?.toString();
    customerItem.ID = customer.customerId;
    customerItem.RunTimeSeconds = customer.runTimeSeconds;
    customerItem.Children = [];
    customerItem.Name = customer.customerName;

    customer.userUsageDatas!.forEach(u => {
      const user = this.TranslateItem(u);
      customerItem.Children.push(user);
    });

    return customerItem;
  }

  private static TranslateUser(user: UserUsageDataBase): UsageDataItem {
    const userItem = new UsageDataItem();
    userItem.ID = user.userId;
    userItem.ItemType = user.level?.toString();
    userItem.JobRuns = user.jobRunCount;
    userItem.RunTimeSeconds = user.runTimeSeconds;
    userItem.Children = [];
    userItem.Name = user.username;

    user.jobUsageDatas!.forEach(j => {
      const jobItem = this.TranslateItem(j);
      userItem.Children.push(jobItem);
    });

    return userItem;
  }

  private static TranslateJob(job: JobUsageDataBase): UsageDataItem {
    const jobItem = new UsageDataItem();
    jobItem.ID = job.jobId;
    jobItem.ItemType = job.level?.toString();
    jobItem.Name = job.jobName;
    jobItem.RunTimeSeconds = job.runTimeSeconds;
    jobItem.JobRuns = job.jobRunCount;
    jobItem.Children = [];

    return jobItem;
  }
}
