// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  FileType,
  HubType,
  IBitFile,
  IFile360,
  JobDirectory,
  JobFile,
  ProjectType,
  ZipEntryDto,
  BitDirectory,
  Directory360,
  DirectoryDto,
  File360,
  FileDto,
  ProjectWiseFile,
  StorageType
} from '../../clients/Classes';
import {DirectoryUI} from '../DirectoryUI';
import {FileUI} from '../FileUI';
import {ZipEntryUI} from '../ZipEntryUI';

export class FileStructureTranslator {
  static GetDirectory(apiDirectory: BitDirectory | DirectoryDto | Directory360, isProjectFiles: boolean): DirectoryUI {
    const storageType = apiDirectory instanceof DirectoryDto
      ? apiDirectory.storageType!
      : apiDirectory instanceof JobDirectory || apiDirectory instanceof Directory360
        ? StorageType.Forge
        : StorageType.ProjectWise;
    const directory = new DirectoryUI(apiDirectory.id!, '', apiDirectory.projectId!, storageType);

    if (Object.hasOwn(apiDirectory, 'hubId')) {
      // @ts-ignore
      directory.HubId = apiDirectory.hubId;
    }

    if (Object.hasOwn(apiDirectory, 'parentId')) {
      // @ts-ignore
      directory.ParentId = apiDirectory.parentId;
    }

    if (Object.hasOwn(apiDirectory, 'name')) {
      // @ts-ignore
      directory.Name = apiDirectory.name;
    }

    if (Object.hasOwn(apiDirectory, 'lastUpdated')) {
      // @ts-ignore
      directory.LastChange = apiDirectory.lastUpdated;
    }

    if (Object.hasOwn(apiDirectory, 'region')) {
      // @ts-ignore
      directory.HubRegion = apiDirectory.region;
    }

    if (Object.hasOwn(apiDirectory, 'hubType')) {
      // @ts-ignore
      directory.HubType = apiDirectory.hubType ?? HubType.Unknown;
    }

    if (Object.hasOwn(apiDirectory, 'projectType')) {
      // @ts-ignore
      directory.ProjectType = apiDirectory.projectType ?? ProjectType.Unknown;
    }

    if (isProjectFiles) {
      directory.Icon = 'assets/images/Icon_ProjectFiles.png';
    }

    return directory;
  }

  static GetApiDirectoryDto(directory: DirectoryUI): DirectoryDto {
    return new DirectoryDto({
      projectId: directory.ProjectId,
      hubId: directory.HubId,
      id: directory.Id,
      storageType: directory.StorageType,
      recursive: directory.IsRecursive
    });
  }

  static GetFile(apiFile: IFile360 | IBitFile): FileUI {
    const storageType = apiFile instanceof JobFile || apiFile instanceof File360
      ? StorageType.Forge
      : StorageType.ProjectWise;
    const file = new FileUI(apiFile.id!, apiFile.fileNameWithExtension!, apiFile.projectId!, apiFile.directoryId!, storageType);
    file.ParentId = apiFile.parentId;
    file.IsComposite = (apiFile instanceof File360 || apiFile instanceof JobFile) && apiFile.isComposite!;
    file.FileSize = apiFile.size;
    file.LastChange = apiFile.lastUpdated;
    file.IsProcessed = apiFile instanceof ProjectWiseFile || ((apiFile instanceof File360 || apiFile instanceof JobFile) && apiFile.isProcessed !== false);
    file.StorageType = storageType;
    if (!apiFile.isDirectory) {
      file.Icon = 'assets/images/Default_File.png';
      file.FileType = new FileType(apiFile.fileType);
      file.LastUpdate = apiFile.lastUpdated;
      file.UpdateBy = apiFile.lastUpdatedBy;
      file.Version = (apiFile instanceof File360 || apiFile instanceof JobFile) ? apiFile.versionNumber?.toString() : undefined;
    }

    if (Object.hasOwn(apiFile, 'hubId')) {
      // @ts-ignore
      file.HubId = apiFile.hubId;
    }

    if (Object.hasOwn(apiFile, 'hubName')) {
      // @ts-ignore
      file.HubName = apiFile.hubName;
    }

    if (Object.hasOwn(apiFile, 'projectName')) {
      // @ts-ignore
      file.ProjectName = apiFile.projectName;
    }

    if (Object.hasOwn(apiFile, 'region')) {
      // @ts-ignore
      file.HubRegion = apiFile.region;
    }

    if (Object.hasOwn(apiFile, 'hubType')) {
      // @ts-ignore
      file.HubType = apiFile.hubType ?? HubType.Unknown;
    }

    if (Object.hasOwn(apiFile, 'projectType')) {
      // @ts-ignore
      file.ProjectType = apiFile.projectType ?? ProjectType.Unknown;
    }

    return file;
  }

  static GetApiFileDto(file: FileUI): FileDto {
    return new FileDto({
      id: file.Id,
      projectId: file.ProjectId,
      hubId: file.HubId,
      storageType: file.StorageType
    });
  }

  static GetZip(apiZip: ZipEntryDto, parentFile: FileUI): ZipEntryUI {
    const zip = new ZipEntryUI(parentFile.Id, apiZip.filename!, parentFile.ProjectId);
    zip.FileSize = apiZip.size;
    zip.ParentId = parentFile.Id;
    zip.Icon = 'assets/images/Default_File.png';
    zip.LastChange = apiZip.time;
    zip.StorageType = StorageType.Forge;
    return zip;
  }
}
