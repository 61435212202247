////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {DirectoryUI} from "./DirectoryUI";
import {ProjectUI} from "./ProjectUI";

export class BulkTaskUI {
  Id: string;
  Name: string | undefined;
  StartTime: Date | undefined;
  Paused = false;
  DestinationDirectory: DirectoryUI | undefined;
  Projects: ProjectUI[];

  constructor(id?: string) {
    this.Id = id ?? 'new';
    this.StartTime = new Date();
    this.Projects = [];
  }
}