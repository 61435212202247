////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {Task} from "../dataModel/Task";
import {FileUI} from "../dataModel/FileUI";
import {ConvertAutodeskSchedule} from "../converters/ConvertAutodeskSchedule";
import {ProjectUI} from "../dataModel/ProjectUI";
import {FindProjectItemRecursive, GetRecursiveFilePath, GetUniqueProjectStructureData, IncludeZip} from "../Utility";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {FileService} from "../services/FileService";
import {GetDirectoryPathResult} from "../dataModel/GetDirectoryPathResult";
import {ZipTaskSetting} from "../dataModel/ZipTaskSetting";
import {CenteringContainer, LineWrapper, ValueWrapper} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";

const fileService = new FileService();

const SettingsSummary = ({task, projects, projectsLoading, onError}: {
  task: Task | undefined,
  projects: (ProjectUI | ProjectWiseConfigurationUI)[],
  projectsLoading: boolean,
  onError?: (error: any, operation: string) => void
}) => {
  const [sourcePaths, setSourcePaths] = useState<string[]>([]);
  const [destinationPath, setDestinationPath] = useState<string>('');
  const [loadingPaths, setLoadingPaths] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (task == null) {
      return;
    }

    if (projectsLoading) {
      setLoadingPaths(true);
      return;
    }

    function getProjectName(project: ProjectUI | ProjectWiseConfigurationUI | undefined): string | undefined {
      return project instanceof ProjectUI ? project.Name : project?.ApiConfiguration.repositoryName;
    }

    function getProjectId(project: ProjectUI | ProjectWiseConfigurationUI | undefined): string | undefined {
      return project instanceof ProjectUI ? project.Id : project?.ApiConfiguration.id;
    }

    const allItems: (DirectoryUI | FileUI | ZipTaskSetting)[] = [];
    task.Directories.forEach(d => allItems.push(d));
    task.Files.forEach(d => allItems.push(d));
    if (task.ExportDirectory != null) {
      allItems.push(task.ExportDirectory);
    }

    const projectData: { projectId: string, directoryIds: string[] }[] = GetUniqueProjectStructureData(allItems);

    setLoadingPaths(true);
    const promises: Promise<GetDirectoryPathResult[]>[] = [];
    projectData.forEach(d => {
      const project = projects
        .find(p => getProjectId(p) === d.projectId);
      if (project == null) {
        return;
      }
      promises.push(fileService.PopulateToDirectories(project, d.directoryIds));
    });

    Promise.all(promises)
      .then(results => {
        if (!isMounted) {
          return;
        }

        const allResults: GetDirectoryPathResult[] = [];
        results.forEach(rs => {
          rs.forEach(r => allResults.push(r));
        });

        if (allResults.find(r => !r.Success) != null) {
          alert('There were errors loading paths, some paths may not display correctly');
        }

        const sources: string[] = [];

        task.Directories.forEach(d => {
          const project = projects
            .find(p => getProjectId(p) === d.ProjectId);
          sources.push(`${getProjectName(project)}/${GetRecursiveFilePath(d, '/')}`)
        });

        task.Files.forEach(f => {
          if (f instanceof ZipTaskSetting && !IncludeZip(f, task)) {
            return;
          }
          const projectId = f instanceof FileUI ? f.ProjectId : f.File.ProjectId;
          const directoryId = f instanceof FileUI ? f.DirectoryId : f.File.DirectoryId;
          const project = projects
            .find(p => getProjectId(p) === projectId);
          const directory = project == null
            ? null
            : FindProjectItemRecursive(project.RootFolderArray, directoryId) as DirectoryUI;
          const directoryPath = directory == null
            ? ''
            : GetRecursiveFilePath(directory, '/');
          const fileName = f instanceof FileUI
            ? f.Name
            : `${f.File.Name} -> ${f.Zips.map(z => z.Name).join(' + ')}`;
          sources.push(`${getProjectName(project)}/${directoryPath}/${fileName}`);
        });

        setSourcePaths(sources);

        allResults.forEach(r => {
          task.Directories.forEach(d => {
            const loaded = r.LoadedDirectories.find(l => l.Id === d.Id);
            if (loaded != null) {
              d.Name = loaded.Name;
            }
          });

          if (task.ExportDirectory != null) {
            const loaded = r.LoadedDirectories.find(l => l.Id === task.ExportDirectory?.Id);
            if (loaded != null) {
              task.ExportDirectory.Name = loaded.Name;
            }
          }
        });

        const destinationProject = projects
          .find(p => getProjectId(p) === task?.ExportDirectory?.ProjectId);
        const populatedDirectory = destinationProject == null || task.ExportDirectory == null
          ? undefined
          : FindProjectItemRecursive(destinationProject.RootFolderArray, task.ExportDirectory?.Id) as DirectoryUI;
        task.ExportDirectory = populatedDirectory;
        const destinationPathOnly = populatedDirectory == null
          ? ''
          : GetRecursiveFilePath(populatedDirectory, '/');
        setDestinationPath(`${getProjectName(destinationProject)}/${destinationPathOnly}`);

        setLoadingPaths(false);
      })
      .catch(e => {
        if (onError) {
          onError(e, 'Get file paths');
        }
        setLoadingPaths(false);
        const sources: string[] = [];
        task.Files.forEach(f => {
          const projectName = f instanceof FileUI ? f.ProjectName : f.File.ProjectName;
          const fileName = f instanceof FileUI ? f.Name : `${f.File.Name} -> ${f.Zips.map(z => z.Name).join(' + ')}`;
          sources.push(`${projectName}/[Error]/${fileName}`);
        });
        setSourcePaths(sources);
        if (task.ExportDirectory != null) {
          setDestinationPath(`[Error]/${task.ExportDirectory.Name}`)
        }
      });

    return () => {
      isMounted = false;
    }
  }, [projectsLoading]);

  return (
    <>
      {(task == null || projects == null || projects.length <= 0) && (
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      )}
      {task != null && projects != null && projects.length > 0 && (
        <div>
          <h2 style={Theme.typography.heading2}>Summary</h2>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Task Name</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Name}</LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Source Files/Folders</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {loadingPaths && <ProgressRing/>}
              {!loadingPaths && sourcePaths.map(p => <LineWrapper key={p}>{p}</LineWrapper>)}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Destination Folder</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {loadingPaths && <ProgressRing/>}
              {!loadingPaths && destinationPath}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Trigger</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Trigger}</LineWrapper>
            {
              task?.Trigger === 'OnceLater' &&
              <LineWrapper style={Theme.typography.bodyMedium}>{task?.StartDate?.toString()}</LineWrapper>
            }
            {
              task?.Trigger === 'Recurring' &&
              <LineWrapper style={Theme.typography.bodyMedium}>
                {task?.RecurrenceSettings == null ? '' : ConvertAutodeskSchedule.GetSummary(task.RecurrenceSettings)}
              </LineWrapper>
            }
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Options</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.ExportDestinationNaming}</LineWrapper>
            {task?.EmailOnCompletion &&
              <LineWrapper style={Theme.typography.bodyMedium}>Email me when complete</LineWrapper>}
            {task?.EmailOnCompletion && task.AttachCsv &&
              <LineWrapper style={Theme.typography.bodyMedium}>Attach transfer log to email</LineWrapper>}
            {task?.DoNotMaintainFolderStructure !== true &&
              <LineWrapper style={Theme.typography.bodyMedium}>Maintain folder structure at destination</LineWrapper>}
            {task?.Archive &&
              <LineWrapper style={Theme.typography.bodyMedium}>Archive source files</LineWrapper>}
          </ValueWrapper>
        </div>
      )}
    </>
  );
};

export default SettingsSummary;