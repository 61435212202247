////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {Flyout} from "@adsk/alloy-react-tooltip";
import {Task} from "../dataModel/Task";
import {ProjectUI} from "../dataModel/ProjectUI";
import FileStructureList from "./FileStructureList";
import ProjectDropdown from "./ProjectDropdown";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {FileUI} from "../dataModel/FileUI";
import {ZipTaskSetting} from "../dataModel/ZipTaskSetting";
import {ZipEntryUI} from "../dataModel/ZipEntryUI";
import {CanArchive, FindProjectItemRecursive, IsTabAvailableToUser} from "../Utility";
import {
  CenteringContainer,
  FlexColumn,
  FlexRowCentered,
  FolderBlueRecursive,
  FolderBlueSingle
} from '../CommonStyledComponents';
import {ProjectType} from "../clients/Classes";
import Theme from "@adsk/alloy-react-theme";
import {AlertInformationIcon, FolderIcon} from "@adsk/alloy-react-icon";
import Illustration from "@adsk/alloy-react-illustration";
import Tooltip from '@adsk/alloy-react-tooltip';
import {FILE_TAB_IDS, FILE_TAB_TITLES, FILE_TABS} from "../Constants";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import ProjectDropdownProjectWise from "./ProjectDropdownProjectWise";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {ClientProvider} from "../clients/ClientProvider";

const authService = ClientProvider.AuthService;

const SettingsSource = (
  {
    task,
    projects,
    loadingProjects,
    selectedProject,
    projectWiseConfigs,
    loadingProjectWiseConfigs,
    selectedProjectWiseConfig,
    getRootDirectories,
    getZipContents,
    getDirectoryContents,
    expandedIds,
    onExpandedChanged,
    onProjectSelected,
    onProjectWiseConfigSelected,
    selectedTab,
    onTabChange,
    onError
  }: {
    task: Task | undefined,
    projects: ProjectUI[],
    loadingProjects: boolean,
    selectedProject: ProjectUI | undefined,
    projectWiseConfigs: ProjectWiseConfigurationUI[],
    loadingProjectWiseConfigs: boolean,
    selectedProjectWiseConfig: ProjectWiseConfigurationUI | undefined,
    getRootDirectories: (rootObject: ProjectUI | ProjectWiseConfigurationUI) => Promise<DirectoryUI[]>
    getDirectoryContents: (directory: DirectoryUI) => Promise<void>,
    getZipContents: (file: FileUI) => Promise<void>,
    expandedIds?: string[],
    onExpandedChanged?: (expandedIds: string[]) => void,
    onProjectSelected?: (project: ProjectUI | undefined) => void,
    onProjectWiseConfigSelected?: (config: ProjectWiseConfigurationUI | undefined) => void,
    selectedTab: string,
    onTabChange?: (newTab: string) => void,
    onError?: (error: any, operation: string) => void
  }) => {

  function projectClick(project: ProjectUI | undefined): void {
    if (task != null) {
      task.Directories = [];
      task.Files = [];
      task.Archive = project == null || project.ProjectType === ProjectType.A360 || project.ProjectType === ProjectType.B360Team;
    }

    if (onExpandedChanged) {
      onExpandedChanged([]);
    }

    if (onProjectSelected) {
      onProjectSelected(project);
    }
  }

  function fileSelectionsChanged(currentSelection: {
    item: BIM360ItemBase,
    newIsSelected: boolean
  }[]): void {
    if (task == null) {
      return;
    }

    if (task.Files.length > 0 || task.Directories.length > 0) {
      const baseObject = task.Files.length > 0 ? task.Files[0] : task.Directories[0];
      const previousSystem = baseObject instanceof ZipTaskSetting ? baseObject.File.StorageType : baseObject.StorageType;
      const previousProject = baseObject instanceof ZipTaskSetting ? baseObject.File.ProjectId : baseObject.ProjectId;

      const newObject = currentSelection[0].item;
      const newSystem = newObject.StorageType;
      const newProject = newObject.ProjectId;

      if (previousSystem !== newSystem || previousProject !== newProject) {
        task.Directories = [];
        task.Files = []
      }
    }

    currentSelection.forEach(s => {
      if (s.item instanceof DirectoryUI) {
        const index = task.Directories.findIndex(dir => dir.Id === s.item.Id);
        if (s.newIsSelected) {
          if (index < 0) {
            task.Directories.push(s.item);
          } else {
            task.Directories[index] = s.item;
          }
        } else {
          if (index >= 0) {
            task.Directories.splice(index, 1);
          }
        }
      } else if (s.item instanceof FileUI) {
        const existing = task.Files.find(f => f instanceof FileUI && f.Id === s.item.Id);

        if (s.newIsSelected && existing == null) {
          task.Files.push(s.item);
        } else if (!s.newIsSelected && existing != null) {
          task.Files.splice(task.Files.indexOf(existing), 1);
        }
      } else if (s.item instanceof ZipEntryUI) {
        const existingFile = task.Files
          .find(f => f instanceof ZipTaskSetting && f.File.Id === s.item.ParentId);

        if (s.newIsSelected) {
          if (existingFile == null) {
            const file = FindProjectItemRecursive(selectedProject!.RootFolderArray, s.item.ParentId!) as FileUI;
            const zip = new ZipTaskSetting(file);
            zip.Zips.push(s.item as ZipEntryUI);
            task.Files.push(zip);
          } else {
            const existingZip = (existingFile as ZipTaskSetting).Zips.find(z => z.Name === s.item.Name);
            if (existingZip == null) {
              (existingFile as ZipTaskSetting).Zips.push(s.item as ZipEntryUI);
            }
          }
        } else if (existingFile != null && existingFile instanceof ZipTaskSetting) {
          const existingZip = existingFile.Zips.find(z => z.Name === s.item.Name);
          if (existingZip != null) {
            if (existingFile.Zips.length === 1) {
              task.Files.splice(task.Files.indexOf(existingFile));
            } else {
              existingFile.Zips.splice(existingFile.Zips.indexOf(existingZip), 1);
            }
          }
        }
      }
    });

    if (!CanArchive(task)) {
      task.Archive = false;
    }
  }

  const fileIds = task?.Files
    .filter(f => f instanceof FileUI)
    .map(f => (f as FileUI).Id) ?? [];
  const zipTasks = task?.Files
    .filter(f => f instanceof ZipTaskSetting) as ZipTaskSetting[];
  const zipIds: string[] = [];
  zipTasks.forEach(z => {
    z.Zips.forEach(zip => zipIds.push(`${zip.Id}_${zip.Name}`));
  });
  const directoryIds = task?.Directories.map(d => d.Id) ?? [];
  const selectionIds = fileIds.concat(directoryIds).concat(zipIds);

  return (
    <FlexColumn>
      <FlexRowCentered>
        <h2 style={Theme.typography.heading2}>Source</h2>
        <Flyout trigger={Tooltip.TRIGGER_EVENTS.HOVER}
                content={
                  <FlexColumn>
                    <FlexRowCentered style={{marginBottom: '1em'}}>
                      <FolderIcon style={{marginRight: '0.5em', flexShrink: 0}}/>
                      <div style={Theme.typography.bodyMedium}>Folder is not included in the task</div>
                    </FlexRowCentered>
                    <FlexRowCentered style={{marginBottom: '1em'}}>
                      <FolderBlueSingle style={{marginRight: '0.5em', flexShrink: 0}}/>
                      <div style={Theme.typography.bodyMedium}>Folder is included in the task</div>
                    </FlexRowCentered>
                    <FlexRowCentered>
                      <FolderBlueRecursive style={{marginRight: '0.5em', flexShrink: 0}}/>
                      <div style={Theme.typography.bodyMedium}>Folder and all nested folders are included in the task
                      </div>
                    </FlexRowCentered>
                    <FlexRowCentered style={{...Theme.typography.bodyMedium, marginTop: '1em'}}>
                      Note that you can only select files and folders from one system (ProjectWise or Forge).
                      Selecting files and folders from one system will clear the others.
                    </FlexRowCentered>
                  </FlexColumn>
                }>
          <AlertInformationIcon size={16} style={{marginLeft: '1em'}}/>
        </Flyout>
      </FlexRowCentered>
      <Tabs active={selectedTab}
            style={{flex: 1, display: 'flex', flexDirection: "column", overflow: 'auto'}}
            onChange={tab => {
              if (onTabChange) {
                onTabChange(tab);
              }
            }}>
        {Object.keys(FILE_TABS).map(k => {
          return !IsTabAvailableToUser(k, authService.CurrentUser) ? undefined : (
            <Tab label={FILE_TAB_TITLES[k]}
                 tab={FILE_TAB_IDS[k]}
                 key={FILE_TAB_IDS[k]}
                 style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
              <FlexColumn style={{paddingTop: '1em'}}>
                {selectedTab === FILE_TAB_IDS[FILE_TABS.FORGE] &&
                  <>
                    {
                      (loadingProjects || projects.length > 0) &&
                      <div style={{width: '400px'}}>
                        <ProjectDropdown projects={projects}
                                         selectedProject={selectedProject}
                                         loadingProjects={loadingProjects}
                                         onSelected={projectClick}/>
                      </div>
                    }
                    {
                      !loadingProjects && projects.length === 0 &&
                      <CenteringContainer style={{flexDirection: 'column'}}>
                        <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                        <p style={Theme.typography.bodyLarge}>You don't have any projects available</p>
                      </CenteringContainer>
                    }
                    {
                      loadingProjects &&
                      <CenteringContainer style={{flex: 1}}>
                        <ProgressRing size={'large'}/>
                      </CenteringContainer>
                    }
                    <FileStructureList
                      rootObject={selectedProject}
                      getRootDirectories={getRootDirectories}
                      getZipContents={getZipContents}
                      getDirectoryContents={getDirectoryContents}
                      onSelectionChange={fileSelectionsChanged}
                      allowSelection={true}
                      allowMultiSelection={true}
                      defaultSelection={selectionIds}
                      defaultExpansion={expandedIds}
                      onExpandedChange={onExpandedChanged}
                      onError={onError}/>
                  </>
                }
                {selectedTab === FILE_TAB_IDS[FILE_TABS.PROJECTWISE] &&
                  <>
                    {
                      (loadingProjectWiseConfigs || projectWiseConfigs.length > 0) &&
                      <ProjectDropdownProjectWise
                        projects={projectWiseConfigs}
                        selectedProject={selectedProjectWiseConfig}
                        loadingProjects={loadingProjectWiseConfigs}
                        onSelected={onProjectWiseConfigSelected}/>
                    }
                    {
                      !loadingProjectWiseConfigs && projectWiseConfigs.length === 0 &&
                      <CenteringContainer style={{flexDirection: 'column'}}>
                        <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                        <p style={Theme.typography.bodyLarge}>You don't have any ProjectWise Projects
                          available</p>
                      </CenteringContainer>
                    }
                    <FileStructureList
                      rootObject={selectedProjectWiseConfig}
                      getRootDirectories={getRootDirectories}
                      getZipContents={getZipContents}
                      getDirectoryContents={getDirectoryContents}
                      onSelectionChange={fileSelectionsChanged}
                      allowSelection={true}
                      allowMultiSelection={true}
                      defaultSelection={selectionIds}
                      defaultExpansion={expandedIds}
                      onExpandedChange={onExpandedChanged}
                      onError={onError}/>
                  </>
                }
              </FlexColumn>
            </Tab>
          )
        })}
      </Tabs>
    </FlexColumn>
  )
    ;
};

export default SettingsSource;