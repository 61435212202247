////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {TreeItem} from "../dataModel/TreeItem";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {IdType, TreeSelectedIds} from "@adsk/alloy-react-tree/es/types";
import {
  AreAnyBranchesUnloaded,
  FindProjectItemRecursive,
  GetTreeItemId,
  GetTreeItems,
  GetUniquePrimatives
} from "../Utility";
import ProgressNode from "./ProgressNode";
import {FileUI} from "../dataModel/FileUI";
import TreeNodeFileContent from "./TreeNodeFileContent";
import {FolderTools} from "../Enums";
import {CenteringContainer, FlexColumn} from '../CommonStyledComponents';
import {cascadeMultiSelection, ExpandButton, Tree, TREE_ACTIONS, TreeNode, useTree} from "@adsk/alloy-react-tree";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {CheckboxState} from "@adsk/alloy-react-checkbox";
import {ProjectUI} from "../dataModel/ProjectUI";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";

let fullCheck = false;
let fullCheckFolder: DirectoryUI | undefined;

const FileStructureList = (
  {
    rootObject,
    getRootDirectories,
    getDirectoryContents,
    getZipContents,
    allowSelection = true,
    allowMultiSelection = true,
    cascadeSelection = false,
    foldersOnly = false,
    allowDownload = false,
    onSelectionChange,
    onExpandedChange,
    defaultSelection,
    defaultExpansion,
    onError
  }: {
    rootObject: ProjectUI | ProjectWiseConfigurationUI | null | undefined,
    getRootDirectories: (rootObject: ProjectUI | ProjectWiseConfigurationUI) => Promise<DirectoryUI[]>
    getDirectoryContents: (directory: DirectoryUI) => Promise<void>,
    getZipContents: (file: FileUI) => Promise<void>,
    allowSelection?: boolean,
    allowMultiSelection?: boolean,
    cascadeSelection?: boolean,
    foldersOnly?: boolean,
    allowDownload?: boolean,
    onSelectionChange?: (currentSelection: {
      item: BIM360ItemBase,
      newIsSelected: boolean
    }[]) => void,
    onExpandedChange?: (expandedIds: string[]) => void,
    defaultSelection?: string[],
    defaultExpansion?: string[],
    onError?: (error: any, operation: string) => void
  }) => {
  const [selectedIds, setSelectedIds] = useState<TreeSelectedIds>({});
  const [expandedIds, setExpandedIds] = useState<string[]>(defaultExpansion ?? []);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [rootDirectories, setRootDirectories] = useState<DirectoryUI[]>([]);

  useEffect(() => {
    let isMounted = true;

    if (defaultSelection != null) {
      const newSelected = selectedIds;
      // Reset any existing keys based on if they are in the default selection
      for (let key in newSelected) {
        newSelected[key] = defaultSelection.includes(key);
      }
      // Make sure each selected is actually in the object
      defaultSelection.forEach(id => newSelected[id] = true);
      setSelectedIds(newSelected);
    }

    if (rootObject == null) {
      dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: []}});
    } else {
      setLoading(true);
      getRootDirectories(rootObject)
        .then(directories => {
          if (!isMounted) {
            return;
          }
          setRootDirectories(directories);
          setLoading(false);
          const dta = GetTreeItems(directories);
          // @ts-ignore
          dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: dta}});
        })
        .catch(error => {
          handleError(error, 'Get root directories');
          setLoading(false);
        });
    }

    return () => {
      isMounted = false;
    }
  }, [rootObject]);

  const expandNode = async (
    id: IdType,
    isExpanded: boolean,
    onExpand: (p: {
      isExpanded: boolean;
      id: IdType
    }) => void
  ): Promise<void> => {
    const treeItem = normalizedTree[id].original as TreeItem<BIM360ItemBase>;
    const item: BIM360ItemBase = treeItem.relatedObject as BIM360ItemBase;

    // This is the previous state so update state
    const newExpanded = !isExpanded;
    if (newExpanded) {
      loadNodeChildren(item)
        .then(() => {
          let items: BIM360ItemBase[];
          if (item instanceof DirectoryUI) {
            items = foldersOnly ? item.SubFolders : item.SubItems;
          } else if (item instanceof FileUI) {
            items = item.SubItems;
          } else {
            return;
          }

          const newNodes = items.filter(i => normalizedTree[GetTreeItemId(i)] == null);
          if (newNodes.length > 0) {
            dispatch({
              type: TREE_ACTIONS.addChildNodes,
              // @ts-ignore
              payload: {targetId: id, newChildNodes: GetTreeItems(items)}
            });
          }
        })
        .catch(er => handleError(er, 'Get sub-items'));
    }

    if (newExpanded) {
      if (!expandedIds.includes(item.Id)) {
        expandedIds.push(item.Id);
      }
    } else {
      const index = expandedIds.indexOf(item.Id);
      if (index > 0) {
        expandedIds.splice(index, 1);
      }
    }
    setExpandedIds(expandedIds);
    if (onExpandedChange) {
      onExpandedChange(expandedIds);
    }

    onExpand({
      isExpanded: !isExpanded,
      id,
    });
  };

  async function loadNodeChildren(item: BIM360ItemBase, recursive: boolean = false): Promise<void> {
    if (item instanceof DirectoryUI && (!item.AreItemsPopulated || (recursive && AreAnyBranchesUnloaded(item)))) {
      loadingIds.push(item.Id);
      setLoadingIds(loadingIds);

      // Only need to actually populate down if we loaded new items but need to go through full tree for recursive.
      const needsPopulate = !item.AreItemsPopulated;
      await getDirectoryContents(item)
        .then(() => {
          loadingIds.splice(loadingIds.indexOf(item.Id), 1);
          setLoadingIds(loadingIds);

          if (needsPopulate) {
            populateForceCheckDown(item);
          }

          if (recursive) {
            const promises = item.SubFolders.map(f => loadNodeChildren(f, true));
            return Promise.all(promises);
          }
        })
        .catch(error => handleError(error, 'Get directory contents'));
    } else if (item instanceof FileUI && item.IsComposite && !item.AreItemsPopulated) {
      loadingIds.push(item.Id);
      setLoadingIds(loadingIds);

      return getZipContents(item)
        .then(() => {
          loadingIds.splice(loadingIds.indexOf(item.Id), 1);
          setLoadingIds(loadingIds);

          const forceCheck = selectedIds[item.Id] === true || isAnyParentSelected(item);
          for (const file of item.SubItems) {
            file.ForceCheckState = forceCheck ? true : undefined;
          }
        })
        .catch(error => handleError(error, 'Get zip contents'));
    }

    return Promise.resolve();
  }

  const expanded = {};
  if (defaultExpansion) {
    // @ts-ignore
    defaultExpansion.forEach(id => expanded[id] = true);
  }
  const {orderedIds, normalizedTree, getTreeNodeProps, getTreeProps, dispatch} =
    useTree({
      selectedIds: selectedIds,
      onSelect: treeSelect,
      initialExpandedIds: expanded,
      // @ts-ignore
      denormalizedTree: [],
      getLabelFromNode: (node) => {
        const cast = node as TreeItem<BIM360ItemBase>;
        return cast.relatedObject.Name ?? '-nothing-';
      },
    });

  function treeSelect(changes: {
    id: IdType,
    isSelected: CheckboxState
  }): void {
    const treeItem = normalizedTree[changes.id].original as TreeItem<BIM360ItemBase>;
    if (allowMultiSelection && treeItem.relatedObject instanceof DirectoryUI) {
      return;
    }

    // Check for setting zip contents
    const object = treeItem.relatedObject;
    if (object instanceof FileUI && object.IsComposite && object.AreItemsPopulated) {
      object.SubItems.forEach(z => z.ForceCheckState = changes.isSelected === true ? true : undefined);
    }

    const originalSelected = Object.keys(selectedIds).filter(k => selectedIds[k] === true);

    let updatedSelectedIds: TreeSelectedIds;
    if (cascadeSelection) {
      updatedSelectedIds = cascadeMultiSelection({
        changes,
        selectedIds,
        normalizedTree,
      });
    } else {
      updatedSelectedIds = selectedIds;
      if (!allowMultiSelection) {
        for (const key in updatedSelectedIds) {
          updatedSelectedIds[key] = false;
        }
      }
      updatedSelectedIds[changes.id] = changes.isSelected;
    }

    const updatedSelected = Object.keys(updatedSelectedIds).filter(k => selectedIds[k] === true);
    const added = GetUniquePrimatives(originalSelected, updatedSelected);
    const removed = GetUniquePrimatives(updatedSelected, originalSelected);

    handleSelectionUpdate(updatedSelectedIds, [...added, ...removed]);
  }

  function isExpandable(id: IdType): boolean {
    const treeItem = normalizedTree[id].original as TreeItem<BIM360ItemBase>;
    const item: BIM360ItemBase = treeItem.relatedObject as BIM360ItemBase;
    if (item instanceof DirectoryUI) {
      const hasLoadedItems = foldersOnly ? item.SubFolders.length > 0 : item.SubItems.length > 0;
      return hasLoadedItems || !item.AreItemsPopulated;
    } else if (item instanceof FileUI) {
      return item.IsComposite;
    }
    return false;
  }

  function folderStateChange(folder: DirectoryUI, isRemoved: boolean): void {
    const modified: string[] = [];

    if (isRemoved) {
      if (selectedIds[folder.Id] === true) {
        modified.push(folder.Id);
        selectedIds[folder.Id] = false;
      }
      folderToolActivated(folder, FolderTools.UncheckRecursive);
    } else {
      const shouldInclude = folder.IncludeThisFolder || folder.IsDynamic;
      if (shouldInclude && selectedIds[folder.Id] !== true) {
        modified.push(folder.Id);
        selectedIds[folder.Id] = true;
      }

      if (!shouldInclude && selectedIds[folder.Id] === true) {
        modified.push(folder.Id);
        selectedIds[folder.Id] = false;
      }

      if (!folder.IsDynamic) {
        folderToolActivated(folder, folder.IsRecursive ? FolderTools.CheckRecursive : FolderTools.CheckSingle);
      }
    }

    populateForceCheckDown(folder);
    const setResult = populateFolderSelectionDown(folder, selectedIds);
    setResult.modified.forEach(m => modified.push(m));
    handleSelectionUpdate(setResult.selection, modified);
  }

  function populateForceCheckDown(folder: DirectoryUI): void {
    const forceCheck = (selectedIds[folder.Id] === true && folder.IsDynamic) || isAnyParentSelected(folder)
      ? true
      : undefined;

    const recursive = forceCheck === true && folder.IsRecursive && folder.IsDynamic;

    setChildrenForceCheck(folder, forceCheck, recursive);
    if (!recursive) {
      // If we are NOT setting the current state recursively we need to loop through sub-folders and still
      // do recursion but set to whatever that folder demands, not force set state through the tree.
      for (const subFolder of folder.SubFolders) {
        populateForceCheckDown(subFolder);
      }
    }
  }

  function populateFolderSelectionDown(folder: DirectoryUI, ids: TreeSelectedIds): {
    selection: TreeSelectedIds,
    modified: string[]
  } {
    let newIds: TreeSelectedIds = ids;

    const recursive = ids[folder.Id] === true && folder.IsDynamic && folder.IsRecursive;
    const includeCurrent = ids[folder.Id] === true;

    const modified: string[] = [];

    if (!recursive) {
      // If we are NOT setting the current state recursively we need to loop through sub-folders and still
      // do recursion but set to whatever that folder demands, not force set selection through the tree.
      for (const subFolder of folder.SubFolders) {
        const childResult = populateFolderSelectionDown(subFolder, newIds);
        newIds = childResult.selection;
        childResult.modified.forEach(m => modified.push(m));
      }
    }

    const setResult = setChildrenCheckState(folder, newIds, false, recursive);
    setResult.modified.forEach(m => modified.push(m));
    newIds = setResult.selection
    newIds[folder.Id] = includeCurrent;
    return {selection: newIds, modified: modified};
  }

  function setChildrenCheckState(
    folder: DirectoryUI,
    checkedIds: TreeSelectedIds,
    checked: boolean, recursive: boolean
  ): {
    selection: TreeSelectedIds,
    modified: string[]
  } {
    const modified: string[] = [];

    for (const file of folder.Files) {
      if (checkedIds[file.Id] !== checked) {
        checkedIds[file.Id] = checked;
        modified.push(file.Id);
      }
    }

    if (recursive) {
      for (const subDirectory of folder.SubFolders) {
        if (checked && selectedIds[subDirectory.Id] !== true) {
          subDirectory.IsDynamic = folder.IsDynamic;
          subDirectory.IncludeThisFolder = true;
          modified.push(subDirectory.Id);
        }
        const childResult = setChildrenCheckState(subDirectory, checkedIds, checked, recursive);
        childResult.modified.forEach(m => modified.push(m));
      }
    }

    return {selection: checkedIds, modified: modified};
  }

  function setChildrenForceCheck(folder: DirectoryUI, forceChecked: boolean | undefined, recursive: boolean): void {
    for (const subItem of folder.SubItems) {
      if (subItem instanceof DirectoryUI) {
        subItem.ForceCheckState = recursive ? forceChecked : undefined;
      } else {
        subItem.ForceCheckState = forceChecked;
        if (subItem.IsComposite && subItem.AreItemsPopulated) {
          subItem.SubItems.forEach(z => z.ForceCheckState = forceChecked)
        }
      }
    }

    if (recursive) {
      for (const subDirectory of folder.SubFolders) {
        setChildrenForceCheck(subDirectory, forceChecked, recursive);
      }
    }
  }

  function folderToolActivated(folder: DirectoryUI, tool: FolderTools): void {
    switch (tool) {
      case FolderTools.CheckSingle:
        loadNodeChildren(folder)
          .then(() => {
            const setResult = setChildrenCheckState(folder, selectedIds, true, false);
            handleSelectionUpdate(setResult.selection, setResult.modified);
          })
          .catch(error => handleError(error, 'Load node children'));
        break;
      case FolderTools.CheckRecursive:
        fullCheck = true;
        fullCheckFolder = folder;
        finishFullCheck();
        break;
      case FolderTools.UncheckSingle:
        loadNodeChildren(folder)
          .then(() => {
            const setResult = setChildrenCheckState(folder, selectedIds, false, false);
            handleSelectionUpdate(setResult.selection, setResult.modified);
          })
          .catch(error => handleError(error, 'Load node children'));
        break;
      case FolderTools.UncheckRecursive:
        fullCheck = false;
        fullCheckFolder = folder;
        finishFullCheck();
        break;
    }
  }

  function finishFullCheck(): void {
    loadNodeChildren(fullCheckFolder!, true)
      .then(() => {
        const setResult = setChildrenCheckState(fullCheckFolder!, selectedIds, fullCheck, true);
        handleSelectionUpdate(setResult.selection, setResult.modified);
      })
      .catch(error => handleError(error, 'Load node children'));
  }

  function isAnyParentSelected(item: BIM360ItemBase): boolean {
    if (item.ParentId == null) {
      return false;
    }
    const treeItem = normalizedTree[item.ParentId]?.original as TreeItem<BIM360ItemBase>;
    if (treeItem == null) {
      return false;
    }
    const parent = treeItem.relatedObject;

    if (selectedIds[item.ParentId] === true
      && (!(parent instanceof DirectoryUI) || (parent.IsRecursive && parent.IsDynamic))) {
      return true;
    }

    return parent != null && isAnyParentSelected(parent);
  }

  function handleSelectionUpdate(newSelection: TreeSelectedIds, modified: string[]): void {
    setSelectedIds(newSelection);

    if (onSelectionChange) {
      const selection: {
        item: BIM360ItemBase,
        newIsSelected: boolean
      }[] = [];
      for (const id of modified) {
        const object = FindProjectItemRecursive(rootDirectories, id);
        if (object == null) {
          continue;
        }

        selection.push({item: object, newIsSelected: newSelection[id] === true});
      }

      onSelectionChange(selection);
    }
  }

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  return (
    <FlexColumn style={{flex: 1}}>
      {
        !loading &&
          <Tree {...getTreeProps()} normalizedTree={normalizedTree}>
            {orderedIds
              .map((id) => normalizedTree[id])
              .map(getTreeNodeProps)
              .map((treeNodeProps) => {
                const treeItem = normalizedTree[treeNodeProps.id].original as TreeItem<BIM360ItemBase>;
                const item = treeItem.relatedObject;
                const display = !foldersOnly || item instanceof DirectoryUI;
                return (
                  display &&
                  <React.Fragment key={`wrap-${treeNodeProps.id}`}>
                      <TreeNode
                          key={treeNodeProps.id}
                          {...treeNodeProps}
                          isMultiSelectable={allowSelection && allowMultiSelection}
                          isSingleSelectable={allowSelection && !allowMultiSelection}
                          isExpandable={isExpandable(treeNodeProps.id)}
                          onExpand={() => expandNode(treeNodeProps.id, treeNodeProps.isExpanded, treeNodeProps.onExpand)}>
                        {
                          (isExpandable(treeNodeProps.id)) && (
                            <ExpandButton
                              style={{
                                margin: '0 3px',
                              }}
                              isExpanded={treeNodeProps.isExpanded}
                              onExpand={() => expandNode(treeNodeProps.id, treeNodeProps.isExpanded, treeNodeProps.onExpand)}
                            />
                          )
                        }

                          <TreeNodeFileContent
                              item={item as BIM360ItemBase}
                              treeNodeProps={treeNodeProps}
                              allowSelection={allowSelection}
                              allowMultiSelection={allowMultiSelection}
                              allowDownload={allowDownload}
                              onFolderInclusionChanged={folderStateChange}
                              isDirectoryIncluded={item instanceof DirectoryUI && (selectedIds[item.Id] === true || isAnyParentSelected(item))}
                              onError={onError}/>
                      </TreeNode>
                      <ProgressNode
                          show={loadingIds.includes(treeNodeProps.id as string)}
                          depth={treeNodeProps.depth + 1}/>
                  </React.Fragment>
                )
              })}
          </Tree>
      }
      {
        loading &&
          <CenteringContainer style={{flex: 1}}>
              <ProgressRing size={'large'}/>
          </CenteringContainer>
      }
    </FlexColumn>
  );
};

export default FileStructureList;