////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { TutorialType } from "../Enums";

export class TutorialService {
  public ShouldTutorialDisplay(tutorialId: TutorialType): boolean {
    return localStorage.getItem(this.GetStorageName(tutorialId)) !== "0";
  }

  public SetTutorialDisplay(tutorialId: TutorialType, display: boolean): void {
    if (display) {
      localStorage.removeItem(this.GetStorageName(tutorialId));
    } else {
      localStorage.setItem(this.GetStorageName(tutorialId), "0");
    }
  }

  private GetStorageName(tutorialId: string): string {
    return `tutorial_${tutorialId}`;
  }
}