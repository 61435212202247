////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import Selector from "./Selector";
import {FlexRowCentered} from "../CommonStyledComponents";
import ProgressRing from "@adsk/alloy-react-progress-ring";

const ProjectDropdownProjectWise = ({projects, selectedProject, loadingProjects, onSelected}: {
  projects: ProjectWiseConfigurationUI[],
  selectedProject: ProjectWiseConfigurationUI | undefined,
  loadingProjects: boolean,
  onSelected?: (project: ProjectWiseConfigurationUI | undefined) => void
}) => {
  const projectsConverted: DefaultItem[] = projects.map(p => {
    return {
      value: p.ApiConfiguration.id!,
      label: p.ApiConfiguration.repositoryName!,
      subtext: p.ApiConfiguration.serverAddress
    }
  });
  const selectedConverted = projectsConverted.find(p => p.value === selectedProject?.ApiConfiguration.id);
  const [projectItems, setProjectItems] = useState(projectsConverted);
  const [selected, setSelected] = useState(selectedConverted);

  useEffect(() => {
    const convertedProjects = projects
      .map(p => {
        return {
          value: p.ApiConfiguration.id!,
          label: p.ApiConfiguration.repositoryName!,
          subtext: p.ApiConfiguration.serverAddress
        }
      })
      .sort((a, b) => `${a.subtext}${a.label}` > `${b.subtext}${b.label}` ? 1 : -1);
    const selectedConverted = projectsConverted.find(p => p.value === selectedProject?.ApiConfiguration.id);

    setProjectItems(convertedProjects);
    setSelected(selectedConverted);
  }, [projects, selectedProject]);

  return (
    <FlexRowCentered>
      <span style={{marginRight: '1em'}}>Configuration:</span>
      {loadingProjects && <ProgressRing size={'small'}/>}
      {!loadingProjects &&
        <Selector
          items={projectItems}
          selected={selected}
          onSelectionChange={s => {
            setSelected(s ?? undefined);
            if (onSelected) {
              const item = projects.find(p => p.ApiConfiguration.id === s?.value);
              onSelected(item);
            }
          }}/>
      }
    </FlexRowCentered>
  );
};

export default ProjectDropdownProjectWise;