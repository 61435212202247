////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react';
import { ClientProvider } from "../clients/ClientProvider";
import { BlueButton, FlexColumn, FlexRow } from '../CommonStyledComponents';
import Theme from "@adsk/alloy-react-theme";
import {BasicButton} from "@adsk/alloy-react-button";
import {TriangleDownIcon, TriangleUpIcon} from "@adsk/alloy-react-icon";
import {Flyout} from "@adsk/alloy-react-tooltip";
import Avatar from '@adsk/alloy-react-avatar';

const authService = ClientProvider.AuthService;

const Profile = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Flyout
      show={menuOpen}
      onHide={() => setMenuOpen(false)}
      onShow={() => setMenuOpen(true)} placement={Flyout.PLACEMENTS.BOTTOM_END}
      content={(
        <FlexRow>
          <img src={authService.CurrentUser?.profileImages?.sizeX40}
               style={{ width: '40px', height: '40px', objectFit: "cover", marginRight: '2em' }}
               alt={'User Profile'} />
          <FlexColumn style={{ flex: '1' }}>
            <span
              style={Theme.typography.bodyMediumBold}>{authService.CurrentUser?.firstName} {authService.CurrentUser?.lastName}</span>
            <span style={{ marginTop: '0.2em', marginBottom: '1em' }}>{authService.CurrentUser?.email}</span>
            <BlueButton style={{ width: '100%' }}
                        onClick={() => window.location.href = 'https://autodesk.com'}>Exit</BlueButton>
          </FlexColumn>
        </FlexRow>
      )}>
      <BasicButton onClick={() => setMenuOpen(!menuOpen)}>
        {authService.CurrentUser != null && (
          <>
            <Avatar name={`${authService.CurrentUser.firstName} ${authService.CurrentUser.lastName}`}
                    size={Avatar.SIZES.MEDIUM_24} imageUrl={authService.CurrentUser.profileImages?.sizeX40} />
            <span
              style={{ marginLeft: 10 }}>{`${authService.CurrentUser.firstName} ${authService.CurrentUser.lastName}`}</span></>
        )}
        {menuOpen && <TriangleUpIcon size={20} />}
        {!menuOpen && <TriangleDownIcon size={20} />}
      </BasicButton>
    </Flyout>
  );
};

export default Profile;