////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {DirectDownloadActions} from "../../Enums";
import {DirectDownloadState} from "../states/DirectDownloadState";

export function reducer(
  state: DirectDownloadState,
  action: { type: DirectDownloadActions, payload: any }
): DirectDownloadState {
  switch (action.type) {
    case DirectDownloadActions.loadingProjects:
      return {...state, LoadingProjects: action.payload};
    case DirectDownloadActions.multipleActions:
      return {...state, ...action.payload};
    case DirectDownloadActions.projects:
      return {...state, Projects: action.payload};
    case DirectDownloadActions.selectedProject:
      return {...state, SelectedProject: action.payload};
    case DirectDownloadActions.projectWiseConfigs:
      return {...state, ProjectWiseConfigs: action.payload};
    case DirectDownloadActions.selectedProjectWiseConfig:
      return {...state, SelectedProjectWiseConfig: action.payload};
    case DirectDownloadActions.loadingProjectWiseConfigs:
      return {...state, LoadingProjectWiseConfigs: action.payload};
    case DirectDownloadActions.selectedTab:
      return {...state, SelectedTab: action.payload};
  }
}