////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ConvertDuration{
  public static Convert(value: number): string{
    // Assume value is in seconds
    let minutes = Math.floor(value / 60);
    let seconds = Math.round(value - (minutes * 60));

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }

    if (minutes < 60) {
      return `${minutes}m ${seconds}s`;
    } else {
      const hours = Math.floor(minutes / 60);
      minutes = Math.floor(minutes - (hours * 60));
      return `${hours}h ${minutes}m ${seconds}s`;
    }
  }
}