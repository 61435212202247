////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {ProjectUI} from "../dataModel/ProjectUI";
import FileStructureList from "./FileStructureList";
import ProjectDropdown from "./ProjectDropdown";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {CenteringContainer, FlexColumn} from '../CommonStyledComponents';
import Theme from "@adsk/alloy-react-theme";
import Illustration from "@adsk/alloy-react-illustration";
import {FileUI} from "../dataModel/FileUI";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {FILE_TAB_IDS, FILE_TAB_TITLES, FILE_TABS} from "../Constants";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import ProjectDropdownProjectWise from "./ProjectDropdownProjectWise";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {IsTabAvailableToUser} from "../Utility";
import {ClientProvider} from "../clients/ClientProvider";

const authService = ClientProvider.AuthService;

const SettingsDestination = (
  {
    projects,
    loadingProjects,
    selectedProject,
    projectWiseConfigs,
    loadingProjectWiseConfigs,
    selectedProjectWiseConfig,
    getRootDirectories,
    getZipContents,
    getDirectoryContents,
    expandedIds,
    onExpandedChanged,
    onProjectSelected,
    onDestinationSelected,
    onProjectWiseConfigSelected,
    selectedDirectory,
    onSelectedChanged,
    selectedTab,
    onTabChange,
    onError
  }: {
    projects: ProjectUI[],
    loadingProjects: boolean,
    selectedProject: ProjectUI | undefined,
    projectWiseConfigs: ProjectWiseConfigurationUI[],
    loadingProjectWiseConfigs: boolean,
    selectedProjectWiseConfig: ProjectWiseConfigurationUI | undefined,
    getRootDirectories: (rootObject: ProjectUI | ProjectWiseConfigurationUI) => Promise<DirectoryUI[]>
    getDirectoryContents: (directory: DirectoryUI) => Promise<void>,
    getZipContents: (file: FileUI) => Promise<void>,
    expandedIds?: string[],
    onExpandedChanged?: (expandedIds: string[]) => void,
    onProjectSelected?: (project: ProjectUI | undefined) => void,
    onDestinationSelected?: (destination: DirectoryUI | undefined) => void
    onProjectWiseConfigSelected?: (config: ProjectWiseConfigurationUI | undefined) => void,
    selectedDirectory: DirectoryUI | undefined,
    onSelectedChanged?: (selected: DirectoryUI | undefined) => void,
    selectedTab: string,
    onTabChange?: (newTab: string) => void,
    onError?: (error: any, operation: string) => void,
  }) => {
  const defaultSelectionId = selectedDirectory?.Id;
  const [selection, setSelection] = useState(defaultSelectionId == null ? [] : [defaultSelectionId]);

  function projectClick(project: ProjectUI | undefined): void {
    if (onDestinationSelected) {
      onDestinationSelected(undefined);
    }

    if (onExpandedChanged) {
      onExpandedChanged([]);
    }

    if (onProjectSelected) {
      onProjectSelected(project);
    }
  }

  function folderSelectionChanged(currentSelection: { item: BIM360ItemBase, newIsSelected: boolean }[]): void {
    const selectedItems = currentSelection.filter(s => s.newIsSelected);
    if (selectedItems.length > 1) {
      throw Error('Destination only supports single selection');
    }
    if (selectedItems.length > 0 && !(selectedItems[0].item instanceof DirectoryUI)) {
      throw Error('Incorrect object type passed, must be a directory.');
    }
    if (onDestinationSelected) {
      onDestinationSelected(selectedItems.length === 0 ? undefined : selectedItems[0].item as DirectoryUI)
    }

    const newSelection = selectedItems.length === 0 ? undefined : selectedItems[0].item as DirectoryUI;
    setSelection(newSelection == null ? [] : [newSelection.Id]);

    if (onSelectedChanged) {
      onSelectedChanged(newSelection);
    }
  }

  return (
    <FlexColumn>
      <h2 style={Theme.typography.heading2}>Destination</h2>
      <Tabs active={selectedTab}
            style={{flex: 1, display: 'flex', flexDirection: "column", overflow: 'auto'}}
            onChange={tab => {
              if (onTabChange) {
                onTabChange(tab);
              }
            }}>
        {Object.keys(FILE_TABS).map(k => {
          return !IsTabAvailableToUser(k, authService.CurrentUser) ? undefined : (
            <Tab label={FILE_TAB_TITLES[k]}
                 tab={FILE_TAB_IDS[k]}
                 key={FILE_TAB_IDS[k]}
                 style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
              <FlexColumn style={{paddingTop: '1em'}}>
                {selectedTab === FILE_TAB_IDS[FILE_TABS.FORGE] &&
                    <>
                      {
                        (loadingProjects || projects.length > 0) &&
                          <div style={{width: '400px'}}>
                              <ProjectDropdown projects={projects}
                                               selectedProject={selectedProject}
                                               loadingProjects={loadingProjects}
                                               onSelected={projectClick}/>
                          </div>
                      }
                      {
                        !loadingProjects && projects.length === 0 &&
                          <CenteringContainer style={{flexDirection: 'column'}}>
                              <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                              <p style={Theme.typography.bodyLarge}>You don't have any projects available</p>
                          </CenteringContainer>
                      }
                      {
                        loadingProjects &&
                          <CenteringContainer style={{flex: 1}}>
                              <ProgressRing size={'large'}/>
                          </CenteringContainer>
                      }
                        <FileStructureList
                            rootObject={selectedProject}
                            getRootDirectories={getRootDirectories}
                            getDirectoryContents={getDirectoryContents}
                            getZipContents={getZipContents}
                            allowSelection={true}
                            allowMultiSelection={false}
                            onSelectionChange={folderSelectionChanged}
                            foldersOnly={true}
                            defaultSelection={selection}
                            defaultExpansion={expandedIds}
                            onExpandedChange={onExpandedChanged}
                            onError={onError}/>
                    </>
                }
                {selectedTab === FILE_TAB_IDS[FILE_TABS.PROJECTWISE] &&
                    <>
                      {
                        (loadingProjectWiseConfigs || projectWiseConfigs.length > 0) &&
                          <ProjectDropdownProjectWise
                              projects={projectWiseConfigs}
                              selectedProject={selectedProjectWiseConfig}
                              loadingProjects={loadingProjectWiseConfigs}
                              onSelected={onProjectWiseConfigSelected}/>
                      }
                      {
                        !loadingProjectWiseConfigs && projectWiseConfigs.length === 0 &&
                          <CenteringContainer style={{flexDirection: 'column'}}>
                              <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                              <p style={Theme.typography.bodyLarge}>You don't have any ProjectWise Projects
                                  available</p>
                          </CenteringContainer>
                      }
                        <FileStructureList
                            rootObject={selectedProjectWiseConfig}
                            getRootDirectories={getRootDirectories}
                            getZipContents={getZipContents}
                            getDirectoryContents={getDirectoryContents}
                            onSelectionChange={folderSelectionChanged}
                            allowSelection={true}
                            allowMultiSelection={false}
                            foldersOnly={true}
                            defaultSelection={selection}
                            defaultExpansion={expandedIds}
                            onExpandedChange={onExpandedChanged}
                            onError={onError}/>
                    </>
                }
              </FlexColumn>
            </Tab>
          )
        })}
      </Tabs>
    </FlexColumn>
  );
};

export default SettingsDestination;