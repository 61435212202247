////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {ProjectUI} from "../dataModel/ProjectUI";
import {BlueButton, CenteringContainer, FlexColumn, FlexFill, FlexRowCentered} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import Selector from "./Selector";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import {PlusIcon, XIcon} from "@adsk/alloy-react-icon";

const BulkSettingsSource = ({projects, loadingProjects, selectedIds, onSelectionChanged}: {
  projects: ProjectUI[],
  loadingProjects: boolean,
  selectedIds: string[],
  onSelectionChanged?: (selected: ProjectUI[]) => void
}) => {
  const [hubItems, setHubItems] = useState<DefaultItem[]>([]);
  const [selectedHub, setSelectedHub] = useState<DefaultItem | undefined>(undefined);
  const [visibleProjects, setVisibleProjects] = useState<ProjectUI[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<ProjectUI[]>(projects.filter(p => selectedIds.includes(p.Id)));

  useEffect(() => {
    const hubs: DefaultItem[] = [];
    const visibleProjectsInternal: ProjectUI[] = [];
    let selectedHubId = selectedProjects.length > 0 ? selectedProjects[0].HubId : undefined;
    let selectedHub: DefaultItem | undefined;
    projects.forEach(project => {
      let existingHub = hubs.find(h => h.value === project.HubId);
      if (existingHub == null) {
        existingHub = {
          value: project.HubId,
          label: project.HubName,
          subtext: project.HubType
        };
        hubs.push(existingHub);
      }

      if (selectedHubId == null) {
        if (selectedHub == null) {
          selectedHub = existingHub;
        }
      } else {
        if (selectedHubId === existingHub.value) {
          selectedHub = existingHub;
        }
      }

      if (project.HubId === selectedHub?.value) {
        visibleProjectsInternal.push(project);
      }
    });

    setHubItems(hubs);
    setSelectedHub(selectedHub);
    setVisibleProjects(visibleProjectsInternal);
  }, []);

  function hubChange(newSelected: DefaultItem): void {
    if (selectedHub?.value === newSelected.value) {
      return;
    }

    setSelectedHub(newSelected);
    setVisibleProjects(projects.filter(p => p.HubId === newSelected.value));
    selectionChange([]);
  }

  function onCheckChange(project: ProjectUI, state: CheckboxState): void {
    let updatedProjects: ProjectUI[] = [];
    let makeUpdate = false;
    if (state) {
      if (!selectedProjects.includes(project)) {
        updatedProjects = [...selectedProjects, project];
        makeUpdate = true;
      }
    } else {
      if (selectedProjects.includes(project)) {
        selectedProjects.splice(selectedProjects.indexOf(project), 1);
        updatedProjects = selectedProjects;
        makeUpdate = true;
      }
    }

    if (makeUpdate) {
      selectionChange(updatedProjects);
    }
  }

  function selectAll(selected: boolean): void {
    selectionChange(selected ? visibleProjects : []);
  }

  function selectionChange(newSelection: ProjectUI[]): void {
    setSelectedProjects(newSelection);
    if (onSelectionChanged) {
      onSelectionChanged(newSelection);
    }
  }

  return (
    <FlexColumn>
      <FlexRowCentered>
        <h2 style={Theme.typography.heading2}>Source</h2>
      </FlexRowCentered>
      {
        (loadingProjects || projects.length > 0) &&
        <FlexRowCentered>
          <div style={{width: '400px'}}>
            <Selector
              items={hubItems}
              selected={selectedHub ?? null}
              loadingProjects={loadingProjects}
              onSelectionChange={i => hubChange(i!)}/>
          </div>
          <FlexFill/>
          <BlueButton onClick={() => selectAll(true)}
                      style={{marginRight: '2em'}}>
            <FlexRowCentered>
              <PlusIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Select All</span>
            </FlexRowCentered>
          </BlueButton>
          <BlueButton onClick={() => selectAll(false)}>
            <FlexRowCentered>
              <XIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Select None</span>
            </FlexRowCentered>
          </BlueButton>
        </FlexRowCentered>
      }
      {
        loadingProjects &&
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      }
      {
        !loadingProjects &&
        <FlexColumn
          style={{
            marginTop: '1em',
            border: '1px solid',
            borderColor: Theme.colors.charcoal300,
            padding: '1em',
            overflow: 'auto'
          }}>
          {visibleProjects.map(p =>
            <FlexRowCentered key={p.Id} style={{marginBottom: '0.5em'}}>
              <Checkbox
                checked={selectedProjects.includes(p)}
                onChange={c => onCheckChange(p, c)}/>
              <label style={{marginLeft: '0.5em'}}>{p.Name}</label>
            </FlexRowCentered>
          )}
        </FlexColumn>
      }
    </FlexColumn>
  );
};

export default BulkSettingsSource;