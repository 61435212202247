////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import styled from "styled-components";
import { FlexFill, FlexRow, FlexColumn, CenteringContainer } from "./CommonStyledComponents";
import Theme from '@adsk/alloy-react-theme';
import task1 from './assets/Tutorial_Task_1.png';
import task2 from './assets/Tutorial_Task_2.png';
import task3 from './assets/Tutorial_Task_3.png';
import task4 from './assets/Tutorial_Task_4.png';
import task5a from './assets/Tutorial_Task_5_Image.png';
import task5b from './assets/Tutorial_Task_5_Text.png';
import report1 from './assets/Tutorial_Report_1.png';
import report2 from './assets/Tutorial_Report_2.png';
import report3 from './assets/Tutorial_Report_3.png';
import direct1a from './assets/Tutorial_Direct_1a.png';
import direct1b from './assets/Tutorial_Direct_1b.png';
import direct1c from './assets/Tutorial_Direct_1c.png';
import direct1d from './assets/Tutorial_Direct_1d.png';
import direct2 from './assets/Tutorial_Direct_2.png';
import direct3 from './assets/Tutorial_Direct_3.png';
import direct4 from './assets/Tutorial_Direct_4.png';

const ContentSection = styled(FlexFill)`
  padding: 1em;
  flex: 1;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ContentImage = styled.img`
  max-height: 300px;
  margin: 1em 1em 1em 0;
  object-fit: none;
`;

const NoScaleImage = styled.img`
  object-fit: none;
`;

const IconImage = styled.img`
  object-fit: contain;
  width: 64px;
  height: 64px;
`;

const IconWrapper = styled(FlexFill)`
  margin: 1em;
  text-align: center;
`;

export const TaskTutorialPages = [
  {
    title: 'Tasks Tutorial | 1 of 5',
    content: (
      <FlexRow style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Use this page to create and manage your file and folder copy tasks.
          </p>
        </ContentSection>
        <ContentSection>
          <ImageSection>
            <ContentImage src={task1} alt={'Task Screen Grab'} />
          </ImageSection>
        </ContentSection>
      </FlexRow>
    ),
    isFinal: false
  },
  {
    title: 'Tasks Tutorial | 2 of 5',
    content: (
      <FlexRow style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            You can sort your list of reports by clicking the column headers.
          </p>
          <p style={Theme.typography.bodyMedium}>
            You can search or filter your list of reports using the tools in the upper right.
          </p>
        </ContentSection>
        <ContentSection>
          <ImageSection>
            <ContentImage src={task2} alt={'Task Screen Grab'} />
          </ImageSection>
        </ContentSection>
      </FlexRow>
    ),
    isFinal: false
  },
  {
    title: 'Tasks Tutorial | 3 of 5',
    content: (
      <CenteringContainer>
        <div>
          <FlexColumn style={{ width: '500px' }}>
            <ContentSection>
              Use the Action buttons to...
            </ContentSection>
            <NoScaleImage src={task3} alt={'Task Screen Grab'} style={{ margin: '3em 0 0.5em 0' }} />
            <FlexRow style={{ margin: '0 1em 1em 1em' }}>
              <FlexFill style={{ marginRight: '0.5em' }}><strong>Edit</strong> a task to change its settings</FlexFill>
              <FlexFill style={{ marginRight: '0.5em', marginLeft: '0.5em' }}><strong>Pause</strong> a task to keep it
                from running</FlexFill>
              <FlexFill style={{ marginRight: '0.5em', marginLeft: '0.5em' }}><strong>Duplicate</strong> a task to make
                a new on starting with the same settings</FlexFill>
              <FlexFill><strong>Delete</strong> a task</FlexFill>
            </FlexRow>
          </FlexColumn>
        </div>
      </CenteringContainer>
    ),
    isFinal: false
  },
  {
    title: 'Tasks Tutorial | 4 of 5',
    content: (
      <FlexColumn style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Create a new task using the button.
          </p>
          <ContentImage src={task4} alt={'Task Screen Grab'} />
          <p style={Theme.typography.bodyMedium}>
            For more information on creating tasks, click
            <a href={'https://help.autodesk.com/view/RTDOCS/ENU/'}
               target={'_blank'}
               rel={'noreferrer'}>
              here
            </a>.
          </p>
        </ContentSection>
      </FlexColumn>
    ),
    isFinal: false
  },
  {
    title: 'Tasks Tutorial | 5 of 5',
    content: (
      <FlexColumn style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            When transferring files created in AutoCAD Plant 3D, you will need to take additional steps to ensure a
            successful migration.
          </p>
          <FlexRow style={{ margin: '2em 1em' }}>
            <FlexFill />
            <ContentImage src={task5a} alt={'Plant 3D Logo'} />
            <ContentImage src={task5b} alt={'Plant 3D Text'} />
            <FlexFill />
          </FlexRow>
          <p style={Theme.typography.bodyMedium}>
            For more information on transferring Plant 3D files, click
            <a
              href={'https://knowledge.autodesk.com/support/bim-360/learn-explore/caas/sfdcarticles/sfdcarticles/BIM-360-Teams-to-BIM-360-Docs-in-AutoCAD-Plant-3D.html'}
              target={'_blank'}
              rel={'noreferrer'}>
              here
            </a>.
          </p>
        </ContentSection>
      </FlexColumn>
    ),
    isFinal: true
  },
]

export const ReportsTutorialPages = [
  {
    title: 'Reports Tutorial | 1 of 3',
    content: (
      <FlexRow style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Use this page to see a list of each completed task.
          </p>
          <p style={Theme.typography.bodyMedium}>
            From here, you can review the details, download the data, or delete the report.
          </p>
        </ContentSection>
        <ImageSection>
          <ContentImage src={report1} alt={'Report Screen Grab'} />
        </ImageSection>
      </FlexRow>
    ),
    isFinal: false
  },
  {
    title: 'Reports Tutorial | 2 of 3',
    content: (
      <FlexRow style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            You can sort your list of reports by clicking the column headers..
          </p>
          <p style={Theme.typography.bodyMedium}>
            You can search or filter your list of reports using the tools in the upper right.
          </p>
        </ContentSection>
        <ImageSection>
          <ContentImage src={report2} alt={'Report Screen Grab'} />
        </ImageSection>
      </FlexRow>
    ),
    isFinal: false
  },
  {
    title: 'Reports Tutorial | 3 of 3',
    content: (
      <FlexColumn style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            You can download the details of the report as a CSV file using the icon.
          </p>
          <ContentImage src={report3} alt={'Report Screen Grab'} />
        </ContentSection>
      </FlexColumn>
    ),
    isFinal: true
  },
]

export const DirectDownloadTutorialPages = [
  {
    title: 'Single Download Tutorial | 1 of 4',
    content: (
      <CenteringContainer>
        <div>
          <FlexColumn style={{ width: '500px' }}>
            <ContentSection style={Theme.typography.bodyMedium}>
              Use this page to download files directly from your cloud repositories to your local computer.
            </ContentSection>
            <FlexRow style={{ margin: '0 1em' }}>
              <IconWrapper>
                <IconImage src={direct1a} alt={'Autodesk Docs Icon'} />
                <div style={Theme.typography.bodyMedium}>Autodesk Docs</div>
              </IconWrapper>
              <IconWrapper>
                <IconImage src={direct1b} alt={'BIM 360 Docs Icon'} />
                <div style={Theme.typography.bodyMedium}>BIM 360 Docs</div>
              </IconWrapper>
              <IconWrapper>
                <IconImage src={direct1c} alt={'BIM 360 Teams Icon'} />
                <div style={Theme.typography.bodyMedium}>BIM 360 Team</div>
              </IconWrapper>
              <IconWrapper>
                <IconImage src={direct1d} alt={'A360 Team Icon'} />
                <div style={Theme.typography.bodyMedium}>A360 Team</div>
              </IconWrapper>
            </FlexRow>
          </FlexColumn>
        </div>
      </CenteringContainer>
    ),
    isFinal: false
  },
  {
    title: 'Single Download Tutorial | 2 of 4',
    content: (
      <FlexRow style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Select the Organization and Project where the files you want to download reside.
          </p>
        </ContentSection>
        <ImageSection>
          <ContentImage src={direct2} alt={'Single Download Screen Grab'} />
        </ImageSection>
      </FlexRow>
    ),
    isFinal: false
  },
  {
    title: 'Single Download Tutorial | 3 of 4',
    content: (
      <FlexColumn style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Click the download icon next to each file you want to download.
          </p>
          <ContentImage src={direct3} alt={'Single Download Screen Grab'} />
          <p style={Theme.typography.bodyMedium}>
            Stay on the page until your download is complete.
          </p>
        </ContentSection>
      </FlexColumn>
    ),
    isFinal: false
  },
  {
    title: 'Single Download Tutorial | 4 of 4',
    content: (
      <FlexColumn style={{ height: '100%' }}>
        <ContentSection>
          <p style={Theme.typography.bodyMedium}>
            Some RVT files contain other RVT files within them. You can expand those files to download the individual
            RVT files within.
          </p>
          <div style={{ textAlign: 'center' }}>
            <ContentImage src={direct4} alt={'Single Download Screen Grab'} />
          </div>
          <p style={Theme.typography.bodyMedium}>
            You can also select the parent container RVT file and download all files within separately.
          </p>
        </ContentSection>
      </FlexColumn>
    ),
    isFinal: true
  },
]
