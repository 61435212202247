// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {FileUI} from './FileUI';
import {RecurrenceSettings} from './RecurrenceSettings';
import {ExtractLocationType, FileDestinationNamingType, JobStatusType} from '../clients/Classes';
import {DirectoryUI} from './DirectoryUI';
import {ProjectNameLoadBase} from './ProjectNameLoadBase';
import {ZipTaskSetting} from './ZipTaskSetting';
import {TaskTrigger} from '../Enums';

export class Task extends ProjectNameLoadBase {
  Id: string | undefined;
  UserId: string | undefined;
  CustomerId: string | undefined;
  BulkTaskId: string | undefined;
  Name: string | undefined;
  Files: (FileUI | ZipTaskSetting)[];
  HubId: string | undefined;
  ProjectId: string | undefined;

  Directories: DirectoryUI[];

  RawStatus: JobStatusType | undefined;

  IsPaused: boolean = false;
  Trigger: TaskTrigger | undefined;
  StartDate: Date | undefined;
  RecurrenceSettings: RecurrenceSettings | undefined;

  ExportLocationType: ExtractLocationType;
  ExportLocation: string | undefined;
  ExportDirectory: DirectoryUI | undefined;
  ExportDestinationNaming: FileDestinationNamingType;

  EmailOnCompletion: boolean = false;
  AttachCsv: boolean = false;

  DoNotMaintainFolderStructure: boolean = false;
  Archive: boolean = true;

  LastRun: Date | undefined;
  NextRun: Date | undefined;

  constructor() {
    super();
    this.Files = [];
    this.Directories = [];
    this.ExportDestinationNaming = FileDestinationNamingType.None;
    this.ExportLocationType = ExtractLocationType.None;
    this.Trigger = 'OnceNow';
  }

  GetComparisonClone(): Task {
    const task = new Task();
    task.Id = this.Id;
    task.Name = this.Name;
    task.ExportLocationType = this.ExportLocationType;
    task.ExportLocation = this.ExportLocation;
    task.ExportDestinationNaming = this.ExportDestinationNaming;
    task.Trigger = this.Trigger;
    this.Directories.forEach(d => task.Directories.push(d));
    this.Files.forEach(f => task.Files.push(f));

    return task;
  }
}
