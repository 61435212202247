// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { UsageDataItem } from './UsageDataItem';

export class UsageSummaryUI {
  TotalRunCount: number;
  TotalRunTimeSeconds: number;
  CustomerData: UsageDataItem[];

  constructor(runCount: number, runSeconds: number) {
    this.TotalRunCount = runCount;
    this.TotalRunTimeSeconds = runSeconds;
    this.CustomerData = [];
  }
}
