////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {pages} from "../pages/routes";
import SideNavigation, {SideNavigationProvider} from '@adsk/alloy-side-navigation';
import {PAGE_TITLES, PAGES, PATHS} from "../Constants";
import {useLocation, useNavigate} from "react-router";
import {ClientProvider} from "../clients/ClientProvider";
import {IsPageAvailableToUser} from "../Utility";

const authService = ClientProvider.AuthService;

const Nav = () => {
  const location = useLocation();
  const currentRoute = location.pathname.replace(PATHS[PAGES.ROOT], '');
  const currentPage = pages.find(p => p.path.startsWith(currentRoute) || `/${p.path}`.startsWith(currentRoute));

  const [selected, setSelected] = useState(currentPage?.title ?? PAGE_TITLES[PAGES.TASK]);

  const navigate = useNavigate();

  const pageItems = pages
    .filter(p => p.uiVisible && IsPageAvailableToUser(p, authService.CurrentUser))
    .map(p => {
      return {key: p.title, name: p.title, onClick: () => itemClicked(p), renderIcon: p.renderIcon};
    });

  function itemClicked(page: { title: string, path: string }): void {
    if (selected === page.title) {
      return;
    }
    setSelected(page.title);
    navigate(`${PATHS[PAGES.ROOT]}/${page.path}`)
  }

  return (
    <SideNavigationProvider>
      <SideNavigation
        selectedId={selected}
        style={{height: '100%'}}
        topItems={pageItems}/>
    </SideNavigationProvider>
  );
};

export default Nav;