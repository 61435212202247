// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  ExtractLocationType,
  FileDestinationNamingType,
  Job, JobDirectory,
  JobFile,
  JobScheduleType, ProjectWiseDirectory, ProjectWiseFile,
  Schedule
} from '../../clients/Classes';
import {Task} from '../Task';
import {FileStructureTranslator} from './FileStructureTranslator';
import {CronTranslator} from './CronTranslator';
import {ZipTaskSetting} from '../ZipTaskSetting';
import {ZipEntryUI} from '../ZipEntryUI';

export class TaskTranslator {
  static GetTask(job: Job): Task {
    const task = new Task();
    task.Id = job.id;
    task.UserId = job.userId;
    task.CustomerId = job.customerId;
    task.BulkTaskId = job.bulkJobId;
    task.Name = job.name;
    task.HubId = job.hubId;
    task.ProjectId = job.projectId;

    let hubName: string | undefined;
    let projectName: string | undefined;

    job.models!
      .filter(m => job.options?.singleFiles == null || job.options.singleFiles[m.id!] == null)
      .forEach(apiModel => {
        task.Files.push(FileStructureTranslator.GetFile(apiModel));
        if (apiModel instanceof ProjectWiseFile) {
          hubName = 'ProjectWise';
          projectName = apiModel.projectName;
        } else if (apiModel instanceof JobFile) {
          hubName = apiModel.hubName;
          projectName = apiModel.projectName;
        }
      });
    job.directories!.forEach(apiDirectory => {
      task.Directories.push(FileStructureTranslator.GetDirectory(apiDirectory, false));
      if (apiDirectory instanceof ProjectWiseDirectory) {
        hubName = 'ProjectWise';
        projectName = apiDirectory.projectName;
      } else if (apiDirectory instanceof JobDirectory) {
        hubName = apiDirectory.hubName;
        projectName = apiDirectory.projectName;
      }
    });
    task.RawStatus = job.status;
    task.IsPaused = job.schedule != null && job.schedule.isPaused!;
    task.EmailOnCompletion = job.options!.emailPreferences?.emailOnCompletion === true;
    task.AttachCsv = job.options!.emailPreferences?.attachExportFiles === true;
    task.DoNotMaintainFolderStructure = job.options!.doNotMaintainFolderStructure === true;
    task.Archive = job.options!.archive !== false;
    task.HubName = hubName;
    task.ProjectName = projectName;
    switch (job.scheduleType) {
      case JobScheduleType.None:
        break;
      case JobScheduleType.OnceNow:
        task.Trigger = 'OnceNow';
        break;
      case JobScheduleType.OnceLater:
        task.Trigger = 'OnceLater';
        task.StartDate = job.nextRun == null || job.nextRun!.startTime! < new Date()
          ? new Date(new Date().getTime() + (15 * 60 * 1000))
          : job.nextRun.startTime;
        break;
      case JobScheduleType.Recurring:
        task.Trigger = 'Recurring';
        task.RecurrenceSettings = CronTranslator.GetSettings(job.schedule!);
        task.StartDate = job.schedule!.startDate;
        break;
      case JobScheduleType.OnPublish:
        task.Trigger = 'OnPublish';
        break;
    }

    if (job.options == null) {
      task.ExportLocationType = ExtractLocationType.ModelDirectory;
      task.ExportDestinationNaming = FileDestinationNamingType.AppendTimestamp;
    } else {
      task.ExportLocationType = job.options.locationType ?? ExtractLocationType.None;
      task.ExportDirectory = job.options.extractDirectory == null
        ? undefined
        : FileStructureTranslator.GetDirectory(job.options.extractDirectory, false);
      task.ExportDestinationNaming = job.options.destinationNamingType ?? FileDestinationNamingType.None;

      if (job.options.singleFiles != null) {
        for (const key of Object.keys(job.options.singleFiles)) {
          const apiFile = job.models!.find(m => m.id === key);

          if (apiFile == null) {
            console.error(`Failed to find model with id ${key}`);
            continue;
          }
          const file = FileStructureTranslator.GetFile(apiFile);
          const setting = new ZipTaskSetting(file);

          const zips = job.options.singleFiles[key];
          zips.forEach(z => {
            const zip = new ZipEntryUI(apiFile.id!, z, apiFile.projectId!);
            zip.HubId = apiFile instanceof JobFile ? apiFile.hubId : undefined;
            zip.ParentId = apiFile.id;
            setting.Zips.push(zip);
          });

          task.Files.push(setting);
        }
      }

      if (job.nonDynamicDirectories != null) {
        job.nonDynamicDirectories.forEach(apiDirectory => {
          const directory = FileStructureTranslator.GetDirectory(apiDirectory, false);
          directory.IsDynamic = false;
          directory.IncludeThisFolder = true;
          task.Directories.push(directory);
        });
      }

      if (job.options.directorySelectionOptions != null) {
        for (const key of Object.keys(job.options.directorySelectionOptions)) {
          const directory = task.Directories.find(d => d.Id === key);

          if (directory == null) {
            console.warn(`Could not find directory with id ${key}`);
            continue;
          }

          const options = job.options.directorySelectionOptions[key];
          directory.IsDynamic = true;
          directory.IsRecursive = options?.recursive === true;
          directory.IncludeThisFolder = options?.copyCurrentFolder === true;
        }
      }
    }

    task.LastRun = job.lastRun?.startTime;
    task.NextRun = job.nextRun?.startTime;

    return task;
  }

  static GetScheduleFromTask(task: Task): Schedule | undefined {
    switch (task.Trigger) {
      case 'OnceNow':
      case 'OnceLater':
      case 'OnPublish':
        return undefined;
      case 'Recurring':
        const end = task.RecurrenceSettings?.EndType === 'Date' ? task.RecurrenceSettings.EndDate : undefined;
        const runs = task.RecurrenceSettings?.EndType === 'Occurrences' ? task.RecurrenceSettings.MaxRuns : 0;
        return new Schedule({
          cronString: CronTranslator.GetCronString(task.RecurrenceSettings!),
          startDate: task.RecurrenceSettings!.StartDate,
          endDate: end,
          maxRuns: runs
        });
    }
  }
}
