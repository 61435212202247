// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {PaginationPages} from "../Enums";
import {getCookie} from "../CookieUtils";

export class PageSizeService {
  public static GetPageSize(page: PaginationPages): number | undefined {
    const cookieVal = getCookie(`pageSize_${page}`);
    if (cookieVal != null && cookieVal.trim() !== '') {
      console.log(`Page size for ${page} is set from cookie "pageSize_${page}": ${cookieVal}`);
      return Number(cookieVal);
    }

    const overallVal = getCookie('pageSize');
    if (overallVal != null && overallVal.trim() !== '') {
      console.log(`Page size for ${page} is set from cookie "pageSize": ${overallVal}`);
      return Number(overallVal);
    }

    console.log(`Page size for ${page} is not set and will use back end default`);
    return undefined;
  }
}