////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {Task} from "../../dataModel/Task";
import {EDIT_TAB_IDS, EDIT_TABS, FILE_TAB_IDS, FILE_TABS} from "../../Constants";
import {ProjectUI} from "../../dataModel/ProjectUI";
import {ProjectWiseConfigurationUI} from "../../dataModel/ProjectWiseConfigurationUI";

export class TaskEditState {
  Task: Task | undefined;
  OriginalTask: Task | undefined;
  IsNewTask = false;
  IsDuplicating = false;

  Loading = true;
  LoadingProjects = false;
  LoadingProjectWiseConfigs = false;

  SelectedTab: string;

  Projects: ProjectUI[];
  ProjectWiseConfigs: ProjectWiseConfigurationUI[];

  Saving = false;
  showCancelConfirm = false;

  expandedSource: string[];
  expandedDestination: string[];

  selectedProjectSource: ProjectUI | undefined;
  selectedProjectDestination: ProjectUI | undefined;

  selectedProjectWiseConfigSource: ProjectWiseConfigurationUI | undefined;
  selectedProjectWiseConfigDestination: ProjectWiseConfigurationUI | undefined;

  selectedTabSource = FILE_TAB_IDS[FILE_TABS.FORGE];
  selectedTabDestination = FILE_TAB_IDS[FILE_TABS.FORGE];

  constructor() {
    this.SelectedTab = EDIT_TAB_IDS[EDIT_TABS.NAME];
    this.Projects = [];
    this.expandedDestination = [];
    this.expandedSource = [];
    this.ProjectWiseConfigs = [];
  }
}