////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ProjectWiseCredential, ProjectWiseCredentialWithSecret} from "../../clients/Classes";
import {ProjectWiseConfigurationUI} from "../../dataModel/ProjectWiseConfigurationUI";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {ProjectWiseRepository} from "../../clients/V2Classes";
import {REPOSITORY_TAB_IDS, REPOSITORY_TABS} from "../../Constants";

export class ProjectWiseSettingsState {
  Credentials: (ProjectWiseCredential | ProjectWiseCredentialWithSecret)[];
  Configurations: ProjectWiseConfigurationUI[];

  EditingCredential: ProjectWiseCredential | undefined;
  EditingConfiguration: ProjectWiseConfigurationUI | undefined;
  EditPassword: string | undefined;

  IsEditingCredential = false
  IsEditingConfiguration = false;
  EditIsNew = true;

  LoadingCredentials = false;
  LoadingConfigurations = false;
  LoadingRepositories = false;

  HasMoreCredentials = false;
  HasMoreConfigurations = false;
  HasMoreRepositories = false;

  CanCancelCredentials = false;
  CanCancelConfigurations = false;

  CredentialSelectOptions: DefaultItem[];
  SelectedCredential: DefaultItem | undefined;

  SelectedTabRepository: string;
  Repositories: ProjectWiseRepository[];
  SelectedRepository: ProjectWiseRepository | undefined;

  constructor() {
    this.Credentials = [];
    this.Configurations = [];
    this.CredentialSelectOptions = [];
    this.SelectedTabRepository = REPOSITORY_TAB_IDS[REPOSITORY_TABS.SEARCH];
    this.Repositories = [];
  }
}