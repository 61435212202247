// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {BIM360ItemBase} from './BIM360ItemBase';
import {FileType, HubType, ProjectType, StorageType} from '../clients/Classes';
import {ZipEntryUI} from './ZipEntryUI';
import {ProjectNameLoadBase} from './ProjectNameLoadBase';

export class FileUI extends ProjectNameLoadBase implements BIM360ItemBase {
  // Interface Implementation
  public IsFolder = false;
  public IsZipEntry = false;

  Id: string;
  ParentId: string | undefined;
  ProjectId: string;
  Name: string;

  // Class Properties
  Icon: string | undefined;
  Version: string | undefined;
  LastUpdate: Date | undefined;
  UpdateBy: string | undefined;
  AreItemsPopulated = false;

  FileSize: number | undefined;

  DownloadLink: string | undefined;
  DownloadError: string | undefined;
  ExtractComplete: boolean;

  IsExtracting: boolean;

  IsComposite: boolean = false;

  DirectoryId: string;

  FileType: FileType | undefined;

  SubItems: ZipEntryUI[];
  LastChange: Date | undefined;

  HubId: string | undefined;
  HubRegion: string | undefined;
  HubType: HubType;

  ProjectType: ProjectType;

  ForceCheckState: boolean | undefined;

  IsProcessed: boolean = false;

  StorageType: StorageType;

  constructor(id: string, name: string, projectId: string, directoryId: string, storageType: StorageType) {
    super();

    this.Id = id;
    this.Name = name;
    this.ProjectId = projectId;
    this.ExtractComplete = false;
    this.IsExtracting = false;
    this.DirectoryId = directoryId;

    this.StorageType = storageType;

    this.HubType = HubType.Unknown;
    this.ProjectType = ProjectType.Unknown;

    this.SubItems = [];
  }
}
