////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {OutgoingWebhook, OutgoingWebhookBody, OutgoingWebhookSendAttempt} from '../../clients/Classes';

export class WebhookSettingsState {
  WebHooks: OutgoingWebhook[];
  EditingHook: OutgoingWebhook | undefined;

  EditName: string | undefined;
  EditUrl: string | undefined;
  EditSecret: string | undefined;
  EditEnabled = true;

  ShowEdit = false;
  ShowDelete = false;
  ShowDeleteAll = false;
  ShowResponseDetail = false;
  ShowSampleBody = false;
  EditIsNew = false;

  ResponseDetail: OutgoingWebhookSendAttempt | undefined;
  SampleBody: OutgoingWebhookBody | undefined;

  Loading = false;
  LoadingMoreWebhooks = false;
  HasMoreWebhooks = false;
  public canCancelLoad = false;

  constructor() {
    this.WebHooks = [];
  }
}
