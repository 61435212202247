////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////
// noinspection SpellCheckingInspection

import { ZipEntryUI } from "../dataModel/ZipEntryUI";
import { FileUI } from "../dataModel/FileUI";
import { TrimString } from "../Utility";
import { BIM360ItemBase } from "../dataModel/BIM360ItemBase";
import Icon_File_any from '../assets/Icon_File_anyfile.png';
import Icon_File_docx from '../assets/Icon_File_docx.png';
import Icon_File_dwg from '../assets/Icon_File_dwg.png';
import Icon_File_image from '../assets/Icon_File_imagefiles.png';
import Icon_File_nwc from '../assets/Icon_File_nwc.png';
import Icon_File_pdf from '../assets/Icon_File_pdf.png';
import Icon_File_pptx from '../assets/Icon_File_pptx.png';
import Icon_File_rvt from '../assets/Icon_File_rvt.png';
import Icon_File_rvtZip from '../assets/Icon_File_rvt_Zipped.png';
import Icon_File_txt from '../assets/Icon_File_txt.png';
import Icon_File_xlsx from '../assets/Icon_File_xlsx.png';
import Icon_File_zip from '../assets/Icon_File_zip.png';
import Icon_File_zipContents from '../assets/Icon_File_ZipContents.png';
import Icon_File_processing from '../assets/Icon_File_processing.svg';

export class ConvertFileIcon {
  public static Convert(value: BIM360ItemBase): string {
    if (value instanceof ZipEntryUI) {
      return Icon_File_zipContents;
    }

    if (value instanceof FileUI) {
      if (!value.IsProcessed) {
        return Icon_File_processing;
      }
      const normalizedExtension = TrimString(value.FileType!.fileExtension!.toUpperCase().trim(), '.');
      switch (normalizedExtension) {
        case 'RVT':
          return value.IsComposite ? Icon_File_rvtZip : Icon_File_rvt;
        case 'DOC':
        case 'DOCX':
        case 'DOCM':
        case 'DOT':
        case 'DOTM':
        case 'DOTX':
          return Icon_File_docx;
        case 'DWG':
          return Icon_File_dwg;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
        case 'GIF':
        case 'TIF':
        case 'TIFF':
        case 'BMP':
        case 'EPS':
          return Icon_File_image;
        case 'NWC':
          return Icon_File_nwc;
        case 'PDF':
          return Icon_File_pdf;
        case 'PPTX':
        case 'POT':
        case 'POTM':
        case 'POTX':
        case 'PPS':
        case 'PPSM':
        case 'PPSX':
        case 'PPT':
        case 'PPTM':
          return Icon_File_pptx;
        case 'TXT':
        case 'RTF':
          return Icon_File_txt;
        case 'XLSX':
        case 'XLS':
        case 'XLSB':
        case 'XLSM':
        case 'XLT':
        case 'XLTM':
        case 'XLTX':
        case 'XLW':
          return Icon_File_xlsx;
        case 'ZIP':
          return Icon_File_zip;
      }
    }

    return Icon_File_any;
  }
}
