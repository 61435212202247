////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useState} from 'react';
import Theme from "@adsk/alloy-react-theme";
import {FlexRow, FlexRowCentered} from "../CommonStyledComponents";
import styled from "styled-components";

const SelectableItem = styled(FlexRowCentered)`
  padding: .25em 1em;
  cursor: pointer;

  &:hover {
    background: ${Theme.colors._greyAlpha};
  }
`;

const ObjectList = <T, >(
  {
    items,
    renderContent,
    getIdentifier,
    currentSelected,
    onSelected,
    renderToolbar,
    renderLastRow
  }: {
    items: T[],
    renderContent: (item: T) => ReactElement,
    getIdentifier: (item: T) => string,
    currentSelected?: T | undefined,
    onSelected?: (selected: T) => void,
    renderToolbar?: () => ReactElement,
    renderLastRow?: () => ReactElement | false | undefined
  }) => {
  const [selectedInternal, setSelectedInternal] = useState(currentSelected);

  function onItemClick(item: T): void {
    setSelectedInternal(item);
    if (onSelected) {
      onSelected(item);
    }
  }

  return (
    <FlexRow style={{border: '1px solid', borderColor: Theme.colors.adskBlue500, height: '100%', overflow: 'auto'}}>
      <div style={{overflow: 'auto', flex: 1}}>
        {
          items.map(i => (
            <SelectableItem onClick={() => onItemClick(i)}
                            style={selectedInternal != null && getIdentifier(i) === getIdentifier(selectedInternal) ? {background: Theme.colors._greyAlpha} : {}}>
              {renderContent(i)}
            </SelectableItem>
          ))
        }
        {renderLastRow && renderLastRow()}
      </div>
      {renderToolbar &&
        <div style={{
          background: Theme.colors.white,
          borderLeft: '1px solid',
          borderColor: Theme.colors.adskBlue500
        }}>{renderToolbar()}</div>
      }
    </FlexRow>
  );
};

export default ObjectList;