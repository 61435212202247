////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////


import {ProjectWiseSettingsState} from "../states/ProjectWiseSettingsState";
import {ProjectWiseSettingsActions} from "../../Enums";

export function reducer(state: ProjectWiseSettingsState, action: {
  type: ProjectWiseSettingsActions,
  payload: any
}): ProjectWiseSettingsState {
  switch (action.type) {
    case ProjectWiseSettingsActions.multipleActions:
      return {...state, ...action.payload};
    case ProjectWiseSettingsActions.loadingCredentials:
      return {...state, LoadingCredentials: action.payload};
    case ProjectWiseSettingsActions.loadingConfigurations:
      return {...state, LoadingConfigurations: action.payload};
    case ProjectWiseSettingsActions.hasMoreConfigurations:
      return {...state, HasMoreConfigurations: action.payload};
    case ProjectWiseSettingsActions.hasMoreCredentials:
      return {...state, HasMoreCredentials: action.payload};
    case ProjectWiseSettingsActions.hasMoreRepositories:
      return {...state, HasMoreRepositories: action.payload};
    case ProjectWiseSettingsActions.canCancelConfigurations:
      return {...state, CanCancelConfigurations: action.payload};
    case ProjectWiseSettingsActions.canCancelCredentials:
      return {...state, CanCancelCredentials: action.payload};
    case ProjectWiseSettingsActions.credentials:
      return {...state, Credentials: action.payload};
    case ProjectWiseSettingsActions.configurations:
      return {...state, Configurations: action.payload};
    case ProjectWiseSettingsActions.editingCredential:
      return {...state, EditingCredential: action.payload};
    case ProjectWiseSettingsActions.editingConfiguration:
      return {...state, EditingConfiguration: action.payload};
    case ProjectWiseSettingsActions.isEditingCredential:
      return {...state, IsEditingCredential: action.payload};
    case ProjectWiseSettingsActions.isEditingConfiguration:
      return {...state, IsEditingConfiguration: action.payload};
    case ProjectWiseSettingsActions.editIsNew:
      return {...state, EditIsNew: action.payload};
    case ProjectWiseSettingsActions.editPassword:
      return {...state, EditPassword: action.payload};
    case ProjectWiseSettingsActions.credentialSelectOptions:
      return {...state, CredentialSelectOptions: action.payload};
    case ProjectWiseSettingsActions.selectedCredential:
      return {...state, SelectedCredential: action.payload};
    case ProjectWiseSettingsActions.selectedTabRepository:
      return {...state, SelectedTabRepository: action.payload};
    case ProjectWiseSettingsActions.repositories:
      return {...state, Repositories: action.payload};
    case ProjectWiseSettingsActions.selectedRepository:
      return {...state, SelectedRepository: action.payload};
    case ProjectWiseSettingsActions.loadingRepositories:
      return {...state, LoadingRepositories: action.payload};
  }
}