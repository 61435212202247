// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  DetailedUsage,
  StartCsvDownloadResult,
  DownloadDto,
  UsageSortType,
  UsageSummary
} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {IClient} from "../clients/Client";

export class UsageDataService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;

  public StartCsvExport(
    filter_CustomerIds?: string[] | undefined,
    filter_UserIds?: string[] | undefined,
    filter_JobIds?: string[] | undefined,
    filter_StartTime?: Date | undefined,
    filter_EndTime?: Date | undefined,
    sort_SortType?: UsageSortType | undefined,
    sort_Ascending?: boolean | undefined
  ): Promise<StartCsvDownloadResult> {
    return this._client.startCsvDownload(
      filter_CustomerIds,
      filter_UserIds,
      filter_JobIds,
      filter_StartTime,
      filter_EndTime,
      sort_SortType,
      sort_Ascending
    );
  }

  public GetCsvDownload(id: string): Promise<DownloadDto> {
    return this.TryPromiseWithCatch(() =>
      this._client.getCsvDownload(id)
    );
  }

  public GetUsageSummary(
    customerIds?: string[],
    userIds?: string[],
    jobIds?: string[],
    startTime?: Date,
    endTime?: Date,
    paginationToken?: string | undefined,
    limit?: number | undefined
  ): Promise<UsageSummary> {

    return this.TryPromiseWithCatch(() =>
      this._client.getUsageSummary(false, limit, paginationToken, customerIds, userIds, jobIds, startTime, endTime)
    );
  }

  public GetUsageDetail(customerIds?: string[],
                        userIds?: string[],
                        jobIds?: string[],
                        startTime?: Date,
                        endTime?: Date,
                        sort?: UsageSortType,
                        sortAscending?: boolean,
                        paginationToken?: string,
                        paginationLimit?: number): Promise<DetailedUsage> {

    return this.TryPromiseWithCatch(() =>
      this._client.getDetailedUsage(
        paginationLimit,
        paginationToken,
        customerIds,
        userIds,
        jobIds,
        startTime,
        endTime,
        sort,
        sortAscending)
    );
  }
}
