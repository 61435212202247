////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ReportUI {
  public Id: string;
  public JobRunId: string;
  public Name: string;
  public Date: Date;
  public TotalFiles: number;
  public SuccessFiles: number;
  public FailedFiles: number;

  constructor(id: string, jobRunId: string, name: string, date: Date, totalFiles: number, successFiles: number, failedFiles: number) {
    this.Id = id;
    this.JobRunId = jobRunId;
    this.Name = name;
    this.Date = date;
    this.TotalFiles = totalFiles;
    this.SuccessFiles = successFiles;
    this.FailedFiles = failedFiles;
  }
}