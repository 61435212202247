////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  CustomerInfo,
  CustomerUsageDataBase,
  CustomerUser,
  DeckardJobDataEntry,
  DeckardJobOptions,
  DeckardJobRunDataEntry, DirectoryDto,
  EmailPreferences,
  ExtractLocationType,
  FileDestinationNamingType,
  FileType,
  Hub,
  HubType,
  Job,
  JobDirectory,
  JobFile,
  JobRun,
  JobRunItemStatusType,
  JobRunStatusType,
  JobScheduleType,
  JobStatusType,
  JobType,
  JobUsageDataBase,
  OutgoingWebhook,
  OutgoingWebhookEvent,
  OutgoingWebhookScopeType,
  OverallUsageData,
  Project,
  ProjectType,
  ProjectWiseConfiguration,
  ProjectWiseCredential,
  ProjectWiseCredentialWithSecret,
  ProjectWiseDirectory,
  ProjectWiseFile,
  Schedule,
  StorageType,
  UsageDataLevel,
  UsageSummary,
  UserProfileImages,
  UserUsageDataBase,
  ZipEntryDto
} from "../clients/Classes";
import {RecurrenceEndType, RecurrencePeriod, TaskTrigger} from "../Enums";
import {RecurrenceSettings} from "../dataModel/RecurrenceSettings";
import {CronTranslator} from "../dataModel/Translators/CronTranslator";
import {GenerateRandomString, GetDateWithOffset} from "../Utility";
import {ROLE_ID_ADMIN} from "../Constants";
import {ProjectWiseRepository, BulkJob, BulkJobItem} from "../clients/V2Classes";

export class SampleData {
  public readonly sampleJobs: Job[];
  public readonly sampleProjects: Project[];
  public readonly sampleHubs: Hub[];
  public readonly sampleFiles: JobFile[];
  public readonly sampleDirectories: JobDirectory[];
  public readonly sampleZips: Map<string, ZipEntryDto[]>;
  public readonly reportModel: JobFile;
  public readonly user: CustomerUser;
  public readonly customers: CustomerInfo[];
  public readonly sampleUsageSummary: UsageSummary;
  public readonly sampleDetailedUsage: DeckardJobDataEntry[];

  public readonly sampleOutgoingWebhooks: { hook: OutgoingWebhook, secret: string }[];
  public readonly sampleOutgoingWebhooksHistory: OutgoingWebhookEvent[];

  public readonly sampleBulkJobs: BulkJob[];

  public readonly projectWiseCredentials: ProjectWiseCredentialWithSecret[];
  public readonly projectWiseConfigurations: ProjectWiseConfiguration[];
  public readonly projectWiseRepositories: ProjectWiseRepository[];
  public readonly projectWiseServers: string[];
  public readonly projectWiseDirectories: ProjectWiseDirectory[];
  public readonly projectWiseFiles: ProjectWiseFile[];

  constructor() {
    this.sampleZips = new Map<string, ZipEntryDto[]>();

    this.customers = [];

    for (let i = 0; i < 50; i++) {
      this.customers.push(this.GetCustomer(`Sample Customer ${i}`));
    }
    this.user = this.GetUser(this.customers[0]);
    const projectData = this.GetProjects();
    this.sampleHubs = projectData.hubs;
    this.sampleProjects = projectData.projects;
    this.sampleDirectories = projectData.directories;
    this.sampleFiles = projectData.files;

    this.sampleOutgoingWebhooks = [];
    this.sampleOutgoingWebhooksHistory = [];
    this.sampleBulkJobs = [];
    this.customers.forEach(c => {
      const hooks = this.GetWebhooks(c, 6);
      hooks.forEach(h => this.sampleOutgoingWebhooks.push(h));

      const user = this.GetUser(c);
      const bulkJobs = this.CreateBulkJobs(user, 2);
      bulkJobs.forEach(j => this.sampleBulkJobs.push(j));
    });

    this.reportModel = projectData.files[0];

    this.projectWiseServers = this.CreateProjectWiseServers(3);
    this.projectWiseRepositories = this.CreateProjectWiseRepositories(this.projectWiseServers, 15);
    this.projectWiseCredentials = this.CreateProjectWiseCredentials(150);
    this.projectWiseConfigurations = this.CreateProjectWiseConfigurations(this.projectWiseRepositories, this.projectWiseCredentials, 5);
    const projectWiseFileData = this.GetProjectWiseFileStructure(this.projectWiseConfigurations);
    this.projectWiseFiles = projectWiseFileData.files;
    this.projectWiseDirectories = projectWiseFileData.directories;

    this.sampleJobs = this.GetJobs(15, 10);
    this.sampleUsageSummary = this.GetUsageSummary(5, 2);
    this.sampleDetailedUsage = this.CreateDetailedUsage(100);
  }

  public GetId(): string {
    return GenerateRandomString(12);
  }

  // Overall data populate
  private GetCustomer(name: string): CustomerInfo {
    return new CustomerInfo({
      id: this.GetId(),
      name: name,
      emailDomain: `@${name}.com`,
      allowAllUsers: false,
      validEmails: [
        `user@${name.toLowerCase()}.com`,
        `user2@${name.toLowerCase()}.com`,
      ]
    });
  }

  private GetWebhooks(customer: CustomerInfo, quantity: number): { hook: OutgoingWebhook, secret: string }[] {
    const hooks: { hook: OutgoingWebhook, secret: string }[] = [];

    for (let i = 0; i < quantity; i++) {
      const newHook = new OutgoingWebhook({
        id: this.GetId(),
        userId: this.user.id,
        url: `https://${customer.name}.com/hooks/${i}`,
        scope: OutgoingWebhookScopeType.User,
        customerId: customer.id,
        isEnabled: i < 4
      });

      newHook.lastEvent = this.GetWebhookEvent(newHook, 1, i > 1, i === 0);

      hooks.push({
        hook: newHook,
        secret: GenerateRandomString(16)
      });
    }

    return hooks;
  }

  private GetWebhookEvent(hook: OutgoingWebhook, eventNumber: number, success: boolean, stillTrying: boolean): OutgoingWebhookEvent {
    return new OutgoingWebhookEvent({
      id: this.GetId(),
      webhookId: hook.id,
      webhookEventNumber: eventNumber,
      url: hook.url,
      scope: hook.scope,
      scopeId: hook.scopeId,
      success: success,
      isComplete: !stillTrying,
      statusCode: 200,
      retryCount: 2,
      sendAttempts: [],
      sendAttemptsLoaded: false
    });
  }

  private GetUser(customer: CustomerInfo): CustomerUser {
    const images = new UserProfileImages();
    images.sizeX20 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX40 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX50 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX58 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX80 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX120 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX160 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX176 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX240 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX360 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';

    const user = new CustomerUser();
    user.init({
      firstName: 'First',
      lastName: 'Last',
      email: 'someone@somewhere.com',
      id: '12345',
      is2FAEnabled: false,
      isEmailVerified: true,
      isAdmin: true,
      username: 'sample username',
      profileImages: images,
      customer: customer,
      roles: [ROLE_ID_ADMIN]
    });
    return user;
  }

  private GetProjects(): { hubs: Hub[], projects: Project[], files: JobFile[], directories: JobDirectory[] } {
    const returnData: { hubs: Hub[], projects: Project[], files: JobFile[], directories: JobDirectory[] } = {
      hubs: [],
      projects: [],
      directories: [],
      files: []
    };

    for (let h = 0; h < 5; h++) {
      const hub = new Hub({
        id: this.GetId(),
        hubType: HubType.B360,
        name: `Hub ${h}`,
        region: 'region 1'
      });
      returnData.hubs.push(hub);

      const projectQuantity = h === 0 ? 20 : 3;
      for (let i = 0; i < projectQuantity; i++) {
        const project = new Project({
          hubId: hub.id,
          id: this.GetId(),
          rootDirectoryId: this.GetId(),
          name: `Test Project ${i}`,
          imageUrl: 'https://revolutiondesign.biz/wp-content/uploads/2017/10/Icon_Revolution.png',
          projectType: i === 0 ? ProjectType.A360 : ProjectType.ACC,
          hubName: hub.name,
          hubRegion: hub.region,
        });
        returnData.projects.push(project);

        const root = new JobDirectory();
        root.init({
          hubId: project.hubId,
          hubName: project.hubName,
          hubType: project.hubType,
          region: project.hubRegion,
          projectName: project.name,
          projectType: project.projectType,
          id: project.rootDirectoryId,
          name: 'Project Files',
          displayName: 'Project Files',
          isDirectory: true,
          lastUpdated: new Date(),
          lastUpdatedBy: 'Some Updater',
          projectId: project.id,
          isHidden: false
        });

        returnData.directories.push(root);
        const items = this.GenerateDirectoryNode(project, 0, 3, root, 3);
        items.forEach(item => {
          if (item instanceof JobFile) {
            returnData.files.push(item);
          } else {
            returnData.directories.push(item);
          }
        });
      }
    }

    return returnData;
  }

  private GetProjectWiseFileStructure(configurations: ProjectWiseConfiguration[]): {
    files: ProjectWiseFile[],
    directories: ProjectWiseDirectory[]
  } {
    const returnData: { files: ProjectWiseFile[], directories: ProjectWiseDirectory[] } = {
      directories: [],
      files: []
    };

    configurations.forEach(configuration => {
      const roots: ProjectWiseDirectory[] = [];
      for (let i = 0; i < 3; i++) {
        const root = new ProjectWiseDirectory();
        root.init({
          id: this.GetId(),
          name: `PW Root ${i}`,
          isDirectory: true,
          projectId: configuration.id,
          storageType: StorageType.ProjectWise
        });
        roots.push(root);
      }

      roots.forEach(root => {
        returnData.directories.push(root);
        const items = this.GenerateProjectWiseDirectoryNode(configuration, 0, 3, root, 3);
        items.forEach(item => {
          if (item instanceof ProjectWiseFile) {
            returnData.files.push(item);
          } else {
            returnData.directories.push(item);
          }
        });
      });
    });

    return returnData;
  }

  private GetJobs(extraForgeJobs: number, extraProjectWiseJobs: number): Job[] {
    const jobs: Job[] = [];
    const futureDate = GetDateWithOffset(5);
    const pastDate = GetDateWithOffset(-5);

    const scheduledTask = this.CreateSampleJob('Scheduled Sample',
      false, 'OnceLater',
      'Daily', 'None', futureDate, undefined, -1,
      new Date(), new Date(), JobStatusType.Scheduled, false, false);
    scheduledTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    scheduledTask.models!.push(this.GetSampleModel('Sample Model 2.rvt'));
    jobs.push(scheduledTask);

    const pausedTask = this.CreateSampleJob('Paused Sample',
      true, 'Recurring',
      'Daily', 'None', new Date(), futureDate, -1,
      new Date(), new Date(), JobStatusType.Paused, false, false);
    pausedTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(pausedTask);

    const errorTask = this.CreateSampleJob('Error Sample',
      true, 'Recurring',
      'Daily', 'None', new Date(), futureDate, -1,
      new Date(), new Date(), JobStatusType.Error, false, false);
    errorTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(errorTask);

    const errorCompleteTask = this.CreateSampleJob('Error Complete Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      new Date(), undefined, JobStatusType.Error, false, false);
    errorCompleteTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(errorCompleteTask);

    const partialCompleteTask = this.CreateSampleJob('Partially Completed Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      new Date(), undefined, JobStatusType.PartiallyCompleted, false, false);
    partialCompleteTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(partialCompleteTask);

    const completeTaskSingle = this.CreateSampleJob('Completed Single Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      new Date(), undefined, JobStatusType.Scheduled, false, false);
    completeTaskSingle.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(completeTaskSingle);

    const completeTaskEndDate = this.CreateSampleJob('Completed End Date Sample',
      false, 'Recurring',
      'Daily', 'Date', pastDate, GetDateWithOffset(-1), -1,
      new Date(), undefined, JobStatusType.Scheduled, false, false);
    completeTaskEndDate.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    jobs.push(completeTaskEndDate);

    for (let i = 0; i < extraProjectWiseJobs; i++) {
      const task = this.CreateSampleJob(`Extra PW Sample ${i}`,
        false, 'OnceLater',
        'Daily', 'None', futureDate, undefined, -1,
        new Date(), new Date(), JobStatusType.Scheduled, false, true);
      jobs.push(task);
    }

    for (let i = 0; i < extraForgeJobs; i++) {
      const task = this.CreateSampleJob(`Extra Forge Sample ${i}`,
        false, 'OnceLater',
        'Daily', 'None', futureDate, undefined, -1,
        new Date(), new Date(), JobStatusType.Scheduled, false, i < 20);
      jobs.push(task);
    }

    return jobs;
  }

  private GetUsageSummary(countPerLevel: number, runsPerJob: number): UsageSummary {
    const summary = new UsageSummary();
    const data = new OverallUsageData();
    data.init({
      jobRunCount: runsPerJob * countPerLevel * countPerLevel * countPerLevel,
      level: UsageDataLevel.All,
      customerUsageDatas: [],
      runTimeSeconds: 300
    });

    for (let i = 0; i < countPerLevel; i++) {
      const customer = this.CreateSampleCustomerUsage(countPerLevel, runsPerJob);
      data.customerUsageDatas!.push(customer);
    }
    summary.data = data;
    return summary;
  }

  // Individual object creation
  private CreateSampleJob(name: string,
                          isPaused: boolean,
                          trigger: TaskTrigger,
                          recurrence: RecurrencePeriod | undefined,
                          endType: RecurrenceEndType,
                          startDate: Date,
                          endDate: Date | undefined,
                          maxRuns: number,
                          lastRun: Date,
                          nextRun: Date | undefined,
                          status: JobStatusType,
                          isBulk = false,
                          isProjectWise: boolean): Job {
    let schedule: Schedule | undefined;
    let scheduleType: JobScheduleType = JobScheduleType.None;
    switch (trigger) {
      case 'OnceNow':
        schedule = undefined;
        scheduleType = JobScheduleType.OnceNow;
        break;
      case 'OnceLater':
        schedule = new Schedule({
          cronString: '',
          startDate: startDate,
          endDate: endDate,
          maxRuns: maxRuns,
          isPaused: isPaused
        });
        scheduleType = JobScheduleType.OnceLater;
        break;
      case 'Recurring':
        const settings = new RecurrenceSettings(startDate, maxRuns);
        settings.EndDate = endDate;
        settings.Recurrence = recurrence;
        settings.EndType = endType;

        schedule = new Schedule({
          cronString: CronTranslator.GetCronString(settings),
          startDate: startDate,
          endDate: endDate,
          maxRuns: maxRuns,
          isPaused: isPaused
        });
        scheduleType = JobScheduleType.Recurring;
        break;
    }

    const options = new DeckardJobOptions({
      locationType: ExtractLocationType.ModelDirectory,
      destinationNamingType: FileDestinationNamingType.AppendTimestamp,
      emailPreferences: new EmailPreferences({emailOnCompletion: true, attachExportFiles: true})
    });

    const projectIndex = status === JobStatusType.Error ? 0 : 1;

    const models = isProjectWise
      ? this.GetSampleProjectWiseFiles(
        this.GetId(),
        this.projectWiseConfigurations[0],
        2
      )
      : this.GetSampleModels(
        this.GetId(),
        this.sampleProjects[projectIndex].hubId!,
        this.sampleProjects[projectIndex].hubType!,
        this.sampleProjects[projectIndex].hubName!,
        this.sampleProjects[projectIndex].projectType!,
        this.sampleProjects[projectIndex].name!,
        'Some Parent',
        this.sampleProjects[projectIndex].hubRegion!,
        this.sampleProjects[projectIndex].id!,
        2
      );

    const job = new Job();
    job.init({
      id: this.GetId(),
      name: name,
      userId: this.GetId(),
      customerId: this.GetId(),
      bulkJobId: isBulk ? this.GetId() : undefined,
      models: models,
      directories: [],
      scheduleType: scheduleType,
      schedule: schedule,
      lastRun: new JobRun({
        id: this.GetId(),
        jobId: this.GetId(),
        startTime: lastRun,
        jobRunItems: []
      }),
      nextRun: new JobRun({
        id: this.GetId(),
        jobId: this.GetId(),
        startTime: nextRun,
        jobRunItems: []
      }),
      status: status,
      jobType: JobType.Standard,
      options: options,
      hubId: isProjectWise
        ? undefined
        : this.sampleProjects[projectIndex].hubId!,
      projectId: isProjectWise
        ? this.projectWiseConfigurations[0].id
        : this.sampleProjects[projectIndex].id!
    });
    return job;
  }

  private GenerateDirectoryNode(project: Project,
                                currentLevel: number,
                                maxLevel: number,
                                directory: JobDirectory,
                                subFolderCount: number): (JobFile | JobDirectory)[] {
    const items: (JobFile | JobDirectory)[] = [];
    const models = this.GetSampleModels(
      directory.id!,
      project.hubId!,
      project.hubType!,
      project.hubName!,
      project.projectType!,
      project.name!,
      directory.name!,
      project.hubRegion!,
      project.id!,
      project.name === 'Test Project 0' && currentLevel === 1 ? 300 : 10,
      ` - ${project.name} - Level ${currentLevel}`
    );

    models.forEach(model => {
      items.push(model);
    });

    if (maxLevel <= currentLevel) {
      return items;
    }

    currentLevel++;
    for (let i = 0; i < subFolderCount; i++) {
      const subFolder = new JobDirectory();
      subFolder.init({
        hubId: project.hubId,
        hubName: project.hubName,
        hubType: project.hubType,
        region: project.hubRegion,
        projectName: project.name,
        projectType: project.projectType,
        parentName: directory.name,
        isDirectory: true,
        id: this.GetId(),
        storageType: StorageType.Forge,
        name: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        displayName: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        parentId: directory.id,
        projectId: project.id,
        lastUpdated: new Date(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false
      });

      items.push(subFolder);

      const childItems = this.GenerateDirectoryNode(project, currentLevel, maxLevel, subFolder, subFolderCount);
      childItems.forEach(item => items.push(item));
    }

    return items;
  }

  private GenerateProjectWiseDirectoryNode(
    configuration: ProjectWiseConfiguration,
    currentLevel: number,
    maxLevel: number,
    directory: ProjectWiseDirectory,
    subFolderCount: number
  ): (ProjectWiseDirectory | ProjectWiseFile)[] {
    const items: (ProjectWiseDirectory | ProjectWiseFile)[] = [];
    const models = this.GetSampleProjectWiseFiles(directory.id!, configuration, 10, ` - ${configuration.repositoryName} - Level ${currentLevel}`);

    models.forEach(model => {
      items.push(model);
    });

    if (maxLevel <= currentLevel) {
      return items;
    }

    currentLevel++;
    for (let i = 0; i < subFolderCount; i++) {
      const subFolder = new ProjectWiseDirectory();
      subFolder.init({
        isDirectory: true,
        id: this.GetId(),
        storageType: StorageType.ProjectWise,
        name: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        displayName: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        parentId: directory.id,
        projectId: configuration.id,
        lastUpdated: new Date(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false
      });

      items.push(subFolder);

      const childItems = this.GenerateProjectWiseDirectoryNode(configuration, currentLevel, maxLevel, subFolder, subFolderCount);
      childItems.forEach(item => items.push(item));
    }

    return items;
  }

  private GetSampleModels(parentId: string, hubId: string, hubType: HubType, hubName: string, projectType: ProjectType, projectName: string, parentName: string, region: string, projectId: string, count: number = 10, nameTag: string = ''): JobFile[] {
    const models: JobFile[] = [];

    for (let i = 0; i < count; i++) {
      const model = new JobFile();
      model.init({
        hubId: hubId,
        hubType: hubType,
        hubName: hubName,
        region: region,
        projectType: projectType,
        projectName: projectName,
        parentName: parentName,
        directoryId: parentId,
        fileType: new FileType({fileDescription: 'Revit File', fileExtension: 'rvt'}),
        thumbnail: 'assets/images/Default_File.png',
        isDirectory: false,
        size: 52336,
        isLocked: false,
        pathInProject: 'some/path',
        fileNameWithExtension: `Sample Model ${i}${nameTag}.rvt`,
        id: this.GetId(),
        storageType: StorageType.Forge,
        name: `Sample Model ${i}${nameTag}`,
        displayName: `Sample Model ${i}${nameTag}`,
        parentId: parentId,
        projectId: projectId,
        lastUpdated: new Date(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false,
        versionNumber: i,
        objectId: this.GetId(),
        isComposite: i < 3,
        derivativesId: this.GetId(),
        isProcessed: i !== 0,
        compositeParentFile: undefined
      });
      models.push(model);

      if (model.isComposite) {
        const zips = this.CreateSampleZips(model);
        this.sampleZips.set(model.id!, zips);
      }
    }

    return models;
  }

  private GetSampleProjectWiseFiles(parentId: string, configuration: ProjectWiseConfiguration, count: number = 10, nameTag: string = ''): ProjectWiseFile[] {
    const models: ProjectWiseFile[] = [];

    for (let i = 0; i < count; i++) {
      const model = new ProjectWiseFile();
      model.init({
        directoryId: parentId,
        fileType: new FileType({fileDescription: 'Revit File', fileExtension: 'rvt'}),
        thumbnail: 'assets/images/Default_File.png',
        isDirectory: false,
        size: 52336,
        isLocked: false,
        pathInProject: 'some/path',
        fileNameWithExtension: `Sample Model ${i}${nameTag}.rvt`,
        id: this.GetId(),
        storageType: StorageType.ProjectWise,
        name: `Sample Model ${i}${nameTag}`,
        displayName: `Sample Model ${i}${nameTag}`,
        parentId: parentId,
        projectId: configuration.id,
        projectName: configuration.repositoryName,
        lastUpdated: new Date(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false
      });
      models.push(model);
    }

    return models;
  }

  private GetSampleModel(name: string): JobFile {
    const file = new JobFile();
    file.init({
      id: this.GetId(),
      name: name,
      thumbnail: 'some image path',
      lastUpdated: GetDateWithOffset(-1),
      lastUpdatedBy: 'sample user',
      versionNumber: 1,
      size: 153668,
    });
    return file;
  }

  private CreateSampleZips(model: JobFile, count: number = 3): ZipEntryDto[] {
    const zips: ZipEntryDto[] = [];
    for (let i = 0; i < count; i++) {
      const zip = new ZipEntryDto({
        filename: `Zip Contents ${i} - ${model.name}`,
        size: 15364
      });
      zips.push(zip);
    }

    return zips;
  }

  private CreateSampleCustomerUsage(countPerLevel: number, runsPerJob: number): CustomerUsageDataBase {
    const id = this.GetId();
    const item = new CustomerUsageDataBase({
      jobRunCount: runsPerJob * countPerLevel * countPerLevel,
      customerId: id,
      level: UsageDataLevel.Customer,
      runTimeSeconds: 80,
      userUsageDatas: [],
      customerName: `Customer Name ${id}`,
    });

    for (let i = 0; i < countPerLevel; i++) {
      const job = this.CreateSampleUserUsage(countPerLevel, runsPerJob);
      item.userUsageDatas!.push(job);
    }

    return item;
  }

  private CreateSampleUserUsage(countPerLevel: number, runsPerJob: number): UserUsageDataBase {
    const id = this.GetId();
    const item = new UserUsageDataBase({
      jobRunCount: runsPerJob * countPerLevel,
      userId: id,
      username: `User ${id}`,
      level: UsageDataLevel.User,
      runTimeSeconds: 20,
      jobUsageDatas: []
    });

    for (let i = 0; i < countPerLevel; i++) {
      const job = this.CreateSampleJobUsage(runsPerJob);
      item.jobUsageDatas!.push(job);
    }

    return item;
  }

  private CreateSampleJobUsage(runsPerJob: number): JobUsageDataBase {
    const id = this.GetId();
    return new JobUsageDataBase({
      jobRunCount: runsPerJob,
      jobId: id,
      jobName: `Sample Job ${id}`,
      runTimeSeconds: 5,
      level: UsageDataLevel.Job
    });
  }

  private CreateDetailedUsage(quantity: number): DeckardJobDataEntry[] {
    const usages: DeckardJobDataEntry[] = [];

    for (let i = 0; i < quantity; i++) {
      const usage = new DeckardJobDataEntry();
      usage.init({
        jobRunDataEntry: new DeckardJobRunDataEntry({
          runStatus: JobRunStatusType.Success,
          jobStatus: JobStatusType.Scheduled,
          jobRunId: `jr${i}`,
          jobId: `j${i}`,
          jobName: `Sample Job ${i}`,
          customerId: this.user.customer!.id,
          customerName: this.user.customer!.name,
          userId: this.user.id,
          username: this.user.username,
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          sourceProjectId: `p${i}`,
          sourceHubType: HubType.B360Team,
          sourceProjectType: ProjectType.ACC,
          runCreatedDate: new Date(),
          runUpdatedDate: new Date(),
          runModifiedDate: new Date(),
          scheduleType: JobScheduleType.OnPublish,
          jobRunActualStartTime: new Date(),
          jobRunActualEndTime: new Date(),
          jobRunScheduledStartTime: new Date()
        }),
        id: i.toString(),
        modelStatus: JobRunItemStatusType.Success,
        sourceModelId: `m${i}`,
        sourceFileNameWithExtension: `FileName${i}.ext`,
        sourceDirectoryId: `d${i}`
      });

      usages.push(usage);
    }

    return usages;
  }

  private CreateProjectWiseServers(quantity: number): string[] {
    const servers: string[] = [];
    for (let i = 0; i < quantity; i++) {
      servers.push(`https://server.com/projectwise/${i}`);
    }

    return servers;
  }

  private CreateProjectWiseRepositories(servers: string[], quantity: number): ProjectWiseRepository[] {
    const repositories: ProjectWiseRepository[] = [];

    servers.forEach(s => {
      const serverId = s.replace('https://server.com/projectwise/', '');
      for (let i = 0; i < quantity; i++) {
        repositories.push(new ProjectWiseRepository({
          serverAddress: s,
          id: this.GetId(),
          name: `server ${serverId} repo ${i}`
        }));
      }
    });

    return repositories;
  }

  private CreateProjectWiseCredentials(quantity: number): ProjectWiseCredentialWithSecret[] {
    const credentials: ProjectWiseCredentialWithSecret[] = [];

    for (let i = 0; i < quantity; i++) {
      credentials.push(new ProjectWiseCredentialWithSecret({
        key: `PWKey${i}`,
        id: this.GetId(),
        password: `PWPassword${i}`,
        username: `PWUser${i}`
      }));
    }

    return credentials;
  }

  private CreateProjectWiseConfigurations(repositories: ProjectWiseRepository[], credentials: ProjectWiseCredential[], quantity: number): ProjectWiseConfiguration[] {
    const configurations: ProjectWiseConfiguration[] = [];

    for (let i = 0; i < quantity; i++) {
      if (i >= repositories.length) {
        throw new Error('Not enough repositories');
      }

      const repository = repositories[i];
      const credential = credentials.length > i ? credentials[i] : credentials[0];

      configurations.push(new ProjectWiseConfiguration({
        id: this.GetId(),
        credentialId: credential.id,
        serverAddress: repository.serverAddress,
        repositoryId: repository.id,
        repositoryName: repository.name,
      }));
    }

    return configurations;
  }

  private CreateBulkJobs(user: CustomerUser, quantity: number): BulkJob[] {
    const jobs: BulkJob[] = [];

    for (let i = 0; i < quantity; i++) {
      const job = new BulkJob({
        id: this.GetId(),
        userId: user.id,
        customerId: user.customer?.id,
        name: `bulk job ${user.id}.${i}`,
        startTime: new Date(),
        items: [],
        destinationDirectory: new DirectoryDto(this.sampleDirectories[0])
      });
      job.items = this.CreateBulkJobItems(job, 3);
      jobs.push(job);
    }

    return jobs;
  }

  private CreateBulkJobItems(job: BulkJob, quantity: number): BulkJobItem[] {
    const items: BulkJobItem[] = [];

    const hub = this.sampleHubs[0];
    const projects = this.sampleProjects.filter(p => p.hubId === hub.id);

    for (let i = 0; i < quantity; i++) {
      const project = projects.length > i ? projects[i] : projects[projects.length - 1];
      items.push(new BulkJobItem({
          jobId: job.id,
          hubId: hub.id,
          projectId: project.id
        })
      );
    }

    return items;
  }
}
