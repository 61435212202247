////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class TreeItem<T> {
  id: string;
  children: TreeItem<T>[];
  relatedObject: T;

  constructor(id: string, relatedObject: T) {
    this.id = id;
    this.children = [];
    this.relatedObject = relatedObject;
  }
}