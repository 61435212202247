////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {Task} from "../dataModel/Task";
import Selector from "./Selector";
import {FileDestinationNamingType} from "../clients/Classes";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {CanArchive} from "../Utility";
import {FlexRowCentered} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";

const SettingsOptions = ({task}: {
  task: Task | undefined
}) => {
  const [naming, setNaming] = useState(task?.ExportDestinationNaming);
  const [email, setEmail] = useState(task?.EmailOnCompletion ?? false);
  const [attachCsv, setAttachCsv] = useState(task?.AttachCsv ?? false);
  const [maintainFileStructure, setMaintainFileStructure] = useState(task?.DoNotMaintainFolderStructure !== true);
  const [archive, setArchive] = useState(task?.Archive ?? false);

  const options: DefaultItem[] = [
    {value: FileDestinationNamingType.Overwrite, label: 'Overwrite Existing Files'},
    {value: FileDestinationNamingType.AppendTimestamp, label: 'Append Time Stamp'},
  ];

  function namingChanged(value: DefaultItem | null | undefined): void {
    if (task == null) {
      return;
    }
    task.ExportDestinationNaming = value == null
      ? FileDestinationNamingType.None
      : value.value as FileDestinationNamingType;
    setNaming(task.ExportDestinationNaming);
  }

  function emailChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.EmailOnCompletion = state === true;
    setEmail(state === true);
  }

  function csvChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.AttachCsv = state === true;
    setAttachCsv(state === true);
  }

  function maintainFileStructureChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.DoNotMaintainFolderStructure = state !== true;
    setMaintainFileStructure(state === true);
  }

  function archiveChanged(state: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.Archive = state === true;
    if (task.Archive) {
      task.DoNotMaintainFolderStructure = false;
      setMaintainFileStructure(true);
    }
    setArchive(state === true);
  }

  function disableArchive(): boolean {
    return task != null && !CanArchive(task);
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Options</h2>
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={maintainFileStructure}
                  disabled={archive}
                  onChange={maintainFileStructureChanged}/>
        <label style={{marginLeft: '0.5em'}}>Maintain folder structure at destination</label>
      </FlexRowCentered>
      <p style={Theme.typography.bodyMedium}>File naming</p>
      <Selector items={options}
                selected={options.find(o => o.value === naming)}
                onSelectionChange={namingChanged}/>
      <FlexRowCentered style={{marginTop: '2em'}}>
        <Checkbox checked={email}
                  onChange={emailChanged}/>
        <label style={{marginLeft: '0.5em'}}>Email me when each run of this task completes</label>
      </FlexRowCentered>
      <FlexRowCentered style={{marginTop: '0.5em', marginLeft: '2em'}}>
        <Checkbox disabled={!task?.EmailOnCompletion}
                  checked={attachCsv}
                  onChange={csvChanged}/>
        <label style={{marginLeft: '0.5em'}}>Attach the CSV transfer log</label>
      </FlexRowCentered>
      <div style={{marginTop: '2em'}}>
        {
          !disableArchive() &&
            <FlexRowCentered>
                <Checkbox checked={archive}
                          onChange={archiveChanged}/>
                <label style={{marginLeft: '0.5em'}}>Archive source files</label>
            </FlexRowCentered>
        }
      </div>
    </div>
  );
};

export default SettingsOptions;