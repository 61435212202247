////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {FileUI} from "../dataModel/FileUI";
import {ZipEntryUI} from "../dataModel/ZipEntryUI";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {ConvertFileIcon} from "../converters/ConvertFileIcon";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {
  BlueButton,
  FlexColumn,
  FlexFill,
  FlexRow,
  FlexRowCentered,
  FolderBlueRecursive,
  FolderBlueSingle
} from "../CommonStyledComponents";
import {ConvertFileSize} from "../converters/ConvertFileSize";
import {ConvertRunDate} from "../converters/ConvertRunDate";
import {
  ExtractLocationType,
  FileDestinationNamingType,
  JobRunItemStatusType
} from "../clients/Classes";
import {DownloadUrl} from "../Utility";
import {ExtractionService} from "../services/ExtractionService";
import styled from "styled-components";
import Theme from "@adsk/alloy-react-theme";
import {AlertErrorIcon, AlertWarningIcon, CheckmarkIcon, DownloadIcon, FolderIcon} from "@adsk/alloy-react-icon";
import Button, {BasicButton, IconButton} from "@adsk/alloy-react-button";
import Modal from '@adsk/alloy-react-modal';
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Checkbox from "@adsk/alloy-react-checkbox";
import Tooltip from "@adsk/alloy-react-tooltip";
import {RadioButton, RadioGroup} from "@adsk/alloy-react-radio-button";

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
`;

const RadioSpan = styled.span`
  padding-left: 1em;
`;

const RadioOptionWrapper = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const SectionHeader = styled.div`
  margin-bottom: 0.75em;
`;

const WarningDiv = styled.div`
  margin-bottom: 0.75em;
  background: ${Theme.colors.yellowOrange300};
  border-radius: 4px;
  padding: 0 1em;
`;

const extractService = new ExtractionService();

const TreeNodeFileContent = (
  {
    item,
    treeNodeProps,
    allowSelection,
    allowMultiSelection,
    allowDownload,
    onFolderInclusionChanged,
    isDirectoryIncluded,
    onError
  }: {
    item: BIM360ItemBase,
    treeNodeProps: any,
    allowSelection: boolean,
    allowMultiSelection: boolean,
    allowDownload: boolean,
    onFolderInclusionChanged?: (folder: DirectoryUI, isRemoved: boolean) => void,
    isDirectoryIncluded?: boolean,
    onError?: (error: any, operation: string) => void
  }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogCurrentFolder, setDialogCurrentFolder] = useState(true);
  const [dialogRecursive, setDialogRecursive] = useState(true);
  const [dialogDynamic, setDialogDynamic] = useState(true);

  function resetFile(file: FileUI | ZipEntryUI): void {
    file.IsExtracting = true;
    file.DownloadLink = undefined;
    file.DownloadError = undefined;
    file.ExtractComplete = false;
  }

  function downloadClick(e: React.MouseEvent, item: FileUI | ZipEntryUI): void {
    e.stopPropagation();

    let fileId: string | undefined;
    let zipName: string | undefined;
    if (item instanceof FileUI) {
      fileId = item.Id;
      if (item.SubItems != null && item.SubItems.length > 0) {
        item.SubItems.forEach(z => resetFile(z));
      }
    } else {
      fileId = item.ParentId;
      zipName = item.Name;
    }

    resetFile(item);

    extractService.StartExtraction(
      item.HubId!,
      item.ProjectId,
      fileId!,
      ExtractLocationType.DirectDownload,
      item.StorageType,
      FileDestinationNamingType.AppendTimestamp,
      zipName)
      .then(result => {
        let completions = 0;
        const targetCompletions = result.length;
        result.forEach(r => {
          const extractingFile = item;
          const startTime = new Date();
          const timerId = setInterval(() => {
            extractService.GetStatus(r.jobId!)
              .then(value => {
                if (value.status === JobRunItemStatusType.Success) {
                  completions++;
                  if (completions === targetCompletions) {
                    item.IsExtracting = false;
                    item.ExtractComplete = true;
                  }
                  clearInterval(timerId);
                  const endTime = new Date();
                  const elapsedSeconds = Math.abs(endTime.getTime() - startTime.getTime()) / 1000;
                  console.log(`Completed Extract of ${value.filename} in ${elapsedSeconds} seconds`);
                  if (extractingFile instanceof FileUI
                    && extractingFile.SubItems != null
                    && extractingFile.SubItems.length > 0) {
                    const entry = extractingFile.SubItems.find(s => s.Name === value.filename);
                    if (entry != null) {
                      entry.IsExtracting = false;
                      entry.ExtractComplete = true;
                      entry.DownloadLink = value.downloadUrl;
                    }
                  } else {
                    extractingFile.IsExtracting = false;
                    extractingFile.ExtractComplete = true;
                    extractingFile.DownloadLink = value.downloadUrl;
                  }

                  if (value.extractLocationType === ExtractLocationType.DirectDownload) {
                    DownloadUrl(value.downloadUrl!, value.filename!);
                  }
                } else if (value.status === JobRunItemStatusType.Error) {
                  completions++;
                  if (completions === targetCompletions) {
                    item.IsExtracting = false;
                  }
                  clearInterval(timerId);
                  const endTime = new Date();
                  const elapsedSeconds = Math.abs(endTime.getTime() - startTime.getTime()) / 1000;
                  console.log(`Completed Extract of ${r.filename} with errors in ${elapsedSeconds} seconds`);
                  console.error(value);
                  extractingFile.DownloadError = `There was an error extracting: ${value.messages!.join(', ')}`;
                  extractingFile.IsExtracting = false;
                }
              })
              .catch(error => {
                if (onError) {
                  onError(error, 'Get Status');
                }
              });
          }, 10000);
        });
      })
      .catch(error => {
        if (onError) {
          onError(error, 'Extract File');
        }
        item.IsExtracting = false;
      });
  }

  function HandleDialogResult(accepted: boolean, folder: DirectoryUI): void {
    setDialogOpen(false);
    if (!accepted || folder == null) {
      return;
    }

    const previousDynamic = folder.IsDynamic;

    folder.IncludeThisFolder = dialogCurrentFolder;
    folder.IsDynamic = dialogDynamic;
    folder.IsRecursive = dialogRecursive;

    if (isDirectoryIncluded && previousDynamic && !dialogDynamic && onFolderInclusionChanged) {
      onFolderInclusionChanged(folder, true);
    }

    if (onFolderInclusionChanged) {
      onFolderInclusionChanged(folder, false);
    }
  }

  function RemoveAll(folder: DirectoryUI): void {
    setDialogOpen(false);
    if (onFolderInclusionChanged) {
      onFolderInclusionChanged(folder, true);
    }
  }

  function menuAnchorClick(): void {
    if (item.ForceCheckState != null) {
      return;
    }
    if (!(item instanceof DirectoryUI)) {
      return;
    }
    setDialogDynamic(item.IsDynamic);
    setDialogRecursive(item.IsRecursive);
    setDialogCurrentFolder(item.IncludeThisFolder);

    setDialogOpen(true);
  }

  const folderIconStyle = item.ForceCheckState == null
    ? {marginRight: '0.5em', width: '24px', height: '24px'}
    : {marginRight: '0.5em', width: '24px', height: '24px', color: 'gray', cursor: 'not-allowed'}

  return (
    <FlexRowCentered>
      {
        allowSelection && allowMultiSelection && (item instanceof FileUI || item instanceof ZipEntryUI) &&
          <Checkbox
              disabled={item.ForceCheckState != null}
              checked={item.ForceCheckState !== false && (treeNodeProps.isSelected || item.ForceCheckState === true)}
              style={{marginRight: '0.5em'}}/>
      }
      {
        allowSelection && allowMultiSelection && item instanceof DirectoryUI &&
          <div style={{marginRight: '0.5em'}} onClick={menuAnchorClick}>
            {
              isDirectoryIncluded && item.IsRecursive && item.IsDynamic &&
                <Tooltip content={'Folder and all nested folders are included in the task'}>
                    <FolderBlueRecursive style={folderIconStyle}/>
                </Tooltip>
            }
            {
              isDirectoryIncluded && (!item.IsDynamic || !item.IsRecursive) &&
                <Tooltip content={'Folder is included in the task'}>
                    <FolderBlueSingle style={folderIconStyle}/>
                </Tooltip>
            }
            {
              !isDirectoryIncluded &&
                <Tooltip content={'Folder is not included in the task'}>
                    <FolderIcon style={folderIconStyle}/>
                </Tooltip>
            }
          </div>
      }
      {item instanceof DirectoryUI && !allowMultiSelection && <FolderIcon style={{marginRight: '0.5em'}}/>}
      {
        !(item instanceof DirectoryUI) &&
          <div style={{
            marginRight: '0.5em',
            width: '24px',
            height: '24px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
              <img
                  src={ConvertFileIcon.Convert(item as BIM360ItemBase)}
                  alt={'File'}
                  style={{width: '20px', height: '20px', objectFit: 'contain'}}/>
          </div>
      }
      {
        allowDownload && !(item instanceof DirectoryUI) &&
          <Tooltip content={
            item instanceof ZipEntryUI
              ? 'Extract and download this model only'
              : item instanceof FileUI && item.IsComposite
                ? 'Extract and download this entire model and all referenced models'
                : 'Download this file'
          }>
              <IconButton renderIcon={() => <DownloadIcon/>}
                          style={{marginRight: '0.5em'}}
                          onClick={e => {
                            if (!(item instanceof FileUI) && !(item instanceof ZipEntryUI)) {
                              return;
                            }
                            downloadClick(e, item)
                          }}
                          onMouseDown={e => e.stopPropagation()}/>
          </Tooltip>
      }
      <FlexRow style={{alignItems: 'baseline'}}>
        {
          <span>{treeNodeProps.label}</span>
        }
        {
          (item instanceof FileUI || item instanceof ZipEntryUI) &&
            <FlexRowCentered style={{marginLeft: '1em'}}>
                      <span style={Theme.typography.labelSmall}>
                        {ConvertFileSize.Convert(item.FileSize!)} - Updated {ConvertRunDate.Convert(item.LastChange)}
                      </span>
              {
                item.DownloadError != null && item.DownloadError !== '' &&
                  <Tooltip content={item.DownloadError}>
                      <AlertErrorIcon style={{height: '1em', width: '1em', marginLeft: '1em'}}/>
                  </Tooltip>
              }
              {
                item.ExtractComplete &&
                  <CheckmarkIcon style={{height: '1em', width: '1em', marginLeft: '1em'}}/>
              }
              {
                item.IsExtracting &&
                  <Tooltip content={'Current extracting, this may take several minutes for large files...'}>
                      <ProgressRing style={{height: '1em', width: '1em', marginLeft: '1em'}}/>
                  </Tooltip>
              }
              {
                item.DownloadLink != null && item.DownloadLink !== '' &&
                  <FlexRowCentered style={{marginLeft: '1em'}}>
                      <a
                          style={Theme.typography.labelSmall}
                          href={item.DownloadLink}
                          target='_blank'
                          rel={'noreferrer'}>Download</a>
                  </FlexRowCentered>
              }
            </FlexRowCentered>}
      </FlexRow>
      <Modal open={dialogOpen} style={{height: 'initial', maxHeight: '750px'}}>
        <Modal.Header>
          Folder and File Selection '{item.Name}'
        </Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <RadioOptionWrapper>
              <SectionHeader style={Theme.typography.bodyLarge}>Files</SectionHeader>
              <RadioGroup name={'dynamic'}
                          value={dialogDynamic ? 'dynamic' : 'static'}
                          onChange={(v) => setDialogDynamic(v === 'dynamic')}>
                <RadioLabel>
                  <RadioButton value={'static'}/>
                  <RadioSpan>Current files only</RadioSpan>
                </RadioLabel>
                <RadioLabel>
                  <RadioButton value={'dynamic'}/>
                  <RadioSpan>Current files and future files</RadioSpan>
                </RadioLabel>
              </RadioGroup>
            </RadioOptionWrapper>
            <RadioOptionWrapper>
              <SectionHeader style={Theme.typography.bodyLarge}>Folders</SectionHeader>
              <RadioGroup name={'recursive'}
                          value={dialogRecursive ? 'recursive' : 'single'}
                          onChange={(v) => setDialogRecursive(v === 'recursive')}>
                <RadioLabel>
                  <RadioButton value={'single'}/>
                  <RadioSpan>Files in this folder only</RadioSpan>
                </RadioLabel>
                <RadioLabel>
                  <RadioButton value={'recursive'}/>
                  <RadioSpan>Files in this folder and all nested folders</RadioSpan>
                </RadioLabel>
              </RadioGroup>
            </RadioOptionWrapper>
            <FlexFill/>
            <div style={{marginBottom: '0.75em'}}>
              <FlexRowCentered>
                <Checkbox checked={dialogCurrentFolder}
                          onChange={v => setDialogCurrentFolder(v === true)}/>
                <label style={{marginLeft: '0.5em'}}>Include the selected folder in the copy operation</label>
              </FlexRowCentered>
            </div>
            {
              !dialogDynamic &&
                <WarningDiv>
                    <FlexRow>
                        <AlertWarningIcon style={{marginRight: '1em', marginTop: '1em'}} size={48}/>
                        <FlexFill>
                            <p>
                                Projects with many folders, sub-folders, and files (200+) could cause your computer to
                                run out of
                                memory
                                which could stop the application from responding.
                            </p>
                            <p>
                                To avoid this issue select 'Current files and future files' instead of 'Current files
                                only'.
                            </p>
                        </FlexFill>
                    </FlexRow>
                </WarningDiv>
            }
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow>
            <BasicButton
              style={{padding: '0', color: Theme.colors.adskBlue500}}
              onClick={() => HandleDialogResult(false, item as DirectoryUI)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BasicButton>
            <FlexFill/>
            <Button style={{marginLeft: '1em'}} onClick={() => RemoveAll(item as DirectoryUI)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Remove All Selections</span>
              </FlexRowCentered>
            </Button>
            <BlueButton style={{marginLeft: '1em'}} onClick={() => HandleDialogResult(true, item as DirectoryUI)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Accept Selections</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </FlexRowCentered>
  );
};

export default TreeNodeFileContent;