////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {IV2Client} from "../clients/V2Client";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {CancellationToken} from "../dataModel/CancellationToken";
import {BulkTaskUI} from "../dataModel/BulkTaskUI";
import {FileStructureTranslator} from "../dataModel/Translators/FileStructureTranslator";
import {BulkTaskTranslator} from "../dataModel/Translators/BulkTaskTranslator";
import {
  BulkJobPaginatedResult,
  CreateBulkJobRequest,
  CreateBulkJobRequestItem,
  EditBulkJobRequestBase
} from "../clients/V2Classes";

export class BulkTaskService extends ServiceBase {
  private _v2Client: IV2Client = ClientProvider.V2Client;

  GetBulkTasks(paginationToken?: string | null | undefined, limit?: number | undefined): Promise<BulkJobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listBulkJobs(t, limit), paginationToken)
        .then(r => new BulkJobPaginatedResult(r))
    );
  }

  GetRemainingBulkTasks(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<BulkJobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(token =>
        this._v2Client.listBulkJobs(token, limit), paginationToken, cancelToken)
        .then(r => new BulkJobPaginatedResult(r))
    );
  }

  GetBulkTask(id: string): Promise<BulkTaskUI> {
    return this.TryPromiseWithCatch(() =>
      this._v2Client.getBulkJob(id).then(j => BulkTaskTranslator.TranslateBulkJob(j))
    );
  }

  CreateBulkTask(job: BulkTaskUI): Promise<BulkTaskUI> {
    const request = new CreateBulkJobRequest({
      name: job.Name,
      paused: job.Paused,
      startTime: job.StartTime,
      destinationDirectory: FileStructureTranslator.GetApiDirectoryDto(job.DestinationDirectory!),
      items: job.Projects.map(p => new CreateBulkJobRequestItem({projectId: p.Id, hubId: p.HubId}))
    });

    return this.TryPromiseWithCatch(() =>
      this._v2Client.createBulkJob(request).then(j => BulkTaskTranslator.TranslateBulkJob(j))
    );
  }

  DeleteBulkTask(id: string): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._v2Client.deleteBulkJob(id)
    );
  }

  EditBulkTask(task: BulkTaskUI, originalTask: BulkTaskUI): Promise<BulkTaskUI> {
    const update = new EditBulkJobRequestBase({
      name: task.Name === originalTask.Name ? undefined : task.Name,
      startTime: task.StartTime === originalTask.StartTime ? undefined : task.StartTime,
      pauseJob: task.Paused === originalTask.Paused ? undefined : task.Paused
    });

    return this.TryPromiseWithCatch(() =>
      this._v2Client.editBulkJob(task.Id, update).then(j => BulkTaskTranslator.TranslateBulkJob(j))
    );
  }
}