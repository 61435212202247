// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export class LoginResult {
  Success: boolean;
  Message: string | undefined;
  AccessToken: string | undefined;
  AccessTokenExpiry: Date | undefined;
  AutodeskToken: string | undefined;

  /**
   * Constructor
   * @param success If this succeeded
   * @param message The message to return on failure
   * @param accessToken The resulting access token
   * @param expiry The access token expiry
   * @param autodeskToken The Autodesk token
   */
  private constructor(success: boolean, message: string | undefined, accessToken: string | undefined, expiry: Date | undefined, autodeskToken: string | undefined) {
    this.Success = success;
    this.Message = message;
    this.AccessToken = accessToken;
    this.AccessTokenExpiry = expiry;
    this.AutodeskToken = autodeskToken;
  }

  public static GetSuccessResult(accessToken: string, expiry: Date, autodeskToken: string): LoginResult{
    return new LoginResult(true,undefined, accessToken, expiry, autodeskToken);
  }

  public static GetFailureResult(message: string): LoginResult{
    return new LoginResult(false,message, undefined, undefined, undefined);
  }
}
