// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { CustomerInfo } from '../../clients/Classes';
import { CustomerUI } from '../CustomerUI';

export class CustomerTranslator {
  static TranslateCustomer(customer: CustomerInfo): CustomerUI {
    const uiCustomer = new CustomerUI(customer.id!, customer.name!, customer.emailDomain!);

    uiCustomer.AllowAllUsers = customer.allowAllUsers!;
    uiCustomer.ValidEmails = customer.validEmails!;

    return uiCustomer;
  }
}
