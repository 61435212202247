// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { StorageType } from '../clients/Classes';
import {BIM360ItemBase} from './BIM360ItemBase';

export class ZipEntryUI implements BIM360ItemBase {
  Icon: string | undefined;
  Id: string;
  IsFolder = false;
  IsZipEntry = true;
  Name: string;
  ParentId: string | undefined;
  ProjectId: string;
  FileSize: number | undefined;
  IsExtracting: boolean;
  DownloadLink: string | undefined;
  DownloadError: string | undefined;
  ExtractComplete: boolean;
  LastChange: Date | undefined;
  HubId: string | undefined;

  ForceCheckState: boolean | undefined;

  StorageType: StorageType;

  constructor(id: string, name: string, projectId: string) {
    this.Id = id;
    this.Name = name;
    this.ProjectId = projectId;
    this.IsExtracting = false;
    this.ExtractComplete = false;
    this.StorageType = StorageType.Forge;
  }
}
