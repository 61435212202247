////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {BulkJob} from "../../clients/V2Classes";
import {BulkTaskUI} from "../BulkTaskUI";
import {FileStructureTranslator} from "./FileStructureTranslator";
import {ProjectUI} from "../ProjectUI";
import {HubType} from "../../clients/Classes";

export class BulkTaskTranslator {
  static TranslateBulkJob(job: BulkJob): BulkTaskUI {
    const uiJob = new BulkTaskUI(job.id);
    uiJob.Name = job.name;
    uiJob.StartTime = job.startTime;
    // TODO: paused
    uiJob.DestinationDirectory = FileStructureTranslator.GetDirectory(job.destinationDirectory!, false);
    job.items?.forEach(i => {
      const project = new ProjectUI(i.projectId!, '', '', i.hubId!, '', '', HubType.Unknown);
      if (project != null) {
        uiJob.Projects.push(project);
      }
    });

    return uiJob;
  }
}