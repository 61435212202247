////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer} from 'react';
import {CenteringContainer, ContentWrapper, FlexColumn} from '../CommonStyledComponents';
import {DirectDownloadState} from "../components/states/DirectDownloadState";
import {reducer} from "../components/reducers/DirectDownloadReducer";
import {ProjectService} from "../services/ProjectService";
import {DirectDownloadActions} from "../Enums";
import FileStructureList from "../components/FileStructureList";
import ProjectDropdown from "../components/ProjectDropdown";
import {ProjectUI} from "../dataModel/ProjectUI";
import Theme from "@adsk/alloy-react-theme";
import {GetErrorMessage, IsTabAvailableToUser} from "../Utility";
import {FileService} from "../services/FileService";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import {FILE_TAB_IDS, FILE_TAB_TITLES, FILE_TABS} from "../Constants";
import Illustration from "@adsk/alloy-react-illustration";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import ProjectDropdownProjectWise from "../components/ProjectDropdownProjectWise";
import {ProjectWiseService} from "../services/ProjectWiseService";
import {ProjectWiseConfigurationTranslator} from "../dataModel/Translators/ProjectWiseConfigurationTranslator";
import {ClientProvider} from "../clients/ClientProvider";

const projectService = new ProjectService();
const fileService = new FileService();
const projectWiseService = new ProjectWiseService();
const authService = ClientProvider.AuthService;

const DirectDownload = () => {
  const [state, dispatch] = useReducer(reducer, new DirectDownloadState());

  useEffect(() => {
    let isMounted = true;

    dispatch({
      type: DirectDownloadActions.multipleActions,
      payload: {LoadingProjects: true, LoadingProjectWiseConfigs: true}
    });

    projectService.GetProjects()
      .then(p => {
        if (!isMounted) {
          return;
        }

        const selected = p.length > 0 ? p[0] : undefined;
        dispatch({
          type: DirectDownloadActions.multipleActions,
          payload: {
            LoadingProjects: false,
            Projects: p,
            SelectedProject: selected,
          }
        });
      })
      .catch(error => onError(error, 'Get Projects'));

    projectWiseService.GetRemainingConfigurations()
      .then(result => {
        if (!isMounted) {
          return;
        }

        const items = result.items!.map(c => ProjectWiseConfigurationTranslator.TranslateConfiguration(c));
        const selected = items.length > 0 ? items[0] : undefined;
        dispatch({
          type: DirectDownloadActions.multipleActions,
          payload: {
            LoadingProjectWiseConfigs: false,
            ProjectWiseConfigs: items,
            SelectedProjectWiseConfig: selected
          }
        });
      })
      .catch(error => onError(error, 'Get ProjectWise Configurations'));

    return () => {
      isMounted = false;
    }
  }, []);

  function projectSelected(project: ProjectUI | undefined): void {
    dispatch({type: DirectDownloadActions.selectedProject, payload: project});
  }

  function getRootDirectories(root: ProjectUI | ProjectWiseConfigurationUI): Promise<DirectoryUI[]> {
    return root instanceof ProjectUI
      ? fileService.GetRootDirectories(root).then(() => root.RootFolderArray)
      : fileService.GetProjectWiseRootDirectories(root).then(() => root.RootFolderArray);
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  return (
    <ContentWrapper>
      <h1 style={Theme.typography.heading1}>Single Download</h1>
      <Tabs active={state.SelectedTab}
            style={{flex: 1, display: 'flex', flexDirection: "column", overflow: 'auto'}}
            onChange={tab => dispatch({type: DirectDownloadActions.selectedTab, payload: tab})}>
        {Object.keys(FILE_TABS).map(k => {
          return !IsTabAvailableToUser(k, authService.CurrentUser) ? undefined : (
            <Tab label={FILE_TAB_TITLES[k]}
                 tab={FILE_TAB_IDS[k]}
                 key={FILE_TAB_IDS[k]}
                 style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
              <FlexColumn style={{paddingTop: '1em'}}>
                {state.SelectedTab === FILE_TAB_IDS[FILE_TABS.FORGE] &&
                  <>
                    {
                      (state.LoadingProjects || state.Projects.length > 0) &&
                      <div style={{width: '400px'}}>
                        <ProjectDropdown projects={state.Projects}
                                         selectedProject={state.SelectedProject}
                                         loadingProjects={state.LoadingProjects}
                                         onSelected={projectSelected}/>
                      </div>
                    }
                    {
                      !state.LoadingProjects && state.Projects.length === 0 &&
                      <CenteringContainer style={{flexDirection: 'column'}}>
                        <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                        <p style={Theme.typography.bodyLarge}>You don't have any projects available</p>
                      </CenteringContainer>
                    }
                    {
                      state.LoadingProjects &&
                      <CenteringContainer style={{flex: 1}}>
                        <ProgressRing size={'large'}/>
                      </CenteringContainer>
                    }
                    <FileStructureList
                      rootObject={state.SelectedProject}
                      getRootDirectories={getRootDirectories}
                      getDirectoryContents={d => fileService.GetDirectoryContents(d)}
                      getZipContents={z => fileService.GetZipContents(z)}
                      allowSelection={false}
                      allowMultiSelection={false}
                      allowDownload={true}
                      onError={onError}/>
                  </>
                }
                {state.SelectedTab === FILE_TAB_IDS[FILE_TABS.PROJECTWISE] &&
                  <>
                    {
                      (state.LoadingProjectWiseConfigs || state.ProjectWiseConfigs.length > 0) &&
                      <div style={{width: '400px'}}>
                        <ProjectDropdownProjectWise
                          projects={state.ProjectWiseConfigs}
                          selectedProject={state.SelectedProjectWiseConfig}
                          loadingProjects={state.LoadingProjectWiseConfigs}
                          onSelected={c => dispatch({
                            type: DirectDownloadActions.selectedProjectWiseConfig,
                            payload: c
                          })}/>
                      </div>
                    }
                    {
                      !state.LoadingProjectWiseConfigs && state.ProjectWiseConfigs.length === 0 &&
                      <CenteringContainer style={{flexDirection: 'column'}}>
                        <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
                        <p style={Theme.typography.bodyLarge}>You don't have any projects available</p>
                      </CenteringContainer>
                    }
                    {
                      state.LoadingProjectWiseConfigs &&
                      <CenteringContainer style={{flex: 1}}>
                        <ProgressRing size={'large'}/>
                      </CenteringContainer>
                    }
                    <FileStructureList
                      rootObject={state.SelectedProjectWiseConfig}
                      getRootDirectories={getRootDirectories}
                      getDirectoryContents={d => fileService.GetDirectoryContents(d)}
                      getZipContents={z => fileService.GetZipContents(z)}
                      allowSelection={false}
                      allowMultiSelection={false}
                      allowDownload={true}
                      onError={onError}/>
                  </>
                }
              </FlexColumn>
            </Tab>
          )
        })}
      </Tabs>
    </ContentWrapper>
  );
};

export default DirectDownload;
