////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ReportUI} from "../dataModel/ReportUI";
import {
  ExportReportOptions,
  DeckardExportReportRequest,
  ExportResultDto,
  BatchDeleteResponse, ListReportHistoryResult
} from "../clients/V2Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IV2Client} from "../clients/V2Client";

export class ReportService extends ServiceBase {
  private _client: IV2Client = ClientProvider.V2Client;

  public GetReports(paginationToken?: string | null | undefined, limit?: number | null | undefined): Promise<ListReportHistoryResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._client.listReportHistory(undefined, t, limit), paginationToken)
        .then(r => new ListReportHistoryResult(r))
    );
  }

  public GetRemainingReports(
    paginationToken?: string | null | undefined,
    limit?: number | null | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<ListReportHistoryResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(token =>
        this._client.listReportHistory(undefined, token, limit), paginationToken, cancelToken)
        .then(reports => new ListReportHistoryResult(reports))
    );
  }

  public StartReportDownload(report: ReportUI, formats: string[] = ['csv']): Promise<ExportResultDto> {
    const options = new ExportReportOptions();
    options.exportFormats = formats;

    const body = new DeckardExportReportRequest({
      reportId: report.Id,
      jobRunId: report.JobRunId,
      options: options
    });

    return this.TryPromiseWithCatch(() =>
      this._client.startReportDownload(body)
    );
  }

  public GetReportExportStatus(reportId: string): Promise<ExportResultDto> {
    return this.TryPromiseWithCatch(() =>
      this._client.getExportDownloadStatus(reportId)
    );
  }

  public DeleteReports(ids: string[]): Promise<BatchDeleteResponse> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteReports(ids)
    );
  }
}