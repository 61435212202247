////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { TaskTutorialPages } from "../../TutorialConstants";
import {ReactElement} from "react";

export class InfoMenuState{
  public tutorialsOpen: boolean;
  public tutorialContent: {title: string, content: ReactElement, isFinal: boolean}[];

  constructor() {
    this.tutorialsOpen = false;
    this.tutorialContent = TaskTutorialPages;
  }
}