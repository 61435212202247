////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

import ProgressRing from '@adsk/alloy-react-progress-ring';
import {TreeNode} from "@adsk/alloy-react-tree";

const ProgressNode = forwardRef<HTMLLIElement, any>(
  ({ depth = 0, style, ...props }, ref) => {
    if (depth <= 0) return null;
    return (
      <TreeNode
        show={true}
        id=""
        ref={ref}
        label=""
        depth={depth}
        style={{ ...(style as object) }}
        {...props}
      >
        <ProgressRing size="small" />
      </TreeNode>
    );
  }
);
ProgressNode.displayName = 'ProgressNode';

ProgressNode.propTypes = {
  /* Determines whether to display the current node */
  show: PropTypes.bool.isRequired,
  /* style object to add to the wrapper of the node */
  depth: PropTypes.number,
};

export default ProgressNode;