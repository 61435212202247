////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useReducer} from 'react';
import {
  BlueButton,
  CenteringContainer, ColumnLeft, ColumnRight,
  FlexColumn,
  FlexColumnCentered,
  FlexFill, FlexRow,
  FlexRowCentered, FlexRowFullCentered
} from "../CommonStyledComponents";
import {
  AlertWarningFilledIcon, AlertWarningIcon,
  ArrowRotateTwoIcon, CheckmarkCircleIcon,
  DocumentTextIcon,
  PencilIcon,
  PlusIcon,
  SendArrowFilledIcon,
  TrashCanIcon, XCircleIcon
} from "@adsk/alloy-react-icon";
import Theme from "@adsk/alloy-react-theme";
import {WebhookSettingsActions} from "../Enums";
import Tooltip, {OverflowTooltip} from "@adsk/alloy-react-tooltip";
import {BasicButton, IconButton, LinkButton} from "@adsk/alloy-react-button";
import Table from "./Table";
import {EditOutgoingWebhookRequest, OutgoingWebhook} from "../clients/Classes";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {reducer} from "./reducers/WebhookSettingsReducer";
import {WebhookSettingsState} from "./states/WebhookSettingsState";
import {GenerateRandomString} from "../Utility";
import {Constants} from "../Constants";
import Modal from "@adsk/alloy-react-modal";
import TextInput from "@adsk/alloy-react-text-input";
import MaskedText from "./MaskedText";
import Checkbox from "@adsk/alloy-react-checkbox";
import ObjectPropertyDetail from "./ObjectPropertyDetail";
import {PageSizeService} from "../services/PageSizeService";
import {CancellationToken} from "../dataModel/CancellationToken";
import {WebhookService} from "../services/WebhookService";
import Illustration from "@adsk/alloy-react-illustration";
import PartialLoadWarning from "./PartialLoadWarning";

const service = new WebhookService();
let originalSecret: string | undefined;

const pageSize = PageSizeService.GetPageSize('webhooks');
let paginationToken: string | undefined = undefined;
const cancelToken = new CancellationToken();

const WebhookSettings = ({onError}: { onError?: (error: any, operation: string) => void }) => {
  const [state, dispatch] = useReducer(reducer, new WebhookSettingsState());

  useEffect(() => {
    loadHooks(true, false);
  }, []);

  function loadHooks(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({
      type: WebhookSettingsActions.multipleActions,
      payload: {
        Loading: isFirstLoad || loadAll,
        LoadingMoreWebhooks: !loadAll && !isFirstLoad,
        canCancelLoad: loadAll
      }
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    cancelToken.Cancel = false;

    const promise = loadAll
      ? service.GetRemainingWebhooks(paginationToken, pageSize, cancelToken)
      : service.GetWebhooks(paginationToken, pageSize);

    promise
      .then(paginationData => {
        paginationToken = paginationData.paginationData?.paginationToken;
        const hooks = paginationData.items;

        if (!isFirstLoad) {
          state.WebHooks.forEach(h => hooks?.push(h));
        }

        dispatch({
          type: WebhookSettingsActions.multipleActions,
          payload: {
            WebHooks: hooks,
            Loading: false,
            LoadingMoreWebhooks: false,
            HasMoreWebhooks: !paginationData.isDone
          }
        });
      })
      .catch(error => handleError(error, 'Get webhooks'));
  }

  function startEdit(hook?: OutgoingWebhook | undefined): void {
    const isNew = hook == null;

    dispatch({
      type: WebhookSettingsActions.multipleActions,
      payload: {
        ShowEdit: true,
        EditingHook: hook,
        EditIsNew: isNew,
        EditName: hook?.displayName,
        EditUrl: hook?.url,
        EditEnabled: hook?.isEnabled ?? true,
        EditSecret: isNew ? GenerateRandomString(Constants.SecretKeyLength) : undefined
      }
    });
  }

  function getSecret(hookId: string): Promise<void> {
    if (state.EditSecret != null && state.EditSecret.trim() !== '') {
      return Promise.resolve();
    }

    return service.GetSecret(hookId)
      .then(secret => {
        originalSecret = secret.secret;
        dispatch({
          type: WebhookSettingsActions.editSecret,
          payload: secret.secret
        });
      })
      .catch(error => handleError(error, 'Get secret'));
  }

  function startDelete(hook: OutgoingWebhook): void {
    dispatch({
      type: WebhookSettingsActions.multipleActions,
      payload: {ShowDelete: true, EditingHook: hook}
    });
  }

  function finishEdit(): void {
    dispatch({type: WebhookSettingsActions.showEdit, payload: false});
    const name = state.EditName == null || state.EditName.trim() === '' ? undefined : state.EditName;
    if (state.EditIsNew) {
      service.CreateWebhook(state.EditUrl!, state.EditSecret!, name)
        .then(hook => {
          dispatch({type: WebhookSettingsActions.webHooks, payload: [...state.WebHooks, hook]});
        })
        .catch(er => handleError(er, 'Create Webhook'));
    } else {
      const id = state.EditingHook?.id;
      if (id == null) {
        alert('Editing hook was null, could not proceed');
        return;
      }

      const newUrl = state.EditUrl === state.EditingHook?.url ? undefined : state.EditUrl;
      const newName = name === state.EditingHook?.displayName ? undefined : name;
      const newSecret = state.EditSecret == null || state.EditSecret.trim() === '' || state.EditSecret === originalSecret ? undefined : state.EditSecret;
      const newEnabled = state.EditEnabled === state.EditingHook?.isEnabled ? undefined : state.EditEnabled;

      if (newUrl == null && newName == null && newEnabled == null && newSecret == null) {
        return;
      }

      const request = new EditOutgoingWebhookRequest({
        url: newUrl,
        displayName: newName,
        isEnabled: newEnabled,
        secretToken: newSecret,
      });

      service.EditWebhook(id, request)
        .then(hook => {
          const index = state.WebHooks.findIndex(h => h.id === id);
          if (index < 0) {
            return;
          }
          state.WebHooks[index] = hook;
          dispatch({type: WebhookSettingsActions.webHooks, payload: [...state.WebHooks]});
        })
        .catch(er => handleError(er, 'Edit Webhook'));
    }
  }

  function finishDelete(): void {
    const id = state.EditingHook?.id;
    if (id == null) {
      alert('Editing hook was null, could not proceed');
      return;
    }

    dispatch({type: WebhookSettingsActions.showDelete, payload: false});

    service.DeleteWebhook(id)
      .then(() => {
        const index = state.WebHooks.findIndex(h => h.id === id);
        if (index < 0) {
          return;
        }

        state.WebHooks.splice(index, 1);
        dispatch({type: WebhookSettingsActions.webHooks, payload: [...state.WebHooks]});
      })
      .catch(er => handleError(er, 'Delete Webhook'));
  }

  function testHook(hook: OutgoingWebhook): void {
    service.TestWebhookExisting(hook.id!, new EditOutgoingWebhookRequest())
      .then(execution => {
        dispatch({
          type: WebhookSettingsActions.multipleActions,
          payload: {ShowResponseDetail: true, ResponseDetail: execution}
        });
      })
      .catch(er => handleError(er, 'Send test'));
  }

  function finishDeleteAll(): void {
    dispatch({type: WebhookSettingsActions.showDeleteAll, payload: false});
    service.DeleteAllWebhooks()
      .then(() => {
        dispatch({type: WebhookSettingsActions.webHooks, payload: []});
      })
      .catch(er => handleError(er, 'Delete All Webhooks'));
  }

  function getSampleBody(hook: OutgoingWebhook): void {
    service.GetSampleWebhookBody(hook.url!, hook.displayName!)
      .then(body => {
        dispatch({
          type: WebhookSettingsActions.multipleActions,
          payload: {ShowSampleBody: true, SampleBody: body}
        });
      })
      .catch(er => handleError(er, 'Get sample webhook body'));
  }

  function renderActionCell(hook: OutgoingWebhook): ReactElement {
    return <FlexRow>
      <Tooltip content={'Edit this webhook'}>
        <BasicButton onClick={() => startEdit(hook)}>
          <PencilIcon/>
        </BasicButton>
      </Tooltip>
      <Tooltip content={'Delete this webhook'}>
        <BasicButton onClick={() => startDelete(hook)}>
          <TrashCanIcon/>
        </BasicButton>
      </Tooltip>
      <Tooltip content={'Send a test notification to this hook'}>
        <BasicButton onClick={() => testHook(hook)}>
          <SendArrowFilledIcon/>
        </BasicButton>
      </Tooltip>
      <Tooltip content={'Get a sample of the body that will be sent to the webhook url on job completion.'}>
        <BasicButton onClick={() => getSampleBody(hook)}>
          <DocumentTextIcon/>
        </BasicButton>
      </Tooltip>
    </FlexRow>;
  }

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  function renderEnabledCell(hook: OutgoingWebhook): ReactElement {
    return <FlexRowFullCentered>
      {
        hook.isEnabled
          ? <CheckmarkCircleIcon style={{color: Theme.colors.green500}}/>
          : <XCircleIcon style={{color: Theme.colors.red500}}/>
      }
      {
        hook.lastEvent?.success === false && hook.lastEvent?.isComplete === true &&
        <Tooltip content={'This webhook errored on its last run and will not be re-run until manually enabled.'}>
          <AlertWarningFilledIcon
            style={{color: Theme.colors.red500, marginLeft: '0.5em', verticalAlign: 'middle'}}/>
        </Tooltip>
      }
      {
        hook.lastEvent?.success === false && hook.lastEvent?.isComplete !== true &&
        <Tooltip content={'This webhook errored on its last run but is still in retry status.'}>
          <AlertWarningIcon style={{color: Theme.colors.red500, marginLeft: '0.5em', verticalAlign: 'middle'}}/>
        </Tooltip>
      }
    </FlexRowFullCentered>;
  }

  return (
    <FlexColumn>
      <FlexRowCentered style={{marginBottom: '2em'}}>
        <BlueButton onClick={() => startEdit()}>
          <FlexRowCentered>
            <PlusIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>New Webhook</span>
          </FlexRowCentered>
        </BlueButton>
        <BlueButton onClick={() => dispatch({type: WebhookSettingsActions.showDeleteAll, payload: true})}
                    style={{marginLeft: '0.5em'}}>
          <FlexRowCentered>
            <TrashCanIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Delete All</span>
          </FlexRowCentered>
        </BlueButton>
        <FlexFill/>
        {
          state.HasMoreWebhooks && state.WebHooks.length > 0 &&
          <PartialLoadWarning pageSize={pageSize}
                              onLoadAll={() => loadHooks(false, true)}/>
        }
        <Tooltip content={'Refresh Webhooks'}>
          <IconButton
            onClick={() => loadHooks(true, false)}
            renderIcon={() => <ArrowRotateTwoIcon/>}
            style={{margin: '0 1em'}}/>
        </Tooltip>
      </FlexRowCentered>
      {
        !state.Loading &&
        <FlexColumn style={{flex: 1, overflow: 'auto'}}>
          <Table<OutgoingWebhook>
            style={state.WebHooks.length === 0 ? {height: 'inherit'} : {}}
            columns={[
              {
                id: 'enabled',
                accessorFn: h => h.isEnabled,
                size: 70,
                header: () => 'Enabled',
                cell: d => renderEnabledCell(d.row.original)
              },
              {
                id: 'displayName',
                accessorFn: h => h.displayName,
                header: () => 'Name',
                cell: d =>
                  d.row.original.displayName == null || d.row.original.displayName.trim() === ''
                    ? d.row.original.url
                    : d.row.original.displayName
              },
              {
                id: 'url',
                accessorFn: h => h.url,
                header: () => 'URL',
                cell: d => <OverflowTooltip>{d.row.original.url}</OverflowTooltip>
              },
              {
                id: 'actions',
                size: 70,
                header: () => 'Actions',
                cell: d => renderActionCell(d.row.original)
              },
            ]}
            data={state.WebHooks}
            renderLastRow={() => state.HasMoreWebhooks &&
              <LoadMoreDataRow
                isLoading={state.LoadingMoreWebhooks}
                onLoad={async () => loadHooks(false, false)}
                renderLoadMore={() =>
                  <FlexRowCentered>
                    <LinkButton onClick={() => loadHooks(false, false)}>
                      <span style={Theme.typography.bodySmall}>Load more</span>
                    </LinkButton>
                    <LinkButton onClick={() => loadHooks(false, true)} style={{marginLeft: '1em'}}>
                      <span style={Theme.typography.bodySmall}>Load all</span>
                    </LinkButton>
                  </FlexRowCentered>
                }/>
            }/>
        </FlexColumn>
      }
      {
        state.Loading &&
        (<CenteringContainer>
          <FlexColumnCentered>
            <ProgressRing size={'large'}/>
            {
              state.canCancelLoad &&
              <LinkButton onClick={() => cancelToken.Cancel = true}>Cancel</LinkButton>
            }
          </FlexColumnCentered>
        </CenteringContainer>)
      }

      {
        !state.Loading && state.WebHooks.length === 0 &&
        <CenteringContainer style={{flexDirection: 'column'}}>
          <Illustration type={'folderEmptyGrey'} height={200} width={200}/>
          <p style={Theme.typography.bodyLarge}>You don't have any Webhooks yet</p>
        </CenteringContainer>
      }

      <Modal open={state.ShowEdit}>
        <Modal.Header>{state.EditIsNew ? 'Create' : 'Edit'} Webhook</Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The display name of the webhook (optional)'}>
                  Name
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.EditName ?? ''}
                           onChange={e => dispatch({type: WebhookSettingsActions.editName, payload: e.target.value})}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The url to be called by the webhook'}>
                  Url
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.EditUrl ?? ''}
                           onChange={e => dispatch({type: WebhookSettingsActions.editUrl, payload: e.target.value})}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The secret value to be used to validate a webhook call'}>
                  Secret
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <FlexFill>
                  <MaskedText value={state.EditSecret ?? ''}
                              onBeforeCopy={() => getSecret(state.EditingHook!.id!)}
                              onCopied={() => alert('Secret copied to clipboard')}
                              onRevealed={() => getSecret(state.EditingHook!.id!)}
                              onRefreshRequested={() => dispatch({
                                type: WebhookSettingsActions.editSecret,
                                payload: GenerateRandomString(Constants.SecretKeyLength)
                              })}/>
                </FlexFill>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft></ColumnLeft>
              <ColumnRight>
                <Checkbox
                  checked={state.EditEnabled}
                  onChange={(e: any) => dispatch({type: WebhookSettingsActions.editEnabled, payload: e})}/>
                <label style={{marginLeft: '0.5em'}}>Enabled</label>
              </ColumnRight>
            </FlexRowCentered>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishEdit()} disabled={state.EditUrl == null || state.EditUrl.trim() === ''}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Save</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: WebhookSettingsActions.showEdit, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>

      <Modal open={state.ShowDelete}>
        <Modal.Header>Delete Webhook</Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <p style={Theme.typography.bodyMediumBold}>Are you sure you want to permanently delete the webhook
              {state.EditingHook?.displayName ?? state.EditingHook?.url}?</p>
            <p style={Theme.typography.bodyMedium}>Your webhook will no longer be called. This is not reversible and you
              will need to reconfigure your webhook if you need to be called on job completions.</p>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishDelete()}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: WebhookSettingsActions.showDelete, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>

      <Modal open={state.ShowDeleteAll}>
        <Modal.Header>Delete All Webhooks</Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <p style={Theme.typography.bodyMediumBold}>Are you sure you want to permanently
              delete <strong>ALL</strong> webhooks on your account?</p>
            <p style={Theme.typography.bodyMedium}>This will permanently remove all current webhooks from your account.
              This is not reversible and you will need to reconfigure your webhooks if you need to be called on job
              completions.</p>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishDeleteAll()}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: WebhookSettingsActions.showDeleteAll, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>

      <Modal open={state.ShowResponseDetail}>
        <Modal.Header>Webhook Response</Modal.Header>
        <Modal.Body>
          {
            state.ResponseDetail != null &&
            <ObjectPropertyDetail detailObject={state.ResponseDetail}/>
          }
          {
            state.ResponseDetail == null &&
            <CenteringContainer>Error - Response data was null!</CenteringContainer>
          }
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: WebhookSettingsActions.showResponseDetail, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Close</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>

      <Modal open={state.ShowSampleBody}>
        <Modal.Header>Webhook Body Sample</Modal.Header>
        <Modal.Body>
          {
            state.SampleBody != null &&
            <pre>{JSON.stringify(state.SampleBody, null, 2)}</pre>
          }
          {
            state.SampleBody == null &&
            <CenteringContainer>Error - Sample was null!</CenteringContainer>
          }
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: WebhookSettingsActions.showSampleBody, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Close</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </FlexColumn>
  );
};

export default WebhookSettings;