////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useState} from 'react';
import styled from "styled-components";
import {FlexRowCentered} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import {ModalPreviewNavigation} from "@adsk/alloy-react-modal";
import Checkbox from "@adsk/alloy-react-checkbox";

const HeaderWrapper = styled.div`
  padding: 1em;
  border-bottom: 1px solid black;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const FooterWrapper = styled.div`
  padding: 1em;
`;

const Tutorials = (props: {
  open: boolean,
  content: {
    title: string,
    content: ReactElement,
    isFinal: boolean
  }[],
  onClose: (dontShowChecked: boolean) => void
}) => {
  const [currentContent, setCurrentContent] = useState(props.content[0]);
  const [dontShow, setDontShow] = useState(false);

  useEffect(() => {
    setCurrentContent(props.content[0]);
  }, [props.content]);

  function moveNext(): void {
    const currentIndex = props.content.indexOf(currentContent);
    if (currentIndex === props.content.length - 1) {
      return;
    }
    setCurrentContent(props.content[currentIndex + 1])
  }

  function movePrevious(): void {
    const currentIndex = props.content.indexOf(currentContent);
    if (currentIndex === 0) {
      return;
    }
    setCurrentContent(props.content[currentIndex - 1])
  }

  return (
    <ModalPreviewNavigation
      open={props.open}
      navigationEnabled={true}
      next={moveNext}
      previous={movePrevious}
      onClose={() => {
        if (props.onClose) {
          props.onClose(dontShow);
        }
      }}
     bootstrapModal={{}}>
      <HeaderWrapper style={{borderBottom: `1px solid ${Theme.colors.charcoal400}`}}>
        <span style={Theme.typography.heading1}>{currentContent.title}</span>
      </HeaderWrapper>
      <ContentWrapper>
        {currentContent.content}
      </ContentWrapper>
      <FooterWrapper style={{borderTop: `1px solid ${Theme.colors.charcoal400}`}}>
        <FlexRowCentered>
          <Checkbox onChange={e => setDontShow(e as boolean)}/>
          <label style={{marginLeft: '0.5em'}}>Do not show this again</label>
        </FlexRowCentered>
      </FooterWrapper>
    </ModalPreviewNavigation>
  );
};

export default Tutorials;