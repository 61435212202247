////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ServiceBase} from "./ServiceBase";
import {ProjectUI} from "../dataModel/ProjectUI";
import {ProjectNameLoadBase} from "../dataModel/ProjectNameLoadBase";
import {StorageType} from "../clients/Classes";
import {GetErrorMessage} from "../Utility";
import {ProjectService} from "./ProjectService";
import {ProjectWiseService} from "./ProjectWiseService";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {ProjectWiseConfigurationTranslator} from "../dataModel/Translators/ProjectWiseConfigurationTranslator";
import {Task} from "../dataModel/Task";

export class NameRetrievalService extends ServiceBase {
  private cachedProjects: (ProjectUI | ProjectWiseConfigurationUI)[] = [];

  private readonly projectService: ProjectService;
  private readonly projectWiseService: ProjectWiseService;

  constructor(projectService: ProjectService, projectWiseService: ProjectWiseService) {
    super();

    this.projectService = projectService;
    this.projectWiseService = projectWiseService;
  }

  GetProjectAndHubNames(baseObjects: ProjectNameLoadBase[]): Promise<void> {
    const projectIds: { storage: StorageType, hubId: string | undefined, projectId: string }[] = [];
    let needsFullLoad = false;
    baseObjects.forEach(baseObject => {
      if (baseObject instanceof Task && baseObject.ProjectName != null && baseObject.HubName != null) {
        return;
      }

      baseObject.LoadingProjectName = true;
      if (baseObject.ProjectId == null) {
        needsFullLoad = true;
        return;
      }

      const existing = projectIds
        .find(p => p.projectId === baseObject.ProjectId);

      if (existing == null) {
        projectIds.push({
          storage: baseObject.HubId == null ? StorageType.ProjectWise : StorageType.Forge,
          hubId: baseObject.HubId,
          projectId: baseObject.ProjectId
        });
      }
    });

    if (needsFullLoad) {
      return this.TryPromiseWithCatch(() =>
        this.PopulateCacheFull()
          .then(() => {
            baseObjects.forEach(b => {
              const project = this.FindProjectInCache(b.ProjectId!);
              if (project == null) {
                b.LoadErrorDetail = `Could not get hub and project name, project was not found.`;
                b.HasLoadError = true;
                b.HubName = '-Unknown-';
                b.ProjectName = '-Unknown-';
                b.LoadingProjectName = false;
              } else {
                b.LoadingProjectName = false;
                b.HubName = project instanceof ProjectUI ? project.HubName : 'ProjectWise';
                b.ProjectName = project instanceof ProjectUI ? project.Name : project.ApiConfiguration.repositoryName;
              }
            });
          })
      );
    } else {
      const promises = projectIds.map(ids => {
        return this.TryPromiseWithCatch(() =>
          this.PopulateCacheIndividual(ids.storage, ids.hubId, ids.projectId)
            .then(() => {
              const project = this.FindProjectInCache(ids.projectId);
              const projectId = project instanceof ProjectUI ? project.Id : project?.ApiConfiguration.id;
              baseObjects.forEach(b => {
                if (b.ProjectId !== projectId) {
                  return;
                }
                b.LoadingProjectName = false;
                b.HubName = project instanceof ProjectUI ? project.HubName : 'ProjectWise';
                b.ProjectName = project instanceof ProjectUI ? project.Name : project?.ApiConfiguration.repositoryName;
              });
            })
            .catch(error => {
              console.error(error);
              baseObjects.forEach(b => {
                if (b.ProjectId !== ids.projectId) {
                  return;
                }
                b.LoadingProjectName = false;
                b.LoadErrorDetail = GetErrorMessage(error, 'Get Hub and Project Name');
                b.HasLoadError = true;
                b.HubName = '-Unknown-';
                b.ProjectName = '-Unknown-';
              });
            })
        );
      });

      return Promise.all(promises).then();
    }
  }

  private PopulateCacheFull(): Promise<void> {
    const promises: Promise<void>[] = [];
    promises.push(this.projectService.GetProjects()
      .then(projects => {
          projects.forEach(p => {
            const existing = this.FindProjectInCache(p.Id);
            if (existing != null) {
              return;
            }
            this.cachedProjects.push(p);
          });
        }
      ));
    promises.push(this.projectWiseService.GetRemainingConfigurations()
      .then(result => {
          result.items?.forEach(p => {
            const existing = this.FindProjectInCache(p.id!);
            if (existing != null) {
              return;
            }
            this.cachedProjects.push(ProjectWiseConfigurationTranslator.TranslateConfiguration(p));
          });
        }
      ));
    return this.TryPromiseWithCatch(() => Promise.all(promises).then());
  }

  private PopulateCacheIndividual(storage: StorageType, hubId: string | undefined, projectId: string): Promise<void> {
    const existing = this.FindProjectInCache(projectId);
    if (existing != null) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      storage === StorageType.Forge ?
        this.projectService.GetProject(hubId!, projectId)
          .then(p => {
            this.cachedProjects.push(p);
          })
        :
        this.projectWiseService.GetConfiguration(projectId)
          .then(config => {
            this.cachedProjects.push(ProjectWiseConfigurationTranslator.TranslateConfiguration(config));
          })
    );

  }

  private FindProjectInCache(id: string): ProjectUI | ProjectWiseConfigurationUI | undefined {
    return this.cachedProjects
      .find(p => (p instanceof ProjectUI ? p.Id : p.ApiConfiguration.id) === id);
  }
}