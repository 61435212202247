////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ProjectUI} from "../../dataModel/ProjectUI";
import {FILE_TAB_IDS, FILE_TABS} from "../../Constants";
import {ProjectWiseConfigurationUI} from "../../dataModel/ProjectWiseConfigurationUI";

export class DirectDownloadState {
  Projects: ProjectUI[];
  SelectedProject: ProjectUI | undefined;
  ProjectWiseConfigs: ProjectWiseConfigurationUI[];
  SelectedProjectWiseConfig: ProjectWiseConfigurationUI | undefined;
  LoadingProjects = false;
  LoadingProjectWiseConfigs = false;
  SelectedTab = FILE_TAB_IDS[FILE_TABS.FORGE];

  constructor() {
    this.Projects = [];
    this.ProjectWiseConfigs = [];
  }
}