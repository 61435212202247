////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {FlexRowCentered, FlexFill} from '../CommonStyledComponents';
import styled from "styled-components";
import Theme from '@adsk/alloy-react-theme';
import Profile from "./Profile";
import InfoMenu from "./InfoMenu";
import logo from '../assets/Logo_ART.svg';
import Divider from '@adsk/alloy-react-divider';

const FlexDivider = styled(Divider)`
  align-self: stretch;
  margin-left: 0.5em;
  margin-right: 0.5em;
  min-height: 100%;
  height: auto;
`;

const Wrapper = styled(FlexRowCentered)`
  padding: 0.5em;
  border-top: 18px solid black;
`;

const EnvironmentTag = styled.div`
  border: 2px solid ${Theme.colors.adskBlue500};
  border-radius: 6px;
  padding: 0.25em 1em;
  margin: 0 2em;
  background: wheat;
  color: ${Theme.colors.adskBlue500};
  font-weight: bold;
`;

const HeaderBar = () => {
  return (
    <Wrapper>
      <img src={logo} style={{height: '32px'}} alt={'Autodesk Replication Tool for Docs Logo'}/>
      <div style={{color: 'gray', marginLeft: '1em'}}>
        <span style={Theme.typography.bodyMedium}>Technology Preview</span>
      </div>
      {
        process.env.REACT_APP_HEADER_TAG != null && process.env.REACT_APP_HEADER_TAG !== '' && (
          <EnvironmentTag>{process.env.REACT_APP_HEADER_TAG}</EnvironmentTag>
        )
      }
      <FlexFill/>
      <InfoMenu/>
      <FlexDivider variant='vertical'/>
      <Profile/>
    </Wrapper>
  );
};

export default HeaderBar;