////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {DeckardReportSummaryDto} from "../../clients/V2Classes";
import {ReportUI} from "../ReportUI";

export class ReportTranslator {
  static TranslateReport(apiReport: DeckardReportSummaryDto): ReportUI {
    return new ReportUI(
      apiReport.reportId!,
      apiReport.jobRunId!,
      apiReport.jobName!,
      apiReport.startTime!,
      apiReport.totalFiles!,
      apiReport.successFiles!,
      apiReport.failedFiles!
    );
  }
}