// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import { DirectoryUI } from './DirectoryUI';
import { HubType, ProjectType } from "../clients/Classes";

export class ProjectUI {
  HubId: string;
  HubName: string;
  HubRegion: string;
  HubType: HubType;
  Id: string;
  RootDirectoryId: string;
  Name: string;
  ImagePath: string | undefined;
  IsBIM360 = false;
  ProjectType: ProjectType;

  RootFolderArray: DirectoryUI[];

  constructor(id: string, name: string, rootDirectoryId: string, hubId: string, hubName: string, hubRegion: string, hubType: HubType) {
    this.Id = id;
    this.Name = name;
    this.RootDirectoryId = rootDirectoryId;
    this.HubId = hubId;
    this.HubRegion = hubRegion;
    this.HubName = hubName;
    this.HubType = hubType;
    this.ProjectType = ProjectType.Unknown;
    this.RootFolderArray = [];
  }
}
