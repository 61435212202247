////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import Theme from "@adsk/alloy-react-theme";
import TextInput from "@adsk/alloy-react-text-input";

const SettingsName = ({value, onChange}: { value: string | undefined, onChange: (newValue: string) => void }) => {
  const [internalValue, setInternalValue] = useState(value ?? '');

  function sendUpdate(): void {
    if (onChange) {
      onChange(internalValue);
    }
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Name</h2>
      <TextInput value={internalValue}
                 placeholder={'Enter a task name...'}
                 onKeyDown={sendUpdate}
                 onChange={v => setInternalValue(v.target.value)}
                 onBlur={sendUpdate}/>
    </div>
  );
};

export default SettingsName;