////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  CreateOutgoingWebhookRequest,
  EditOutgoingWebhookRequest,
  OutgoingWebhook,
  OutgoingWebhookBody,
  OutgoingWebhookEventType,
  OutgoingWebhookPaginatedResult,
  OutgoingWebhookScopeType,
  OutgoingWebhookSecret,
  OutgoingWebhookSendAttempt
} from '../clients/Classes';
import {ClientProvider} from '../clients/ClientProvider';
import {ServiceBase} from "./ServiceBase";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IClient} from "../clients/Client";

export class WebhookService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;

  CreateWebhook(uri: string, secret: string, name?: string): Promise<OutgoingWebhook> {
    return this.TryPromiseWithCatch(() =>
      this._client.createWebhook(this.GetRequest(uri, secret, name))
    );
  }

  GetWebhooks(paginationToken?: string | null | undefined, limit?: number | null | undefined): Promise<OutgoingWebhookPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._client.listWebhooks(false, t, limit), paginationToken)
        .then(r => new OutgoingWebhookPaginatedResult(r))
    );
  }

  GetRemainingWebhooks(
    paginationToken?: string | null | undefined,
    limit?: number | null | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<OutgoingWebhookPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(token =>
        this._client.listWebhooks(false, token, limit), paginationToken, cancelToken)
        .then(r => new OutgoingWebhookPaginatedResult(r))
    );
  }

  EditWebhook(id: string, edit: EditOutgoingWebhookRequest): Promise<OutgoingWebhook> {
    return this.TryPromiseWithCatch(() =>
      this._client.editWebhook(id, edit)
    );
  }

  DeleteWebhook(id: string): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteWebhook(id)
    );
  }

  DeleteAllWebhooks(): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteUserWebhooks()
    );
  }

  GetSecret(hookId: string): Promise<OutgoingWebhookSecret> {
    return this.TryPromiseWithCatch(() =>
      this._client.getWebhookSecret(hookId)
    );
  }

  GetSampleWebhookBody(uri: string, secret: string, name?: string): Promise<OutgoingWebhookBody> {
    return this.TryPromiseWithCatch(() =>
      this._client.previewWebhookBody(this.GetRequest(uri, secret, name))
    );
  }

  TestWebhookExisting(id: string, edit?: EditOutgoingWebhookRequest): Promise<OutgoingWebhookSendAttempt> {
    return this.TryPromiseWithCatch(() =>
      this._client.testExistingWebhook(id, edit)
    );
  }

  private GetRequest(uri: string, secret: string, name?: string): CreateOutgoingWebhookRequest {
    return new CreateOutgoingWebhookRequest({
      url: uri,
      displayName: name,
      eventType: OutgoingWebhookEventType.JobComplete,
      isEnabled: true,
      scope: OutgoingWebhookScopeType.User,
      scopeId: ClientProvider.AuthService.CurrentUser?.id,
      secretToken: secret,
    });
  }
}
