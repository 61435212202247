// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

export class CustomerUI {
  ID: string;
  Name: string;
  EmailDomain: string;
  AllowAllUsers: boolean;
  ValidEmails: string[];

  constructor(id: string, name: string, emailDomain: string) {
    this.ID = id;
    this.Name = name;
    this.EmailDomain = emailDomain;
    this.AllowAllUsers = false;
    this.ValidEmails = [];
  }
}
