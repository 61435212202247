////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {useCallback, useEffect, useReducer} from 'react';
import {reducer} from "./reducers/ProjectWiseSettingsReducer";
import {ProjectWiseSettingsState} from "./states/ProjectWiseSettingsState";
import {
  BlueButton, CenteringContainer,
  ColumnLeft,
  ColumnRight,
  FlexColumn,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from "../CommonStyledComponents";
import ProjectWiseCredentialsList from "./ProjectWiseCredentialsList";
import ProjectWiseConfigurationsList from "./ProjectWiseConfigurationsList";
import {ArrowRotateTwoIcon, PlusIcon} from "@adsk/alloy-react-icon";
import Theme from "@adsk/alloy-react-theme";
import {IconButton, LinkButton} from "@adsk/alloy-react-button";
import Tooltip from "@adsk/alloy-react-tooltip";
import {ProjectWiseService} from "../services/ProjectWiseService";
import {PageSizeService} from "../services/PageSizeService";
import {CancellationToken} from "../dataModel/CancellationToken";
import {ProjectWiseActionTypes, ProjectWiseSettingsActions} from "../Enums";
import {ProjectWiseConfigurationTranslator} from "../dataModel/Translators/ProjectWiseConfigurationTranslator";
import {
  ProjectWiseConfiguration,
  ProjectWiseCredential,
  ProjectWiseCredentialWithSecret,
  UpdateProjectWiseConfigurationDto,
} from "../clients/Classes";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {SetConfigurationCredentials} from "../Utility";
import Modal from "@adsk/alloy-react-modal";
import TextInput from "@adsk/alloy-react-text-input";
import Selector from "./Selector";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import {REPOSITORY_TAB_IDS, REPOSITORY_TAB_TITLES, REPOSITORY_TABS} from "../Constants";
import Illustration from "@adsk/alloy-react-illustration";
import ObjectList from "./ObjectList";
import {ProjectWiseRepository} from "../clients/V2Classes";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import MaskedText from "./MaskedText";

const projectWiseService = new ProjectWiseService();

const pageSizeCredentials = PageSizeService.GetPageSize('projectWiseCredentials');
let paginationTokenCredentials: string | undefined = undefined;
const cancelTokenCredentials = new CancellationToken();

const pageSizeConfigurations = PageSizeService.GetPageSize('projectWiseConfigurations');
let paginationTokenConfigurations: string | undefined = undefined;
const cancelTokenConfigurations = new CancellationToken();

const pageSizeRepositories = PageSizeService.GetPageSize('projectWiseRepositories');
let paginationTokenRepositories: string | undefined = undefined;
const cancelTokenRepositories = new CancellationToken();

let originalPassword: string | undefined;

const ProjectWiseSettings = ({onError}: { onError?: (error: any, operation: string) => void }) => {
  const [state, dispatch] = useReducer(reducer, new ProjectWiseSettingsState());

  const refreshCredentials = useCallback((isFirstLoad: boolean, loadAll: boolean) => {
    dispatch({
      type: ProjectWiseSettingsActions.multipleActions,
      payload: {
        LoadingCredentials: true,
        CanCancelCredentials: loadAll
      }
    });

    if (isFirstLoad) {
      paginationTokenCredentials = undefined;
    }

    cancelTokenCredentials.Cancel = false;

    const promise = loadAll
      ? projectWiseService.GetRemainingCredentials(paginationTokenCredentials, pageSizeCredentials, cancelTokenCredentials)
      : projectWiseService.GetCredentials(paginationTokenCredentials, pageSizeCredentials);

    return promise
      .then(paginationData => {
        paginationTokenCredentials = paginationData.paginationData?.paginationToken;

        if (!isFirstLoad) {
          state.Credentials.forEach(c => paginationData.items!.push(c));
        }

        dispatch({
          type: ProjectWiseSettingsActions.multipleActions,
          payload: {
            LoadingCredentials: false,
            Credentials: paginationData.items,
            HasMoreCredentials: !paginationData.isDone
          }
        });

        return paginationData.items!;
      })
      .catch(error => {
        dispatch({type: ProjectWiseSettingsActions.loadingCredentials, payload: false});
        handleError(error, 'Get ProjectWise Credentials');
        return [];
      });
  }, []);

  const refreshConfigs = useCallback((isFirstLoad: boolean, loadAll: boolean) => {
    dispatch({
      type: ProjectWiseSettingsActions.multipleActions,
      payload: {
        LoadingConfigurations: true,
        CanCancelConfigurations: loadAll
      }
    });

    if (isFirstLoad) {
      paginationTokenConfigurations = undefined;
    }

    cancelTokenConfigurations.Cancel = false;

    const promise = loadAll
      ? projectWiseService.GetRemainingConfigurations(paginationTokenConfigurations, pageSizeConfigurations, cancelTokenConfigurations)
      : projectWiseService.GetConfigurations(paginationTokenConfigurations, pageSizeConfigurations);

    return promise
      .then(paginationData => {
        paginationTokenConfigurations = paginationData.paginationData?.paginationToken;

        const configs = paginationData.items!
          .map(c => ProjectWiseConfigurationTranslator.TranslateConfiguration(c));

        if (!isFirstLoad) {
          state.Configurations.forEach(c => configs.push(c));
        }

        SetConfigurationCredentials(configs, state.Credentials);

        dispatch({
          type: ProjectWiseSettingsActions.multipleActions,
          payload: {
            LoadingConfigurations: false,
            Configurations: configs,
            HasMoreConfigurations: !paginationData.isDone
          }
        });

        return configs;
      })
      .catch(error => {
        dispatch({type: ProjectWiseSettingsActions.loadingConfigurations, payload: false});
        handleError(error, 'Get ProjectWise Configurations');
        return [];
      });
  }, [state.Credentials]);

  useEffect(() => {
    const promises = [
      refreshCredentials(true, false),
      refreshConfigs(true, false)
    ];

    Promise.all(promises)
      .then(results => {
        const credentials: ProjectWiseCredential[] = [];
        const configs: ProjectWiseConfigurationUI[] = [];

        results.forEach(resultSet => {
          resultSet.forEach(result => {
            if (result instanceof ProjectWiseConfigurationUI) {
              configs.push(result);
            } else if (result instanceof ProjectWiseCredential) {
              credentials.push(result);
            }
          });
        })

        const hasUpdate = SetConfigurationCredentials(configs, credentials);

        if (hasUpdate) {
          dispatch({type: ProjectWiseSettingsActions.configurations, payload: configs});
        }
      });
  }, []);

  function startNewCredential(): void {
    dispatch({
      type: ProjectWiseSettingsActions.multipleActions,
      payload: {
        EditingCredential: new ProjectWiseCredential(),
        EditIsNew: true,
        IsEditingCredential: true,
        EditPassword: undefined
      }
    });
  }

  function startNewConfiguration(): void {
    const credentialOptions = state.Credentials.map(c => ({value: c.id, label: c.key}));
    const selected = credentialOptions.length > 0 ? credentialOptions[0] : undefined;

    dispatch({
      type: ProjectWiseSettingsActions.multipleActions,
      payload: {
        EditingConfiguration: new ProjectWiseConfigurationUI(new ProjectWiseConfiguration()),
        EditIsNew: true,
        IsEditingConfiguration: true,
        CredentialSelectOptions: credentialOptions,
        SelectedCredential: selected,
        Repositories: []
      }
    });
  }

  function configurationAction(action: ProjectWiseActionTypes, config: ProjectWiseConfigurationUI): void {
    const credentialOptions = state.Credentials.map(c => ({value: c.id, label: c.key}));
    const selected = credentialOptions.find(c => c.value === config.ApiConfiguration.credentialId);

    switch (action) {
      case "edit":
        dispatch({
          type: ProjectWiseSettingsActions.multipleActions,
          payload: {
            EditingConfiguration: config,
            EditIsNew: false,
            IsEditingConfiguration: true,
            CredentialSelectOptions: credentialOptions,
            SelectedCredential: selected,
            Repositories: []
          }
        });
        break;
      case "delete":
        projectWiseService.DeleteConfiguration(config)
          .then(() => {
            const index = state.Configurations.findIndex(c => c.ApiConfiguration.id === config.ApiConfiguration.id);
            if (index < 0) {
              return;
            }

            state.Configurations.splice(index, 1);
            dispatch({type: ProjectWiseSettingsActions.configurations, payload: [...state.Configurations]});
          })
          .catch(error => handleError(error, 'Delete Credential'));
        break;
      case "duplicate":
        const newConfig = new ProjectWiseConfigurationUI(new ProjectWiseConfiguration(config.ApiConfiguration));
        newConfig.ApiConfiguration.id = '';
        newConfig.CredentialKey = config.CredentialKey;

        dispatch({
          type: ProjectWiseSettingsActions.multipleActions,
          payload: {
            EditingConfiguration: newConfig,
            EditIsNew: true,
            IsEditingConfiguration: true,
            CredentialSelectOptions: credentialOptions,
            SelectedCredential: selected
          }
        });
        break;
    }
  }

  function credentialAction(action: ProjectWiseActionTypes, credential: ProjectWiseCredential | ProjectWiseCredentialWithSecret): void {
    switch (action) {
      case "edit":
        getPassword(credential)
          .then(pw => {
            if (pw.newCredential != null) {
              const index = state.Credentials.findIndex(c => c.id === pw.newCredential?.id);
              if (index < 0) {
                state.Credentials.push(pw.newCredential);
              } else {
                state.Credentials[index] = pw.newCredential;
              }
            }
            dispatch({
              type: ProjectWiseSettingsActions.multipleActions,
              payload: {
                Credentials: [...state.Credentials],
                EditingCredential: credential,
                EditIsNew: false,
                IsEditingCredential: true,
                EditPassword: pw.password
              }
            });
          })
          .catch(error => handleError(error, 'Get Password'));
        break;
      case "delete":
        projectWiseService.DeleteCredential(credential.id!)
          .then(() => {
            const index = state.Credentials.findIndex(c => c.id === credential.id);
            if (index < 0) {
              return;
            }

            state.Credentials.splice(index, 1);
            dispatch({type: ProjectWiseSettingsActions.credentials, payload: [...state.Credentials]});
          })
          .catch(error => handleError(error, 'Delete Credential'));
        break;
      case "duplicate":
        getPassword(credential)
          .then(pw => {
            if (pw.newCredential != null) {
              const index = state.Credentials.findIndex(c => c.id === pw.newCredential?.id);
              if (index < 0) {
                state.Credentials.push(pw.newCredential);
              } else {
                state.Credentials[index] = pw.newCredential;
              }
            }
            const newCredential = new ProjectWiseCredential(credential);
            newCredential.id = '';
            newCredential.key += ' - Copy';

            dispatch({
              type: ProjectWiseSettingsActions.multipleActions,
              payload: {
                Credentials: [...state.Credentials],
                EditingCredential: newCredential,
                EditIsNew: true,
                IsEditingCredential: true,
                EditPassword: pw
              }
            });
          })
          .catch(error => handleError(error, 'Get Password'));
        break;
    }
  }

  function finishEditCredential(): void {
    dispatch({type: ProjectWiseSettingsActions.isEditingCredential, payload: false});

    if (state.EditIsNew) {
      projectWiseService.CreateCredential(state.EditingCredential!.key!, state.EditingCredential!.username!, state.EditPassword!)
        .then(credential => {
          dispatch({
            type: ProjectWiseSettingsActions.multipleActions,
            payload: {Credentials: [...state.Credentials, credential], EditPassword: undefined}
          });
        })
        .catch(error => handleError(error, 'Create Credential'));
    } else {
      projectWiseService.UpdateCredential(state.EditingCredential!.id!, state.EditingCredential!.key!, state.EditingCredential!.username!, state.EditPassword === originalPassword ? undefined : state.EditPassword)
        .then(credential => {
          const index = state.Credentials.findIndex(c => c.id === credential.id);
          if (index < 0) {
            dispatch({
              type: ProjectWiseSettingsActions.multipleActions,
              payload: {Credentials: [...state.Credentials, credential], EditPassword: undefined}
            });
          } else {
            state.Credentials[index] = credential;
            dispatch({
              type: ProjectWiseSettingsActions.multipleActions,
              payload: {Credentials: [...state.Credentials], EditPassword: undefined}
            });
          }
        })
        .catch(error => handleError(error, 'Update Credential'));
    }
  }

  function finishEditConfiguration(): void {
    dispatch({type: ProjectWiseSettingsActions.isEditingConfiguration, payload: false});

    if (state.EditIsNew) {
      projectWiseService.CreateConfiguration(
        state.EditingConfiguration!.ApiConfiguration.repositoryId!,
        state.EditingConfiguration!.ApiConfiguration.serverAddress!,
        state.EditingConfiguration!.ApiConfiguration.repositoryName!,
        state.SelectedCredential!.value
      )
        .then(config => {
          SetConfigurationCredentials([config], state.Credentials);
          dispatch({type: ProjectWiseSettingsActions.configurations, payload: [...state.Configurations, config]});
        })
        .catch(error => handleError(error, 'Create Configuration'));
    } else {
      const dto = new UpdateProjectWiseConfigurationDto({
        repositoryId: state.EditingConfiguration!.ApiConfiguration.repositoryId,
        serverAddress: state.EditingConfiguration!.ApiConfiguration.serverAddress,
        repositoryName: state.EditingConfiguration!.ApiConfiguration.repositoryName,
        credentialId: state.SelectedCredential?.value === state.EditingConfiguration!.ApiConfiguration.id
          ? undefined
          : state.SelectedCredential?.value
      });
      projectWiseService.UpdateConfiguration(state.EditingConfiguration!.ApiConfiguration.id!, dto)
        .then(config => {
          const index = state.Configurations.findIndex(c => c.ApiConfiguration.id === config.ApiConfiguration.id);

          if (index < 0) {
            state.Configurations.push(config);
          } else {
            state.Configurations[index] = config;
          }

          SetConfigurationCredentials([config], state.Credentials);

          dispatch({type: ProjectWiseSettingsActions.configurations, payload: [...state.Configurations]});
        })
        .catch(error => handleError(error, 'Update Configuration'));
    }
  }

  function getPassword(credential: ProjectWiseCredential | ProjectWiseCredentialWithSecret): Promise<{
    password: string,
    newCredential?: ProjectWiseCredentialWithSecret | undefined
  }> {
    if (credential instanceof ProjectWiseCredentialWithSecret) {
      originalPassword = credential.password;
      return Promise.resolve({password: credential.password!});
    }

    return projectWiseService.GetCredentialWithSecret(credential.id!)
      .then(c => {
        originalPassword = c.password;
        return {password: c.password!, newCredential: c};
      })
      .catch(error => {
        handleError(error, 'Get password');
        return {password: ''};
      });
  }

  function loadRepositories(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({type: ProjectWiseSettingsActions.loadingRepositories, payload: true});

    if (isFirstLoad) {
      paginationTokenRepositories = undefined;
    }

    cancelTokenRepositories.Cancel = false;

    const serverAddress = state.EditingConfiguration!.ApiConfiguration.serverAddress!;
    const credentialId = state.SelectedCredential!.value!;

    const promise = loadAll
      ? projectWiseService.GetRemainingRepositories(serverAddress, credentialId, paginationTokenRepositories, pageSizeRepositories, cancelTokenRepositories)
      : projectWiseService.GetRepositories(serverAddress, credentialId, paginationTokenRepositories, pageSizeRepositories);

    promise
      .then(paginationData => {
        if (paginationData.items!.length === 0) {
          alert('No repositories were found using the data above');
        }

        paginationTokenRepositories = paginationData.paginationData?.paginationToken;

        const repositories: ProjectWiseRepository[] = [];
        const selectedRepository = isFirstLoad ? undefined : state.SelectedRepository;
        if (!isFirstLoad) {
          state.Repositories.forEach(r => repositories.push(r));
        }
        paginationData.items!.forEach(r => repositories.push(r));

        dispatch({
          type: ProjectWiseSettingsActions.multipleActions,
          payload: {
            LoadingRepositories: false,
            Repositories: repositories,
            SelectedRepository: selectedRepository,
            HasMoreRepositories: !paginationData.isDone
          }
        });
      })
      .catch(error => {
        handleError(error, 'Load Repositories');
        dispatch({type: ProjectWiseSettingsActions.loadingRepositories, payload: false});
      });
  }

  function repositorySelected(repository: ProjectWiseRepository): void {
    if (state.EditingConfiguration != null) {
      state.EditingConfiguration.ApiConfiguration.repositoryId = repository.id;
      state.EditingConfiguration.ApiConfiguration.repositoryName = repository.name;
    }

    dispatch({
      type: ProjectWiseSettingsActions.multipleActions,
      payload: {EditingCredential: state.EditingCredential, SelectedRepository: repository}
    });
  }

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  return (
    <FlexColumn>
      <FlexColumn>
        <h3>Credentials</h3>
        <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
          <BlueButton onClick={startNewCredential}>
            <FlexRowCentered>
              <PlusIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>New Credential</span>
            </FlexRowCentered>
          </BlueButton>
          <FlexFill/>
          <Tooltip content={'Refresh Credentials'}>
            <IconButton
              onClick={() => refreshCredentials(true, false)}
              renderIcon={() => <ArrowRotateTwoIcon/>}
              style={{marginRight: '1em'}}/>
          </Tooltip>
        </FlexRowCentered>
        <ProjectWiseCredentialsList
          credentials={state.Credentials}
          loading={state.LoadingCredentials}
          hasMoreData={state.HasMoreCredentials}
          canCancelLoad={state.CanCancelCredentials}
          onLoadMore={loadAll => refreshCredentials(false, loadAll)}
          onLoadCancel={() => cancelTokenCredentials.Cancel = true}
          onAction={credentialAction}/>
      </FlexColumn>
      <FlexColumn style={{marginTop: '1em'}}>
        <h3>Configurations</h3>
        <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
          <BlueButton onClick={startNewConfiguration}>
            <FlexRowCentered>
              <PlusIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>New Configuration</span>
            </FlexRowCentered>
          </BlueButton>
          <FlexFill/>
          <Tooltip content={'Refresh Configurations'}>
            <IconButton
              onClick={() => refreshConfigs(true, false)}
              renderIcon={() => <ArrowRotateTwoIcon/>}
              style={{marginRight: '1em'}}/>
          </Tooltip>
        </FlexRowCentered>
        <ProjectWiseConfigurationsList
          configurations={state.Configurations}
          loading={state.LoadingConfigurations}
          hasMoreData={state.HasMoreConfigurations}
          canCancelLoad={state.CanCancelConfigurations}
          onLoadMore={loadAll => refreshConfigs(false, loadAll)}
          onLoadCancel={() => cancelTokenConfigurations.Cancel = true}
          onAction={configurationAction}/>
      </FlexColumn>

      <Modal open={state.IsEditingCredential}>
        <Modal.Header>{state.EditIsNew ? 'Create' : 'Edit'} Credential</Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The display name of the credential'}>
                  Key
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.EditingCredential?.key ?? ''}
                           onChange={e => {
                             state.EditingCredential!.key = e.target.value;
                             dispatch({
                               type: ProjectWiseSettingsActions.editingCredential,
                               payload: state.EditingCredential
                             })
                           }}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The username to use to log in'}>
                  Username
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.EditingCredential?.username ?? ''}
                           onChange={e => {
                             state.EditingCredential!.username = e.target.value;
                             dispatch({
                               type: ProjectWiseSettingsActions.editingCredential,
                               payload: state.EditingCredential
                             })
                           }}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The password'}>
                  Password
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <FlexFill>
                  <MaskedText allowChange={true}
                             value={state.EditPassword ?? ''}
                             onChange={v => {
                               dispatch({
                                 type: ProjectWiseSettingsActions.editPassword,
                                 payload: v
                               })
                             }}/>
                </FlexFill>
              </ColumnRight>
            </FlexRowCentered>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishEditCredential()}
                        disabled={state.EditingCredential?.key == null
                          || state.EditingCredential?.key.trim() === ''
                          || state.EditingCredential?.username == null
                          || state.EditingCredential?.key.trim() === ''
                          || state.EditPassword == null
                          || state.EditPassword.trim() === ''}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Save</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({
                          type: ProjectWiseSettingsActions.isEditingCredential,
                          payload: false
                        })}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>

      <Modal open={state.IsEditingConfiguration}>
        <Modal.Header>{state.EditIsNew ? 'Create' : 'Edit'} Configuration</Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The ProjectWise server address'}>
                  Server Address
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.EditingConfiguration?.ApiConfiguration.serverAddress ?? ''}
                           onChange={e => {
                             state.EditingConfiguration!.ApiConfiguration.serverAddress = e.target.value;
                             dispatch({
                               type: ProjectWiseSettingsActions.editingConfiguration,
                               payload: state.EditingConfiguration
                             })
                           }}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The credential to use to log into the ProjectWise server'}>
                  Credential
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <Selector
                  items={state.CredentialSelectOptions}
                  selected={state.SelectedCredential}
                  style={{width: '100%'}}
                  onSelectionChange={c => {
                    dispatch({type: ProjectWiseSettingsActions.selectedCredential, payload: c});
                  }}/>
              </ColumnRight>
            </FlexRowCentered>
            <h3 style={{marginTop: 0}}>Repository</h3>
            <Tabs active={state.SelectedTabRepository}
                  style={{flex: 1, display: 'flex', flexDirection: "column", overflow: 'auto'}}
                  onChange={tab => dispatch({type: ProjectWiseSettingsActions.selectedTabRepository, payload: tab})}>
              <Tab label={REPOSITORY_TAB_TITLES[REPOSITORY_TABS.SEARCH]}
                   tab={REPOSITORY_TAB_IDS[REPOSITORY_TABS.SEARCH]}
                   key={REPOSITORY_TAB_IDS[REPOSITORY_TABS.SEARCH]}
                   style={{overflow: 'auto', flex: 1}}>
                {!state.LoadingRepositories && state.Repositories.length === 0 &&
                  <CenteringContainer style={{flexDirection: 'column'}}>
                    <Illustration type={'folderEmptyGrey'} height={75} width={75}/>
                    <div style={Theme.typography.bodyMedium}>No Repositories have been Loaded</div>
                    <LinkButton onClick={() => loadRepositories(true, false)}
                                disabled={
                                  state.EditingConfiguration?.ApiConfiguration.serverAddress == null
                                  || state.EditingConfiguration.ApiConfiguration.serverAddress.trim() === ''
                                  || state.SelectedCredential == null
                                }>Load Now</LinkButton>
                  </CenteringContainer>
                }
                {state.LoadingRepositories &&
                  <CenteringContainer style={{flex: 1, padding: '2em'}}>
                    <ProgressRing size={'medium'}/>
                  </CenteringContainer>
                }
                {!state.LoadingRepositories && state.Repositories.length > 0 &&
                  <ObjectList
                    items={state.Repositories}
                    renderToolbar={() => (
                      <Tooltip content={'Refresh Repositories'}>
                        <IconButton
                          onClick={() => loadRepositories(true, false)}
                          renderIcon={() => <ArrowRotateTwoIcon/>}
                          style={{margin: '0.5em'}}/>
                      </Tooltip>
                    )}
                    renderContent={r => <span>{r.name}</span>}
                    getIdentifier={r => r.id!}
                    currentSelected={state.SelectedRepository}
                    onSelected={repositorySelected}
                    renderLastRow={() => state.HasMoreRepositories &&
                      <LoadMoreDataRow
                        isLoading={state.LoadingRepositories}
                        onLoad={async () => {
                        }}
                        renderLoadMore={() =>
                          <FlexRowCentered>
                            <LinkButton onClick={() => loadRepositories(false, false)}>
                              <span style={Theme.typography.bodySmall}>Load more</span>
                            </LinkButton>
                            <LinkButton onClick={() => loadRepositories(false, true)}
                                        style={{marginLeft: '1em'}}>
                              <span style={Theme.typography.bodySmall}>Load all</span>
                            </LinkButton>
                          </FlexRowCentered>
                        }/>
                    }/>
                }
              </Tab>
              <Tab label={REPOSITORY_TAB_TITLES[REPOSITORY_TABS.MANUAL]}
                   tab={REPOSITORY_TAB_IDS[REPOSITORY_TABS.MANUAL]}
                   key={REPOSITORY_TAB_IDS[REPOSITORY_TABS.MANUAL]}>
                <FlexColumn style={{marginTop: '1em'}}>
                  <FlexRowCentered>
                    <ColumnLeft>
                      <Tooltip content={'The ID of the ProjectWise repository to use'}>
                        Repository ID
                      </Tooltip>
                    </ColumnLeft>
                    <ColumnRight>
                      <TextInput value={state.EditingConfiguration?.ApiConfiguration.repositoryId ?? ''}
                                 onChange={e => {
                                   state.EditingConfiguration!.ApiConfiguration.repositoryId = e.target.value;
                                   dispatch({
                                     type: ProjectWiseSettingsActions.editingConfiguration,
                                     payload: state.EditingConfiguration
                                   })
                                 }}/>
                    </ColumnRight>
                  </FlexRowCentered>
                  <FlexRowCentered>
                    <ColumnLeft>
                      <Tooltip content={'The Name of the ProjectWise repository to use'}>
                        Repository Name
                      </Tooltip>
                    </ColumnLeft>
                    <ColumnRight>
                      <FlexFill>
                        <TextInput value={state.EditingConfiguration?.ApiConfiguration.repositoryName ?? ''}
                                   onChange={e => {
                                     state.EditingConfiguration!.ApiConfiguration.repositoryName = e.target.value;
                                     dispatch({
                                       type: ProjectWiseSettingsActions.editingConfiguration,
                                       payload: state.EditingConfiguration
                                     })
                                   }}/>
                      </FlexFill>
                    </ColumnRight>
                  </FlexRowCentered>
                </FlexColumn>
              </Tab>
            </Tabs>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishEditConfiguration()}
                        disabled={state.EditingConfiguration?.ApiConfiguration.serverAddress == null
                          || state.EditingConfiguration?.ApiConfiguration.serverAddress.trim() === ''
                          || state.EditingConfiguration?.ApiConfiguration.repositoryId == null
                          || state.EditingConfiguration?.ApiConfiguration.repositoryId.trim() === ''
                          || state.EditingConfiguration?.ApiConfiguration.repositoryName == null
                          || state.EditingConfiguration?.ApiConfiguration.repositoryName.trim() === ''
                          || state.SelectedCredential == null}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Save</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({
                          type: ProjectWiseSettingsActions.isEditingConfiguration,
                          payload: false
                        })}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </FlexColumn>
  );
};

export default ProjectWiseSettings;