////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";
import {PAGES, PATHS} from "../Constants";
import {GetErrorMessage, ObjectToQueryString} from "../Utility";
import {ClientProvider} from "../clients/ClientProvider";
import {CenteringContainer} from '../CommonStyledComponents';
import ProgressRing from "@adsk/alloy-react-progress-ring";

const authService = ClientProvider.AuthService;

const Door = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    function attemptStoredLogin(): Promise<boolean> {
      return authService.AttemptStoredLogin()
        .then(result => {
          if (result.Success) {
            const returnPath: any = location?.state?.from;
            const navigation = returnPath == null || returnPath === '' || returnPath === '/'
              ? `${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`
              : returnPath as string;
            navigate(navigation);
          }
          return result.Success;
        });
    }

    function login(): void {
      const returnPath: any = location?.state?.from?.pathname;
      const stateParameter = {returnPath: returnPath};
      const queryParams: any = {
        response_type: 'code',
        client_id: process.env.REACT_APP_FORGE_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_CALLBACK_URL,
        scope: 'user-profile:read data:read data:write data:create data:search',
        state: encodeURIComponent(JSON.stringify(stateParameter))
      };

      if (process.env.REACT_APP_USE_MOCK_DATA === '1') {
        navigate(`${PATHS[PAGES.LANDING]}?code=fake`);
      } else {
        window.location.href = `https://${process.env.REACT_APP_AUTODESK_BASE_ADDRESS}/authentication/v2/authorize?${ObjectToQueryString(queryParams)}`;
      }
    }

    attemptStoredLogin()
      .then(r => {
        if (r) {
          return;
        }

        login();
      })
      .catch(error => alert(GetErrorMessage(error, 'Stored Login')));
  }, []);

  return (
    <CenteringContainer>
      <ProgressRing size={'xlarge'}/>
    </CenteringContainer>
  );
};

export default Door;