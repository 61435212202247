////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {ProjectUI} from "../dataModel/ProjectUI";
import {ConvertAutodeskProject} from "../converters/ConvertAutodeskProject";
import {ProjectButton, ProjectPickerExternal} from "@adsk/alloy-top-navigation";
import {EntitlementsProject} from "@adsk/alloy-provider";

const ProjectDropdown = (
  {
    projects,
    selectedProject,
    loadingProjects,
    onSelected
  }: {
    projects: ProjectUI[],
    selectedProject: ProjectUI | undefined,
    loadingProjects: boolean,
    onSelected: (project: ProjectUI | undefined) => void
  }) => {
  const convertedProjects = projects.map(ConvertAutodeskProject.GetAutodeskProject);
  const selectedConverted = convertedProjects.find(p => p.id === selectedProject?.Id);
  const [internalProjects, setInternalProjects] = useState(projects.map(ConvertAutodeskProject.GetAutodeskProject));
  const [selected, setSelected] = useState(selectedConverted);

  useEffect(() => {
    const convertedProjects = projects
      .map(ConvertAutodeskProject.GetAutodeskProject)
      .sort((a, b) => a.name == null || b.name == null ? 1 : a.name > b.name ? 1 : -1);
    const selectedConverted = convertedProjects.find(p => p.id === selectedProject?.Id);
    setInternalProjects(convertedProjects);
    setSelected(selectedConverted);
  }, [selectedProject, projects])

  function projectSelected(project?: object) {
    const castProject = project as EntitlementsProject;
    setSelected(castProject);
    const uiProject = projects.find(p => p.Id === castProject?.id);

    if (onSelected) {
      onSelected(uiProject);
    }
  }

  return (
    <ProjectButton
      currentProject={selected}
      renderProjectPicker={props => (
        <ProjectPickerExternal
          {...props}
          projects={internalProjects}
          currentProjectId={selected?.id}
          projectsLoading={loadingProjects}
          onProjectClick={projectSelected}
        />
      )}
    />
  );
};

export default ProjectDropdown;