////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {GetPropertyDisplayString} from '../Utility';
import {ConvertTextTruncation} from '../converters/ConvertTextTruncation';
import {ColumnLeft, ColumnRight, FlexRow} from '../CommonStyledComponents';
import Theme from "@adsk/alloy-react-theme";

const ObjectPropertyDetail = ({detailObject, prefix}: { detailObject: Object, prefix?: string | undefined  }) => {
  return (
    <>
      {
        Object.keys(detailObject).map(k => {
          // @ts-ignore
          if (detailObject != null && detailObject[k] instanceof Object) {
            const newPrefix = prefix == null ? `${k}.` : `${prefix}${k}.`;
            // @ts-ignore
            return <ObjectPropertyDetail detailObject={detailObject[k]} prefix={newPrefix}/>
          }
          const propertyValue = GetPropertyDisplayString(detailObject, k);
          const isLink = propertyValue != null && propertyValue.toLowerCase().startsWith('http');
          const truncatedString = propertyValue == null ? '' : ConvertTextTruncation.Convert(propertyValue);
          return (
            <FlexRow key={k}>
              <ColumnLeft style={Theme.typography.bodyMediumBold}><span
                style={{marginRight: '1em'}}>{prefix}{k}</span></ColumnLeft>
              <ColumnRight style={Theme.typography.bodyMedium}>
                {isLink && <a href={propertyValue}>{truncatedString}</a>}
                {!isLink && <span>{truncatedString}</span>}
              </ColumnRight>
            </FlexRow>
          );
        })
      }
    </>
  );
};

export default ObjectPropertyDetail;
