// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {IClient} from "../clients/Client";
import {ClientProvider} from "../clients/ClientProvider";
import {FileUI} from "../dataModel/FileUI";
import {FileStructureTranslator} from "../dataModel/Translators/FileStructureTranslator";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {FindProjectItemRecursive} from "../Utility";
import {GetDirectoryPathResult} from "../dataModel/GetDirectoryPathResult";
import {ProjectUI} from "../dataModel/ProjectUI";
import {ServiceBase} from "./ServiceBase";
import {
  Directory360,
  File360,
  ProjectWiseDirectory,
  ProjectWiseFile,
  StorageType
} from "../clients/Classes";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {IV2Client} from "../clients/V2Client";

export class FileService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2client: IV2Client = ClientProvider.V2Client;

  GetRootDirectories(project: ProjectUI): Promise<void> {
    if (project.RootFolderArray != null && project.RootFolderArray.length > 0) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      this._v2client.listTopLevelDirectoriesAll(project.HubId, project.Id, false)
        .then(directories => {
            project.RootFolderArray = [];
            directories.forEach(directory => {
              const dir = FileStructureTranslator.GetDirectory(directory, true);
              dir.HubId = project.HubId;
              dir.HubRegion = project.HubRegion;
              dir.HubType = project.HubType;
              dir.ProjectType = project.ProjectType;
              project.RootFolderArray.push(dir);
            });
          }
        )
    );
  }

  GetProjectWiseRootDirectories(configuration: ProjectWiseConfigurationUI): Promise<void> {
    if (configuration.RootFolderArray != null && configuration.RootFolderArray.length > 0) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpointFileResult(t =>
        this._v2client.listTopLevelDirectories(configuration.ApiConfiguration.id!, t, 100))
        .then(result => {
          configuration.RootFolderArray = [];
          result.directories!.forEach(directory => {
            const dir = FileStructureTranslator.GetDirectory(directory, true);
            configuration.RootFolderArray!.push(dir);
          });
        }));
  }

  async PopulateToDirectories(project: ProjectUI | ProjectWiseConfigurationUI, directoryIds: string[]): Promise<GetDirectoryPathResult[]> {
    const results: GetDirectoryPathResult[] = [];
    for (const id of directoryIds) {
      results.push(await this.PopulateToDirectory(project, id));
    }
    return results;
  }

  async PopulateToDirectory(project: ProjectUI | ProjectWiseConfigurationUI, directoryId: string): Promise<GetDirectoryPathResult> {
    let currentDirectoryId: string = directoryId;
    let lastDirectory: DirectoryUI | null = null;
    const loaded: DirectoryUI[] = [];

    const isForge = project instanceof ProjectUI;

    if (project.RootFolderArray.length === 0) {
      if (isForge) {
        await this.GetRootDirectories(project);
      } else {
        await this.GetProjectWiseRootDirectories(project);
      }
    }

    loaded.forEach(f => loaded.push(f));

    while (true) {
      const existingLoaded = FindProjectItemRecursive(project.RootFolderArray, currentDirectoryId);
      let currentDirectory: DirectoryUI;
      if (existingLoaded != null && existingLoaded instanceof DirectoryUI) {
        currentDirectory = existingLoaded;
      } else {
        if (isForge) {
          const currentDirectoryApi = await this._v2client.getDirectory(project.Id, currentDirectoryId);
          const isProjectFiles = currentDirectoryApi.parentId === project.RootDirectoryId;
          currentDirectory = FileStructureTranslator.GetDirectory(currentDirectoryApi, isProjectFiles);
        } else {
          const currentDirectoryApi = await this._v2client.getDirectory2(project.ApiConfiguration.id!, currentDirectoryId);
          currentDirectory = FileStructureTranslator.GetDirectory(currentDirectoryApi, false);
        }
      }

      if (isForge) {
        currentDirectory.ProjectType = project.ProjectType;
        currentDirectory.HubId = project.HubId;
        currentDirectory.HubRegion = project.HubRegion;
      }

      loaded.push(currentDirectory);
      if (lastDirectory != null) {
        lastDirectory.Parent = currentDirectory;
        currentDirectory.SubFolders.push(lastDirectory);
      }

      const existingRoot = project.RootFolderArray.find(f => f.Id === currentDirectory.Id);
      if (existingRoot != null) {
        return new GetDirectoryPathResult(true, loaded);
      }

      const rootParent = project.RootFolderArray.find(f => f.Id === currentDirectory.ParentId);
      if (rootParent != null || (isForge && currentDirectory.ParentId === project.RootDirectoryId)) {
        rootParent?.SubFolders.push(currentDirectory);
        currentDirectory.Parent = rootParent;
        return new GetDirectoryPathResult(true, loaded);
      }
      currentDirectoryId = currentDirectory.ParentId!;
      lastDirectory = currentDirectory;
    }
  }

  async GetDirectoryContents(directory: DirectoryUI): Promise<void> {
    if (directory.AreItemsPopulated) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      this.GetAllFileData(directory)
        .then(dto => {
          dto.directories.sort((x, y) => x.name! > y.name! ? 1 : -1);
          dto.directories.forEach(d => {
            const dir = FileStructureTranslator.GetDirectory(d, false);
            dir.HubId = directory.HubId;
            dir.HubRegion = directory.HubRegion;
            dir.ProjectType = directory.ProjectType;
            dir.HubType = directory.HubType;
            const projectMatchId = d.projectId!.replace('b.', '');
            if (!dir.Name.includes(projectMatchId)) {
              dir.Parent = directory;
              directory.SubFolders.push(dir);
              directory.SubItems.push(dir);
            }
          });

          dto.files.sort((x, y) => x.name! > y.name! ? 1 : -1);
          dto.files.forEach(f => {
            const file = FileStructureTranslator.GetFile(f);
            file.HubId = directory.HubId;
            file.HubRegion = directory.HubRegion;
            file.ProjectType = directory.ProjectType;
            file.HubType = directory.HubType;
            directory.Files.push(file);
            directory.SubItems.push(file);
          });

          directory.AreItemsPopulated = true;
        })
    );
  }

  GetZipContents(file: FileUI): Promise<void> {
    if (file.AreItemsPopulated) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      this._client.listZipContents(file.ProjectId, file.Id)
        .then(zips => {
          zips.sort((x, y) => x.filename! > y.filename! ? 1 : -1);
          file.SubItems = [];
          zips.forEach(z => {
            const zip = FileStructureTranslator.GetZip(z, file);
            zip.HubId = file.HubId;
            zip.ProjectId = file.ProjectId;
            file.SubItems.push(zip);
          });

          file.AreItemsPopulated = true;
        })
    );
  }

  private async GetAllFileData(directory: DirectoryUI): Promise<{
    files: (File360 | ProjectWiseFile)[],
    directories: (Directory360 | ProjectWiseDirectory)[]
  }> {
    const result = await this.GetRemainingDataFromPaginatedEndpointFileResult(t =>
      directory.StorageType === StorageType.Forge
        ? this._v2client.listFiles(directory.ProjectId, directory.Id, undefined, undefined, undefined, undefined, t, 100)
        : this._v2client.listFiles2(directory.ProjectId, directory.Id, undefined, undefined, undefined, undefined, t, 100, false, true, true)
    );

    return {files: result.files ?? [], directories: result.directories ?? []};
  }
}