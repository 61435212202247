// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {FileUI} from './FileUI';
import {BIM360ItemBase} from './BIM360ItemBase';
import {HubType, ProjectType, StorageType} from "../clients/Classes";

export class DirectoryUI implements BIM360ItemBase {
  // Interface Implementation
  public IsFolder = true;
  public IsZipEntry = false;

  Id: string;
  ParentId: string | undefined;
  ProjectId: string;
  Name: string;
  LastChange: Date | undefined;

  // Class Properties
  SubFolders: DirectoryUI[];
  Files: FileUI[];
  Parent: DirectoryUI | undefined;

  HubId: string | undefined;
  HubRegion: string | undefined;
  HubType: HubType;
  ProjectType: ProjectType;

  SubItems: (FileUI | DirectoryUI)[];

  Icon: string;

  AreItemsPopulated = false;

  IncludeThisFolder = false;
  IsDynamic = true;
  IsRecursive = true;

  ForceCheckState: boolean | undefined;

  StorageType: StorageType;

  constructor(id: string, name: string, projectId: string, storageType: StorageType) {
    this.Id = id;
    this.Name = name;
    this.ProjectId = projectId;
    this.SubFolders = [];
    this.Files = [];
    this.SubItems = [];
    this.HubType = HubType.Unknown;
    this.ProjectType = ProjectType.Unknown;
    this.Icon = 'assets/images/Icon_Folder.png';
    this.StorageType = storageType;
  }
}
