////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer} from 'react';
import {useNavigate, useParams} from "react-router";
import {reducer} from "../components/reducers/TaskEditReducer";
import {TaskEditState} from "../components/states/TaskEditState";
import {TaskService} from "../services/TaskService";
import {TaskEditActions} from "../Enums";
import {Task} from "../dataModel/Task";
import {
  BlueButton,
  CenteringContainer,
  ContentWrapper,
  FlexColumn,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import {EDIT_TAB_IDS, EDIT_TAB_TITLES, EDIT_TABS, FILE_TAB_IDS, FILE_TABS, PAGES, PATHS} from "../Constants";
import SettingsName from "../components/SettingsName";
import SettingsSource from "../components/SettingsSource";
import SettingsDestination from "../components/SettingsDestination";
import SettingsTrigger from "../components/SettingsTrigger";
import SettingsOptions from "../components/SettingsOptions";
import SettingsSummary from "../components/SettingsSummary";
import {ProjectService} from "../services/ProjectService";
import {
  CreateJobRequest,
  DeckardJobOptions,
  DirectorySelectionOptions,
  EmailPreferences,
  ExtractLocationType,
  FileDestinationNamingType,
  JobScheduleType,
  StorageType
} from "../clients/Classes";
import {GetErrorMessage, IsProjectValidForDestination, ValidateExports, ValidateTrigger} from "../Utility";
import {FileStructureTranslator} from "../dataModel/Translators/FileStructureTranslator";
import {FileUI} from "../dataModel/FileUI";
import {ZipTaskSetting} from "../dataModel/ZipTaskSetting";
import {TaskTranslator} from "../dataModel/Translators/TaskTranslator";
import {ProjectUI} from "../dataModel/ProjectUI";
import Theme from "@adsk/alloy-react-theme";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {ChevronLeftIcon, ChevronRightIcon, PlayIcon, XIcon} from "@adsk/alloy-react-icon";
import Modal from "@adsk/alloy-react-modal";
import Tooltip from "@adsk/alloy-react-tooltip";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import {ProjectWiseService} from "../services/ProjectWiseService";
import {ProjectWiseConfigurationTranslator} from "../dataModel/Translators/ProjectWiseConfigurationTranslator";
import {FileService} from "../services/FileService";
import {DirectoryUI} from "../dataModel/DirectoryUI";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";

const service = new TaskService();
const projectService = new ProjectService();
const projectWiseService = new ProjectWiseService();
const fileService = new FileService();

const TaskEdit = () => {
  const {id, action} = useParams();
  const [state, dispatch] = useReducer(reducer, new TaskEditState());
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    function setSelectedProjects(task: Task | undefined, projects: ProjectUI[], projectWiseConfigs: ProjectWiseConfigurationUI[], additionalPayload: any): void {
      let projectSource: ProjectUI | undefined;
      let configSource: ProjectWiseConfigurationUI | undefined;
      let projectSystem = StorageType.Forge;
      let destinationSystem = StorageType.Forge;
      if (task == null || (task.Directories.length === 0 && task.Files.length === 0)) {
        projectSource = projects.length > 0 ? projects[0] : undefined;
        configSource = projectWiseConfigs.length > 0 ? projectWiseConfigs[0] : undefined;
      } else {
        const taskBasedId = task.Directories.length > 0
          ? task.Directories[0].ProjectId
          : task.Files[0] instanceof FileUI ? task.Files[0].ProjectId : task.Files[0].File.ProjectId;
        projectSystem = task.Directories.length > 0
          ? task.Directories[0].StorageType
          : task.Files[0] instanceof FileUI ? task.Files[0].StorageType : task.Files[0].File.StorageType;
        configSource = projectWiseConfigs.find(c => c.ApiConfiguration.id === taskBasedId)
          ?? (projectWiseConfigs.length > 0 ? projectWiseConfigs[0] : undefined);
        projectSource = projects.find(proj => proj.Id === taskBasedId)
          ?? (projects.length > 0 ? projects[0] : undefined);
      }

      let projectDestination: ProjectUI | undefined;
      let configDestination: ProjectWiseConfigurationUI | undefined;
      if (task == null || task.ExportDirectory == null) {
        projectDestination = projects.find(IsProjectValidForDestination);
        configDestination = projectWiseConfigs.length > 0 ? projectWiseConfigs[0] : undefined;
      } else {
        const taskBasedId = task.ExportDirectory.ProjectId;
        projectDestination = projects.find(proj => proj.Id === taskBasedId) ?? projects.find(IsProjectValidForDestination);
        configDestination = projectWiseConfigs.find(c => c.ApiConfiguration.id === taskBasedId)
          ?? (projectWiseConfigs.length > 0 ? projectWiseConfigs[0] : undefined);
        destinationSystem = task.ExportDirectory.StorageType;
      }

      dispatch({
        type: TaskEditActions.multipleActions, payload: {
          ...additionalPayload,
          selectedProjectSource: projectSource,
          selectedProjectDestination: projectDestination,
          selectedProjectWiseConfigSource: configSource,
          selectedProjectWiseConfigDestination: configDestination,
          selectedTabSource: projectSystem === StorageType.ProjectWise ? FILE_TAB_IDS[FILE_TABS.PROJECTWISE] : FILE_TAB_IDS[FILE_TABS.FORGE],
          selectedTabDestination: destinationSystem === StorageType.ProjectWise ? FILE_TAB_IDS[FILE_TABS.PROJECTWISE] : FILE_TAB_IDS[FILE_TABS.FORGE],
        }
      });
    }

    let taskPayload: {};
    if (id?.toLowerCase() === 'new') {
      const newTask = new Task();
      newTask.ExportLocationType = ExtractLocationType.OtherDirectory;
      state.Task = newTask;

      taskPayload = {
        Task: newTask,
        Loading: false,
        IsNewTask: true,
        IsDuplicating: false
      };
    } else {
      taskPayload = {
        Loading: true,
        IsNewTask: false,
        IsDuplicating: action?.toLowerCase() === 'duplicate'
      };
      service.GetTask(id!)
        .then(task => {
          if (!isMounted) {
            return;
          }

          if (action?.toLowerCase() === 'duplicate') {
            task.Name = `${task.Name} Copy`;
            task.Id = undefined;
          }

          state.Task = task;

          setSelectedProjects(task, state.Projects, state.ProjectWiseConfigs, {
            Task: task,
            Loading: false,
            OriginalTask: task.GetComparisonClone(),
          });
        })
        .catch(error => onError(error, 'Get Task'));
    }

    dispatch({
      type: TaskEditActions.multipleActions,
      payload: {
        ...taskPayload,
        LoadingProjects: true,
        LoadingProjectWiseConfigs: true
      }
    });

    projectService.GetProjects()
      .then(p => {
        if (!isMounted) {
          return;
        }

        state.Projects = p;

        setSelectedProjects(state.Task, p, state.ProjectWiseConfigs, {
          LoadingProjects: false,
          Projects: p,
        });
      })
      .catch(error => onError(error, 'Get Projects'));

    projectWiseService.GetRemainingConfigurations()
      .then(result => {
        const items = result.items!.map(c => ProjectWiseConfigurationTranslator.TranslateConfiguration(c));

        state.ProjectWiseConfigs = items;

        setSelectedProjects(state.Task, state.Projects, items, {
          LoadingProjectWiseConfigs: false,
          ProjectWiseConfigs: items
        });
      })
      .catch(error => onError(error, 'Loading ProjectWise Configurations'));

    return () => {
      isMounted = false;
    }
  }, [action, id]);

  function nextTab(): void {
    let newTab = null;
    switch (state.SelectedTab) {
      case EDIT_TAB_IDS[EDIT_TABS.NAME]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.SOURCE]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.SOURCE]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.DESTINATION]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.DESTINATION]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.TRIGGER]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.TRIGGER]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.OPTIONS]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.OPTIONS]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.SUMMARY]
        break;
    }

    if (newTab == null) {
      return;
    }

    dispatch({type: TaskEditActions.selectedTab, payload: newTab});
  }

  function previousTab(): void {
    let newTab = null;
    switch (state.SelectedTab) {
      case EDIT_TAB_IDS[EDIT_TABS.SUMMARY]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.OPTIONS]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.OPTIONS]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.TRIGGER]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.TRIGGER]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.DESTINATION]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.DESTINATION]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.SOURCE]
        break;
      case EDIT_TAB_IDS[EDIT_TABS.SOURCE]:
        newTab = EDIT_TAB_IDS[EDIT_TABS.NAME]
        break;
    }

    if (newTab == null) {
      return;
    }

    dispatch({type: TaskEditActions.selectedTab, payload: newTab});
  }

  function cancel(): void {
    navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`);
  }

  function save(): void {
    if (state.IsNewTask || state.IsDuplicating) {
      CreateNewTask();
    } else {
      UpdateTask();
    }
  }

  function CreateNewTask(): void {
    if (state.Task == null) {
      return;
    }
    let scheduleType: JobScheduleType = JobScheduleType.None;

    switch (state.Task.Trigger) {
      case 'OnceNow':
        scheduleType = JobScheduleType.OnceNow;
        break;
      case 'OnceLater':
        scheduleType = JobScheduleType.OnceLater;
        break;
      case 'Recurring':
        scheduleType = JobScheduleType.Recurring;
        break;
      case 'OnPublish':
        scheduleType = JobScheduleType.OnPublish;
        break;
    }

    const directoryOptions: { [key: string]: DirectorySelectionOptions } = {};
    if (state.Task.Directories != null) {
      state.Task.Directories
        .filter(d => d.IsDynamic)
        .forEach(d => directoryOptions[d.Id] = new DirectorySelectionOptions({
          recursive: d.IsRecursive,
          copyCurrentFolder: d.IncludeThisFolder
        }));
    }

    const options = new DeckardJobOptions({
      locationType: state.Task.ExportLocationType,
      destinationNamingType: state.Task.ExportDestinationNaming,
      extractDirectory: state.Task.ExportDirectory == null
        ? undefined :
        FileStructureTranslator.GetApiDirectoryDto(state.Task.ExportDirectory),
      emailPreferences: new EmailPreferences({
        emailOnCompletion: state.Task.EmailOnCompletion,
        attachExportFiles: state.Task.AttachCsv
      }),
      singleFiles: service.GetSingleFiles(state.Task),
      doNotMaintainFolderStructure: state.Task.DoNotMaintainFolderStructure,
      archive: state.Task.Archive,
      directorySelectionOptions: directoryOptions
    });

    const projectWiseFiles = state.Task.Files
      .filter(file => file instanceof FileUI && file.StorageType === StorageType.ProjectWise)
      .map(model => FileStructureTranslator.GetApiFileDto(model as FileUI));

    const allModels = state.Task.Files
      .filter(model => model instanceof FileUI && model.StorageType === StorageType.Forge)
      .map(model => FileStructureTranslator.GetApiFileDto(model as FileUI));

    // Add in the models from the zips
    state.Task.Files
      .filter(m => m instanceof ZipTaskSetting)
      .forEach(z => {
          const zip = z as ZipTaskSetting;
          const existingModel = allModels.find(m => m.id === zip.File.Id);
          if (existingModel == null) {
            allModels.push(FileStructureTranslator.GetApiFileDto(zip.File));
          }
        }
      );

    const create = new CreateJobRequest({
      name: state.Task.Name,
      models: [...allModels, ...projectWiseFiles],
      directories: state.Task.Directories
        .filter(d => d.IsDynamic)
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d)),
      schedule: TaskTranslator.GetScheduleFromTask(state.Task),
      options: options,
      jobScheduleType: scheduleType,
      runOnceDateTime: state.Task.Trigger === 'OnceLater' ? state.Task.StartDate : undefined,
      nonDynamicDirectories: state.Task.Directories
        .filter(d => !d.IsDynamic)
        .map(d => FileStructureTranslator.GetApiDirectoryDto(d)),
    });

    dispatch({type: TaskEditActions.Saving, payload: true});
    service.CreateTask(create)
      .then(() => {
        dispatch({type: TaskEditActions.Saving, payload: false});
        navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`);
      })
      .catch(error => {
        dispatch({type: TaskEditActions.Saving, payload: false});
        onError(error, 'Save task');
      });
  }

  function UpdateTask(): void {
    if (state.Task == null || state.OriginalTask == null) {
      return;
    }
    const update = service.GetTaskUpdate(state.OriginalTask, state.Task);

    dispatch({type: TaskEditActions.Saving, payload: true});
    service.UpdateTask(state.Task, update).then(newTask => {
      dispatch({
        type: TaskEditActions.multipleActions, payload: {
          Saving: false,
          Task: newTask,
          OriginalTask: newTask.GetComparisonClone()
        }
      });

      navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`);
    })
      .catch(error => {
        dispatch({type: TaskEditActions.Saving, payload: false});
        onError(error, 'Save task');
      });
  }

  function ValidateFinish(task: Task): string[] {
    if (task == null) {
      return ['Task is null'];
    }

    const messages: string[] = [];

    if (task.Name == null || task.Name === '') {
      messages.push('Name is blank');
    }

    if (task.Files.length === 0 && task.Directories.length === 0) {
      messages.push('You have not selected any source models or folders');
    }

    if (task.ExportDestinationNaming == null || task.ExportDestinationNaming === FileDestinationNamingType.None) {
      messages.push('Please select a file naming option for exports');
    }

    const triggerValidation = ValidateTrigger(task);
    if (triggerValidation != null) {
      for (const message of triggerValidation) {
        messages.push(message);
      }
    }

    const exportValidation = ValidateExports(task);
    if (exportValidation != null) {
      for (const message of exportValidation) {
        messages.push(message);
      }
    }

    return messages;
  }

  function getValidationTooltip(messages: string[]): string | undefined {
    return messages.length > 0
      ? `Your task is not complete and can't be saved!  Here are the issues:\n\n${messages.join('\n')}`
      : undefined;
  }

  function startCancel(): void {
    dispatch({type: TaskEditActions.showCancelConfirm, payload: true});
  }

  function getRootDirectories(root: ProjectUI | ProjectWiseConfigurationUI): Promise<DirectoryUI[]> {
    return root instanceof ProjectUI
      ? fileService.GetRootDirectories(root).then(() => root.RootFolderArray)
      : fileService.GetProjectWiseRootDirectories(root).then(() => root.RootFolderArray);
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  return (
    <ContentWrapper>
      {
        (state.Loading || state.Saving) &&
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      }
      {state.Task != null && !state.Loading && !state.Saving && (
        <>
          <h1 style={Theme.typography.heading1}>{state.IsNewTask ? 'New Task' : 'Edit Task'}</h1>
          <FlexRow style={{marginBottom: '2em', marginTop: '1em', flex: 0}}>
            <Tooltip content={getValidationTooltip(ValidateFinish(state.Task))}>
              <BlueButton onClick={save} disabled={ValidateFinish(state.Task).length > 0}
                          style={{marginRight: '2em'}}>
                <FlexRowCentered>
                  <PlayIcon style={{marginRight: '0.5em'}}/>
                  <span style={Theme.typography.labelMedium}>Save and run</span>
                </FlexRowCentered>
              </BlueButton>
            </Tooltip>
            <BlueButton onClick={previousTab}
                        disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.NAME]}
                        style={{marginRight: '2em'}}>
              <FlexRowCentered>
                <ChevronLeftIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Back</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton onClick={nextTab}
                        disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SUMMARY]}
                        style={{marginRight: '2em'}}>
              <FlexRowCentered>
                <ChevronRightIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Next</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton onClick={startCancel}>
              <FlexRowCentered>
                <XIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
          <Tabs active={state.SelectedTab}
                style={{flex: 1, display: 'flex', flexDirection: "column"}}
                onChange={tab => dispatch({type: TaskEditActions.selectedTab, payload: tab})}>
            {Object.keys(EDIT_TABS).map((k) => {
              return (
                <Tab label={EDIT_TAB_TITLES[k]}
                     tab={EDIT_TAB_IDS[k]}
                     key={EDIT_TAB_IDS[k]}
                     style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                  <FlexColumn style={{paddingTop: '1em'}}>
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.NAME] &&
                      <SettingsName
                        value={state.Task?.Name ?? ''}
                        onChange={name => {
                          if (state.Task == null) {
                            return;
                          }
                          state.Task.Name = name;
                        }}/>}
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SOURCE] &&
                      <SettingsSource
                        task={state.Task}
                        projects={state.Projects}
                        loadingProjects={state.LoadingProjects}
                        getRootDirectories={getRootDirectories}
                        getDirectoryContents={d => fileService.GetDirectoryContents(d)}
                        getZipContents={z => fileService.GetZipContents(z)}
                        projectWiseConfigs={state.ProjectWiseConfigs}
                        loadingProjectWiseConfigs={state.LoadingProjectWiseConfigs}
                        selectedProjectWiseConfig={state.selectedProjectWiseConfigSource}
                        expandedIds={state.expandedSource}
                        selectedProject={state.selectedProjectSource}
                        selectedTab={state.selectedTabSource}
                        onTabChange={t => dispatch({type: TaskEditActions.selectedTabSource, payload: t})}
                        onProjectSelected={p => dispatch({
                          type: TaskEditActions.selectedProjectSource,
                          payload: p
                        })}
                        onProjectWiseConfigSelected={p => dispatch({
                          type: TaskEditActions.selectedProjectWiseConfigSource,
                          payload: p
                        })}
                        onExpandedChanged={ids => dispatch({
                          type: TaskEditActions.expandedSource,
                          payload: ids
                        })}
                        onError={onError}/>}
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.DESTINATION] &&
                      <SettingsDestination
                        selectedDirectory={state.Task?.ExportDirectory}
                        onSelectedChanged={d => {
                          if (state.Task != null) {
                            state.Task.ExportDirectory = d;
                          }
                        }}
                        projects={state.Projects.filter(IsProjectValidForDestination)}
                        loadingProjects={state.LoadingProjects}
                        getRootDirectories={getRootDirectories}
                        getDirectoryContents={d => fileService.GetDirectoryContents(d)}
                        getZipContents={z => fileService.GetZipContents(z)}
                        projectWiseConfigs={state.ProjectWiseConfigs}
                        loadingProjectWiseConfigs={state.LoadingProjectWiseConfigs}
                        selectedProjectWiseConfig={state.selectedProjectWiseConfigDestination}
                        expandedIds={state.expandedDestination}
                        selectedProject={state.selectedProjectDestination}
                        selectedTab={state.selectedTabDestination}
                        onTabChange={t => dispatch({type: TaskEditActions.selectedTabDestination, payload: t})}
                        onProjectSelected={p => dispatch({
                          type: TaskEditActions.selectedProjectDestination,
                          payload: p
                        })}
                        onProjectWiseConfigSelected={p => dispatch({
                          type: TaskEditActions.selectedProjectWiseConfigDestination,
                          payload: p
                        })}
                        onExpandedChanged={ids => dispatch({
                          type: TaskEditActions.expandedDestination,
                          payload: ids
                        })}
                        onError={onError}/>}
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.TRIGGER] &&
                      <SettingsTrigger task={state.Task}/>}
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.OPTIONS] &&
                      <SettingsOptions task={state.Task}/>}
                    {state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SUMMARY] &&
                      <SettingsSummary task={state.Task}
                                       projects={[...state.Projects, ...state.ProjectWiseConfigs]}
                                       projectsLoading={state.LoadingProjects}
                                       onError={onError}/>}
                  </FlexColumn>
                </Tab>
              )
            })}
          </Tabs>
        </>
      )}
      <Modal open={state.showCancelConfirm}>
        <Modal.Header>Lose Changes?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>Canceling will cause you to lose any changes you have made, are you
            sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={cancel}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Yes</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: TaskEditActions.showCancelConfirm, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>No</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </ContentWrapper>
  );
};

export default TaskEdit;