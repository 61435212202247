// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {
  ExtractLocationType,
  FileDestinationNamingType,
  FileDto,
  FileStatus,
  StartExtractRequest,
  StartExtractResponse,
  StorageType
} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {IClient} from "../clients/Client";

export class ExtractionService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;

  StartExtraction(hubId: string,
                  projectId: string,
                  fileId: string,
                  location: ExtractLocationType,
                  storageType: StorageType,
                  namingSetting: FileDestinationNamingType,
                  zipFileName?: string): Promise<StartExtractResponse[]> {
    const file = new FileDto({
      storageType: storageType,
      projectId: projectId,
      hubId: hubId,
      id: fileId,
    });
    const data = new StartExtractRequest({
      sourceFile: file,
      singleFilename: zipFileName,
      locationType: location,
      destinationNamingType: namingSetting,
    });

    return this.TryPromiseWithCatch(() =>
      this._client.startExtract(data)
    );
  }

  GetStatus(id: string): Promise<FileStatus> {
    return this.TryPromiseWithCatch(() =>
      this._client.status(id)
    );
  }
}