////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {PAGE_TITLES, PAGES, PATHS} from "../Constants";
import DirectDownload from "./DirectDownload";
import Jobs from "./Jobs";
import Usage from "./Usage";
import Customers from "./Customers";
import Tasks from "./Tasks";
import Landing from "./Landing";
import Door from "./Door";
import Reports from "./Reports";
import Settings from "./Settings";
import {PageTypes} from "../Enums";
import {
  ChartLineIcon, ClipboardPencilIcon, CloudDownArrowIcon,
  DocumentReportIcon,
  GearIcon,
  HomeIcon,
  KeyIcon,
  PeopleIcon,
  TaskListIcon
} from "@adsk/alloy-react-icon";
import React, {ReactElement} from "react";
import {Navigate} from "react-router-dom";

export const pages: {
  path: string,
  component: ReactElement,
  title: string,
  exact: boolean,
  renderIcon: (p: any) => ReactElement | null | undefined,
  uiVisible: boolean,
  type: PageTypes,
  requiredRole?: string | undefined,
}[] = [
  {
    path: PATHS[PAGES.ROOT],
    component: <Navigate to={`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`} replace/>,
    title: PAGE_TITLES[PAGES.ROOT],
    exact: true,
    renderIcon: (p: any) => <HomeIcon {...p} />,
    uiVisible: false,
    type: PageTypes.protected,
  },
  {
    path: PATHS[PAGES.DOOR],
    component: <Door/>,
    title: PAGE_TITLES[PAGES.DOOR],
    exact: true,
    renderIcon: (p: any) => <KeyIcon {...p} />,
    uiVisible: false,
    type: PageTypes.auth,
  },
  {
    path: PATHS[PAGES.LANDING],
    component: <Landing/>,
    title: PAGE_TITLES[PAGES.LANDING],
    exact: true,
    renderIcon: (p: any) => <KeyIcon {...p} />,
    uiVisible: false,
    type: PageTypes.auth,
  },
  {
    path: PATHS[PAGES.TASK],
    component: <Tasks/>,
    title: PAGE_TITLES[PAGES.TASK],
    exact: true,
    renderIcon: (p: any) => <TaskListIcon {...p} />,
    uiVisible: true,
    type: PageTypes.protected,
  },
  {
    path: PATHS[PAGES.REPORT],
    component: <Reports/>,
    title: PAGE_TITLES[PAGES.REPORT],
    exact: true,
    renderIcon: (p: any) => <DocumentReportIcon {...p} />,
    uiVisible: true,
    type: PageTypes.protected,
  },
  {
    path: PATHS[PAGES.SETTINGS],
    component: <Settings/>,
    title: PAGE_TITLES[PAGES.SETTINGS],
    exact: true,
    renderIcon: (p: any) => <GearIcon {...p} />,
    uiVisible: true,
    type: PageTypes.settings,
  },
  {
    path: PATHS[PAGES.DIRECT],
    component: <DirectDownload/>,
    title: PAGE_TITLES[PAGES.DIRECT],
    exact: true,
    renderIcon: (p: any) => <CloudDownArrowIcon {...p} />,
    uiVisible: true,
    type: PageTypes.protected,
  },
  {
    path: PATHS[PAGES.CUSTOMERS],
    component: <Customers/>,
    title: PAGE_TITLES[PAGES.CUSTOMERS],
    exact: true,
    renderIcon: (p: any) => <PeopleIcon {...p} />,
    uiVisible: true,
    type: PageTypes.customers,
  },
  {
    path: PATHS[PAGES.JOBS],
    component: <Jobs/>,
    title: PAGE_TITLES[PAGES.JOBS],
    exact: true,
    renderIcon: (p: any) => <ChartLineIcon {...p} />,
    uiVisible: true,
    type: PageTypes.usage,
  },
  {
    path: PATHS[PAGES.USAGE],
    component: <Usage/>,
    title: PAGE_TITLES[PAGES.USAGE],
    exact: true,
    renderIcon: (p: any) => <ClipboardPencilIcon {...p} />,
    uiVisible: true,
    type: PageTypes.usage,
  },
];
