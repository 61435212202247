////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { InfoMenuState } from "../states/InfoMenuState";
import { InfoMenuActions } from "../../Enums";

export function reducer(state: InfoMenuState, action: { type: InfoMenuActions, payload: any }): InfoMenuState {
  switch (action.type) {
    case InfoMenuActions.multipleActions:
      return { ...state, ...action.payload };
    case InfoMenuActions.modalOpen:
      return { ...state, tutorialsOpen: action.payload };
    case InfoMenuActions.modalContent:
      return { ...state, tutorialContent: action.payload };
  }
}