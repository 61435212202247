////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer} from 'react';
import {useNavigate, useParams} from "react-router";
import {reducer} from "../components/reducers/BulkTaskEditReducer";
import {BulkTaskEditState} from "../components/states/BulkTaskEditState";
import {
  BlueButton,
  CenteringContainer,
  ContentWrapper,
  FlexColumn,
  FlexRow,
  FlexRowCentered
} from "../CommonStyledComponents";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Modal from "@adsk/alloy-react-modal";
import Theme from "@adsk/alloy-react-theme";
import {BulkTaskEditActions} from "../Enums";
import {
  EDIT_TAB_BULK_IDS,
  EDIT_TAB_BULK_TITLES,
  EDIT_TAB_IDS,
  EDIT_TABS,
  EDIT_TABS_BULK, FILE_TAB_IDS, FILE_TABS,
  PAGES,
  PATHS
} from "../Constants";
import Tooltip from "@adsk/alloy-react-tooltip";
import {ChevronLeftIcon, ChevronRightIcon, PlayIcon, XIcon} from "@adsk/alloy-react-icon";
import {BulkTaskUI} from "../dataModel/BulkTaskUI";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import {ProjectService} from "../services/ProjectService";
import {BulkTaskService} from "../services/BulkTaskService";
import {GetErrorMessage, IsProjectValidForDestination} from "../Utility";
import SettingsName from "../components/SettingsName";
import BulkSettingsSource from "../components/BulkSettingsSource";
import BulkSettingsSummary from "../components/BulkSettingsSummary";
import SettingsDestination from "../components/SettingsDestination";
import {FileService} from "../services/FileService";
import {ProjectUI} from "../dataModel/ProjectUI";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {DirectoryUI} from "../dataModel/DirectoryUI";

const service = new BulkTaskService();
const projectService = new ProjectService();
const fileService = new FileService();

const BulkTaskEdit = () => {
  const {id, action} = useParams();
  const [state, dispatch] = useReducer(reducer, new BulkTaskEditState());
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    dispatch({type: BulkTaskEditActions.multipleActions, payload: {Loading: true, LoadingProjects: true}});

    if (id?.toLowerCase() === 'new') {
      const newTask = new BulkTaskUI('new');
      dispatch({type: BulkTaskEditActions.multipleActions, payload: {Task: newTask, IsNewTask: true}});
    } else {
      alert('Editing bulk tasks is not currently supported');
    }

    projectService.GetProjects()
      .then(p => {
        if (!isMounted) {
          return;
        }

        let selectedProjectDestination = p.length > 0
          ? p.find(IsProjectValidForDestination)
          : undefined;
        if (state.Task?.DestinationDirectory != null) {
          selectedProjectDestination = p.find(project => project.Id === state.Task?.DestinationDirectory?.ProjectId && project.HubId === state.Task.DestinationDirectory.HubId);
        }

        dispatch({
          type: BulkTaskEditActions.multipleActions,
          payload: {
            Projects: p,
            Loading: false,
            LoadingProjects: false,
            SelectedProjectDestination: selectedProjectDestination
          }
        });
      });

    return () => {
      isMounted = false;
    }
  }, [action, id]);

  function save(): void {
    if (state.IsNewTask || state.IsDuplicating) {
      CreateNewTask();
    } else {
      UpdateTask();
    }
  }

  function CreateNewTask(): void {
    if (state.Task == null) {
      return;
    }

    dispatch({type: BulkTaskEditActions.saving, payload: true});
    service.CreateBulkTask(state.Task)
      .then(() => {
        dispatch({type: BulkTaskEditActions.saving, payload: false});
        navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`);
      })
      .catch(error => {
        dispatch({type: BulkTaskEditActions.saving, payload: false});
        alert(GetErrorMessage(error, 'Save Task'));
      });
  }

  function UpdateTask(): void {
    alert('Updating a task is not currently supported');
  }

  function ValidateFinish(task: BulkTaskUI): string[] {
    if (task == null) {
      return ['Task is null'];
    }

    const messages: string[] = [];

    if (task.Name == null || task.Name.trim() === '') {
      messages.push('Name is blank');
    }

    if (task.Projects.length === 0) {
      messages.push('You have not selected any source projects');
    }

    if (task.DestinationDirectory == null) {
      messages.push('You have not selected a destination');
    }

    return messages;
  }

  function getValidationTooltip(messages: string[]): string | undefined {
    return messages.length > 0
      ? `Your task is not complete and can't be saved!  Here are the issues:\n\n${messages.join('\n')}`
      : undefined;
  }

  function nextTab(): void {
    let newTab = null;
    switch (state.SelectedTab) {
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.NAME]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SOURCE];
        break;
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SOURCE]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.DESTINATION];
        break;
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.DESTINATION]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SUMMARY];
        break;
    }

    if (newTab == null) {
      return;
    }

    dispatch({type: BulkTaskEditActions.selectedTab, payload: newTab});
  }

  function previousTab(): void {
    let newTab = null;
    switch (state.SelectedTab) {
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SUMMARY]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.DESTINATION]
        break;
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.DESTINATION]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SOURCE]
        break;
      case EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SOURCE]:
        newTab = EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.NAME]
        break;
    }

    if (newTab == null) {
      return;
    }

    dispatch({type: BulkTaskEditActions.selectedTab, payload: newTab});
  }

  function getRootDirectories(root: ProjectUI | ProjectWiseConfigurationUI): Promise<DirectoryUI[]> {
    return root instanceof ProjectUI
      ? fileService.GetRootDirectories(root).then(() => root.RootFolderArray)
      : fileService.GetProjectWiseRootDirectories(root).then(() => root.RootFolderArray);
  }

  function startCancel(): void {
    dispatch({type: BulkTaskEditActions.showCancelConfirm, payload: true});
  }

  function cancel(): void {
    navigate(`${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`);
  }

  return (
    <ContentWrapper>
      {
        (state.Loading || state.Saving) &&
          <CenteringContainer style={{flex: 1}}>
              <ProgressRing size={'large'}/>
          </CenteringContainer>
      }
      {state.Task != null && !state.Loading && !state.Saving && (
        <>
          <h1 style={Theme.typography.heading1}>{state.IsNewTask ? 'New Bulk Task' : 'Edit Bulk Task'}</h1>
          <FlexRow style={{marginBottom: '2em', marginTop: '1em', flex: 0}}>
            <Tooltip content={getValidationTooltip(ValidateFinish(state.Task))}>
              <BlueButton onClick={save} disabled={ValidateFinish(state.Task).length > 0}
                          style={{marginRight: '2em'}}>
                <FlexRowCentered>
                  <PlayIcon style={{marginRight: '0.5em'}}/>
                  <span style={Theme.typography.labelMedium}>Save and run</span>
                </FlexRowCentered>
              </BlueButton>
            </Tooltip>
            <BlueButton onClick={previousTab}
                        disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.NAME]}
                        style={{marginRight: '2em'}}>
              <FlexRowCentered>
                <ChevronLeftIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Back</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton onClick={nextTab}
                        disabled={state.SelectedTab === EDIT_TAB_IDS[EDIT_TABS.SUMMARY]}
                        style={{marginRight: '2em'}}>
              <FlexRowCentered>
                <ChevronRightIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Next</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton onClick={startCancel}>
              <FlexRowCentered>
                <XIcon style={{marginRight: '0.5em'}}/>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
          <Tabs active={state.SelectedTab}
                style={{flex: 1, display: 'flex', flexDirection: "column", minHeight: 0}}
                onChange={tab => dispatch({type: BulkTaskEditActions.selectedTab, payload: tab})}>
            {Object.keys(EDIT_TABS_BULK).map(k => {
              return (
                <Tab label={EDIT_TAB_BULK_TITLES[k]}
                     tab={EDIT_TAB_BULK_IDS[k]}
                     key={EDIT_TAB_BULK_IDS[k]}
                     style={{flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0}}>
                  <FlexColumn style={{paddingTop: '1em'}}>
                    {state.SelectedTab === EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.NAME] &&
                        <SettingsName
                            value={state.Task?.Name ?? ''}
                            onChange={n => {
                              if (state.Task != null) {
                                state.Task.Name = n;
                                dispatch({type: BulkTaskEditActions.task, payload: state.Task});
                              }
                            }}/>}
                    {state.SelectedTab === EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SOURCE] &&
                        <BulkSettingsSource projects={state.Projects}
                                            selectedIds={state.Task?.Projects.map(p => p.Id) ?? []}
                                            loadingProjects={state.LoadingProjects}
                                            onSelectionChanged={p => {
                                              if (state.Task != null) {
                                                state.Task.Projects = p;
                                                dispatch({type: BulkTaskEditActions.task, payload: state.Task});
                                              }
                                            }}/>}
                    {state.SelectedTab === EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.DESTINATION] &&
                        <SettingsDestination
                            projects={state.Projects.filter(IsProjectValidForDestination)}
                            loadingProjects={state.LoadingProjects}
                            selectedProject={state.SelectedProjectDestination}
                            selectedDirectory={state.Task?.DestinationDirectory}
                            expandedIds={state.ExpandedDestination}
                            projectWiseConfigs={[]}
                            loadingProjectWiseConfigs={false}
                            selectedProjectWiseConfig={undefined}
                            selectedTab={FILE_TAB_IDS[FILE_TABS.FORGE]}
                            getRootDirectories={getRootDirectories}
                            getDirectoryContents={d => fileService.GetDirectoryContents(d)}
                            getZipContents={z => fileService.GetZipContents(z)}
                            onProjectSelected={p => dispatch({
                              type: BulkTaskEditActions.selectedProjectDestination,
                              payload: p
                            })}
                            onExpandedChanged={ids => dispatch({
                              type: BulkTaskEditActions.expandedDestination,
                              payload: ids
                            })}
                            onDestinationSelected={destination => {
                              if (state.Task != null) {
                                state.Task.DestinationDirectory = destination;
                                dispatch({type: BulkTaskEditActions.task, payload: state.Task});
                              }
                            }}/>}
                    {state.SelectedTab === EDIT_TAB_BULK_IDS[EDIT_TABS_BULK.SUMMARY] &&
                        <BulkSettingsSummary task={state.Task} projects={state.Projects}/>}
                  </FlexColumn>
                </Tab>
              )
            })}
          </Tabs>
        </>
      )}
      <Modal open={state.showCancelConfirm}>
        <Modal.Header>Lose Changes?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>Canceling will cause you to lose any changes you have made, are you
            sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={cancel}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Yes</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: BulkTaskEditActions.showCancelConfirm, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>No</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </ContentWrapper>
  );
};

export default BulkTaskEdit;